import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
	Typography,
} from '@mui/material';
import { ArticleOutlined, EditRounded } from '@mui/icons-material';

import Tools from 'utils/Tools';
import { ProjectService } from 'services';

import {
	AddButton,
	Layout,
	PageTitle,
	Spinner,
	StatusChip,
	WritersOccupationRate,
} from 'components';

import AssignmentDialog from '../Assignments/AssignmentDialog';
import ProjectDialog from './ProjectDialog';
import ProjectFilters from './ProjectFilters';

import './Projects.scss';
import CanI from 'components/CanI';

const { getProjects } = ProjectService;
const { getDate, toFixed2 } = Tools;

const Projects = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [filters, setFilters] = useState(
		Tools.objectToQueryString({
			isEnabled: 'true',
			order: 'ASC',
			orderBy: 'clientName',
		})
	);
	const [projects, setProjects] = useState([]);
	const [selectedProject, setSelectedProject] = useState(null);
	const [showAddForm, setShowAddForm] = useState(false);
	const [showAssignmentForm, setShowAssignmentForm] = useState(false);
	const [relatedProject, setRelatedProject] = useState(null);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			getProjects(filters)
				.then(({ data }) => {
					setProjects(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [enqueueSnackbar, isLoading, filters]);

	const handleClose = () => {
		if (showAddForm) setShowAddForm(false);
		if (selectedProject) setSelectedProject(null);
	};

	const handleCreateAssignment = (project) => {
		setRelatedProject(project);
		setShowAssignmentForm(true);
	};

	const handleCancelAssignment = () => {
		setShowAssignmentForm(false);
		setRelatedProject(null);
	};

	const handleSearch = (query) => {
		setFilters(query);
		setIsLoading(true);
	};

	const totalPaid = projects.reduce(
		(accumulator, { paidAt, amount }) => (!!paidAt ? accumulator + amount : accumulator),
		0
	);

	const totalPendingPayment = projects.reduce(
		(accumulator, { paidAt, amount }) => (!paidAt ? accumulator + amount : accumulator),
		0
	);

	return (
		<Layout>
			<PageTitle
				title="Proyectos"
				actionButtons={[
					<CanI make="PROJECT_ADD">
						<AddButton onClick={() => setShowAddForm(true)} />
					</CanI>,
				]}
			/>
			<ProjectFilters onSearch={handleSearch} />
			<div className="Project-OccupationRate">
				<WritersOccupationRate />
			</div>
			{isLoading && <Spinner text="Cargando proyectos" isOpen small />}
			{!isLoading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Proyecto</TableCell>
								<TableCell>Cliente</TableCell>
								<TableCell>Monto</TableCell>
								<TableCell>Palabras</TableCell>
								<TableCell>Desde</TableCell>
								<TableCell>Hasta</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Fecha de pago</TableCell>
								<TableCell>Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{projects.map((project) => {
								const {
									amount,
									clientLastName,
									clientName,
									endDate,
									id,
									isEnabled,
									name,
									paidAt,
									startDate,
									totalWords,
								} = project;

								return (
									<TableRow key={id}>
										<TableCell>{name}</TableCell>
										<TableCell>
											{clientName} {clientLastName}
										</TableCell>
										<TableCell>${toFixed2(amount)}</TableCell>
										<TableCell align="right">
											{Number(totalWords).toLocaleString('es-CO')}
										</TableCell>
										<TableCell>{getDate({ date: startDate, format: 'short' })}</TableCell>
										<TableCell>{getDate({ date: endDate, format: 'short' })}</TableCell>
										<TableCell>
											<StatusChip isEnabled={isEnabled} />
										</TableCell>
										<TableCell>
											{paidAt ? getDate({ date: paidAt, format: 'dateTime' }) : '-'}
										</TableCell>
										<TableCell>
											<CanI make="PROJECT_EDIT">
												<IconButton size="small" onClick={() => setSelectedProject(project)}>
													<EditRounded />
												</IconButton>
											</CanI>
											<CanI make="PROJECT_ADD_ASSIGNMENT">
												<IconButton size="small" onClick={() => handleCreateAssignment(project)}>
													<ArticleOutlined />
												</IconButton>
											</CanI>
										</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell colSpan={9} align="right">
									<Typography color="green">
										<strong>Total Proyectos pagados:</strong> ${toFixed2(totalPaid)}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={9} align="right">
									<Typography color="orangered">
										<strong>Total Proyectos por cobrar:</strong> ${toFixed2(totalPendingPayment)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{(showAddForm || selectedProject) && (
				<ProjectDialog
					onClose={handleClose}
					project={selectedProject}
					onSuccess={() => setIsLoading(true)}
				/>
			)}
			{relatedProject && showAssignmentForm && (
				<AssignmentDialog
					onCancel={handleCancelAssignment}
					onSuccess={handleCancelAssignment}
					project={relatedProject}
				/>
			)}
		</Layout>
	);
};

export default Projects;
