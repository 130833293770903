import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

import { AccountingService } from 'services';
import { Layout, PageTitle, Spinner } from 'components';
import Tools from 'utils/Tools';
import { AccountingFilters } from './AccountingFilters';

import './ClientsAccounting.scss';
import dateType from 'common/constants/dateType';

const { toFixed2, calculatePeriod } = Tools;

const { getClientsAccounting } = AccountingService;

// const defaultFilters = {
// 	from: dayjs().startOf('month').format('YYYY-MM-DD'),
// 	to: dayjs().format('YYYY-MM-DD'),
// };

const ClientsAccounting = () => {
	const [accounting, setAccounting] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [filters, setFilters] = useState({
		...calculatePeriod('last'),
		orderBy: 'name',
		dateType: dateType.END.value,
	});

	useEffect(() => {
		if (isLoading) {
			getClientsAccounting(filters)
				.then(({ data }) => {
					setAccounting(data);
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
				});
		}
	}, [isLoading, filters]);

	const handleSubmit = (values) => {
		setFilters(values);
		setIsLoading(true);
	};

	const totalDebts = accounting.reduce(
		(accumulator, { clientDebt }) => accumulator + clientDebt,
		0
	);
	const totalPayments = accounting.reduce((accumulator, { payment }) => accumulator + payment, 0);

	return (
		<Layout>
			<PageTitle title="Contabilidad General" />
			<AccountingFilters onSubmit={handleSubmit} defaultFilters={filters} />
			{isLoading && <Spinner text="Cargando registros" isOpen small />}
			{!isLoading && (
				<div>
					<TableContainer component={Box}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Cliente</TableCell>
									<TableCell>ID Cont.</TableCell>
									<TableCell align="center">Deuda Inicial</TableCell>
									<TableCell align="center">Adeudos</TableCell>
									<TableCell align="center">Abonos</TableCell>
									<TableCell align="center">Deuda Final</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{accounting.map((client) => {
									const {
										clientId,
										name,
										lastName,
										idCont,
										initialDebt,
										payment,
										clientDebt,
										finalDebt,
									} = client;

									const accountingHref = `contabilidad/${clientId}?from=${filters.from}&to=${filters.to}&dateType=${filters.dateType}`;

									return (
										<TableRow key={clientId}>
											<TableCell sx={{ py: 1 }}>
												<Link to={accountingHref} target="_blank">
													{name} {lastName}
												</Link>
											</TableCell>
											<TableCell>{idCont || '-'}</TableCell>
											<TableCell align="right">${toFixed2(initialDebt)}</TableCell>
											<TableCell align="right">${toFixed2(clientDebt)}</TableCell>
											<TableCell align="right">${toFixed2(payment)}</TableCell>
											<TableCell align="right">${toFixed2(finalDebt)}</TableCell>
										</TableRow>
									);
								})}
								<TableRow>
									<TableCell colSpan={3} align="right">
										<Typography fontWeight={600}>Total adeudos:</Typography>
										<Typography fontWeight={600}>${toFixed2(totalDebts)}</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography fontWeight={600}>Total abonos:</Typography>
										<Typography fontWeight={600}>${toFixed2(totalPayments)}</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
		</Layout>
	);
};

export default ClientsAccounting;
