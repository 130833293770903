import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { useFormik } from 'formik';

import { ActionButtons, CustomSwitch } from 'components';
import { Permission } from 'common/constants/permissions';

const parseJsonPermissions = (data) => {
	const isEmpty = !data?.permissions;
	if (isEmpty) return [];
	else {
		const isStringified = typeof data.permissions === 'string';
		if (isStringified) return JSON.parse(data.permissions);
		return data.permissions;
	}
};

export const PermissionsForm = ({ onCancel, user, onSubmit, isProcessing }) => {
	const initialValues = {};
	const userPermissions = parseJsonPermissions(user.permissions);
	userPermissions.forEach((permission) => (initialValues[permission] = true));

	const form = useFormik({
		initialValues,
		onSubmit,
	});

	return (
		<div className="PermissionsForm">
			<form onSubmit={form.handleSubmit}>
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Permiso</TableCell>
								<TableCell>Activar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Permission.permissions.map(({ section, description, permissions }) => {
								return (
									<>
										<TableRow key={section} style={{ background: '#eee' }}>
											<TableCell style={{ fontWeight: 600 }} colSpan={2}>
												{description}
											</TableCell>
										</TableRow>
										{permissions.map(({ code, description }) => {
											return (
												<TableRow key={code}>
													<TableCell style={{ whiteSpace: 'nowrap' }}>{description}</TableCell>
													<TableCell>
														<CustomSwitch
															formik={form}
															initialValue={form.values[code]}
															name={code}
															id={code}
															key={code}
															label=""
															disabled={isProcessing}
														/>
													</TableCell>
												</TableRow>
											);
										})}
									</>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<ActionButtons style={{ marginTop: 16 }}>
					<Button onClick={onCancel}>Cancelar</Button>
					<Button variant="contained" type="submit">
						Guardar permisos
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};
