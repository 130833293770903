import { useCallback, useEffect, useState } from 'react';

import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { useFormik } from 'formik';

import Tools from 'utils/Tools';
import { UserService } from 'services';
import { useLoggedUser } from 'hooks';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { useSnackbar } from 'notistack';

const { getWriters, getEditors } = UserService;

const { getDate } = Tools;

const getTime = (date) => dayjs(date).add(5, 'hours');

const PaymentsFilters = ({ onSearch, initialValues }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { loggedUser } = useLoggedUser();
	const isAdmin = loggedUser.role === 'ADMIN';
	const [isLoading, setIsLoading] = useState(true);
	const [searchType, setSearchType] = useState(initialValues?.searchType || 'period');
	const [range, setRange] = useState([initialValues?.from || null, initialValues?.to || null]);
	const [period, setPeriod] = useState(initialValues?.period || 'last');
	const [idType, setIdType] = useState(initialValues?.idType || 'writerId');
	const [userId, setUserId] = useState(isAdmin ? null : loggedUser.id);
	const [users, setUsers] = useState([]);

	const calculatePeriod = useCallback(() => {
		const today = dayjs();
		const currentDay = today.date();
		let from = null;
		let to = null;

		if (period === 'current') {
			if (currentDay >= 7 && currentDay <= 21) {
				from = getDate({ date: getTime(`${today.year()}-${today.month() + 1}-7`) });
				to = getDate({ date: getTime(`${today.year()}-${today.month() + 1}-21`) });
			} else {
				let startMonth = today.month() + 1;
				let endMonth = today.month() + 1;
				let startYear = today.year();
				let endYear = today.year();

				if (currentDay < 7) {
					startMonth = today.subtract(1, 'month').month() + 1;

					if (today.month() === 0) {
						startYear = startYear - 1;
					}
				} else {
					endMonth = today.add(1, 'month').month() + 1;

					if (today.month() === 11) {
						endYear = startYear + 1;
					}
				}

				from = getDate({ date: getTime(`${startYear}-${startMonth}-22`) });
				to = getDate({ date: getTime(`${endYear}-${endMonth}-06`) });
			}
		} else {
			let startMonth = today.subtract(1, 'month').month() + 1;
			let endMonth = today.month() + 1;
			let startYear = today.year();
			let endYear = today.year();

			if (today.month() === 0) {
				startYear = startYear - 1;
			}

			if (currentDay >= 7 && currentDay <= 21) {
				from = getDate({ date: getTime(`${startYear}-${startMonth}-22`) });
				to = getDate({ date: getTime(`${endYear}-${endMonth}-06`) });
			} else {
				if (currentDay < 7) {
					if (today.month() === 0) {
						endYear = endYear - 1;
					}
					endMonth = today.subtract(1, 'month').month() + 1;
				} else {
					if (today.month() === 0) {
						startYear = startYear + 1;
					}
					startMonth = today.month() + 1;
				}

				from = getDate({ date: getTime(`${startYear}-${startMonth}-07`) });
				to = getDate({ date: getTime(`${endYear}-${endMonth}-21`) });
			}
		}

		return { from, to };
	}, [period]);

	const handleSubmit = (values) => {
		let fromToDates = calculatePeriod(values.period);

		if (searchType === 'range') {
			if (!values.range[0] || !values.range[1]) {
				enqueueSnackbar('Debe elegir ambas fechas', { variant: 'error' });
				return false;
			}

			fromToDates = {
				from: dayjs(values.range[0]).add(5, 'hours').format('YYYY-MM-DD'),
				to: dayjs(values.range[1]).add(5, 'hours').format('YYYY-MM-DD'),
			};
		}

		const payload = {
			...values,
			...fromToDates,
			userId: isAdmin ? values?.userId : loggedUser.id,
			idType: isAdmin ? values?.idType : idType,
		};

		delete payload.range;

		onSearch(payload);
	};

	const formik = useFormik({
		initialValues: {
			searchType: initialValues?.searchType || 'period',
			period: initialValues?.period || 'last',
			idType: initialValues?.idType || 'writerId',
			range:
				initialValues?.searchType === 'range'
					? [dayjs(initialValues.from).add(5, 'hours'), dayjs(initialValues.to).add(5, 'hours')]
					: [null, null],
		},
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		if (isLoading) {
			let fromToDates = calculatePeriod(period);

			if (searchType === 'range') {
				fromToDates = {
					from: initialValues.from,
					to: initialValues.to,
				};
			}
			const getUsers = idType === 'writerId' ? getWriters : getEditors;

			if (isAdmin) {
				getUsers(Tools.objectToQueryString({ orderBy: 'name', order: 'ASC' }))
					.then(({ data }) => {
						setUsers(data);
						const firstUserId = data[0].id;
						const payload = {
							...fromToDates,
							userId: initialValues?.userId || firstUserId,
							idType,
						};
						formik.values.userId = initialValues?.userId || firstUserId;
						setUserId(firstUserId);
						onSearch(payload);
						setIsLoading(false);
					})
					.catch((error) => {
						console.log(`error`, error);
						setIsLoading(false);
					});
			} else {
				onSearch(fromToDates);
				setIsLoading(false);
			}
		}
	}, [
		onSearch,
		isLoading,
		calculatePeriod,
		period,
		userId,
		idType,
		isAdmin,
		formik,
		initialValues,
		searchType,
	]);

	if (isLoading) return null;

	const { from, to } =
		searchType === 'period' ? calculatePeriod(period) : { from: range[0], to: range[1] };
	const startDate = getDate({ date: getTime(from), format: 'short' });
	const endDate = getDate({ date: getTime(to), format: 'short' });

	return (
		<div className="PaymentsFilters" style={{ marginBottom: 16 }}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={3}>
					{isAdmin && (
						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="searchType-label">Buscar por</InputLabel>
								<Select
									labelId="searchType-label"
									id="searchType"
									label="Buscar por"
									name="searchType"
									onChange={(e) => {
										formik.values.searchType = e.target.value;
										setSearchType(e.target.value);
									}}
									value={formik.values.searchType}
									error={formik.touched.searchType && Boolean(formik.errors.searchType)}
								>
									<MenuItem value="period">Quincenas</MenuItem>
									<MenuItem value="range">Rango de fechas</MenuItem>
								</Select>
								<FormHelperText error>
									{formik.touched.searchType && formik.errors.searchType}
								</FormHelperText>
							</FormControl>
						</Grid>
					)}
					{searchType === 'period' && (
						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="period-label">Quincena</InputLabel>
								<Select
									labelId="period-label"
									id="period"
									label="Quincena"
									name="period"
									onChange={(e) => {
										formik.values.period = e.target.value;
										setPeriod(e.target.value);
									}}
									value={formik.values.period}
									error={formik.touched.period && Boolean(formik.errors.period)}
								>
									<MenuItem value="current">Actual</MenuItem>
									<MenuItem value="last">Anterior</MenuItem>
								</Select>
								<FormHelperText error>
									{formik.touched.period && formik.errors.period}
								</FormHelperText>
							</FormControl>
						</Grid>
					)}
					{isAdmin && searchType === 'range' && (
						<Grid item xs={12} sm={6} lg={3}>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
								<DateRangePicker
									disableFuture
									labelId="range-label"
									name="range"
									value={formik.values.range}
									onChange={(values) => {
										setRange(values);
										formik.values.range = values;
									}}
									error={formik.touched.range && Boolean(formik.errors.range)}
									renderInput={(startProps, endProps) => (
										<Stack spacing={1} direction="row" sx={{ width: '100%' }}>
											<TextField {...startProps} label="Desde" sx={{ flex: 1 }} />
											<TextField {...endProps} label="Hasta" sx={{ flex: 1 }} />
										</Stack>
									)}
								/>
							</LocalizationProvider>
						</Grid>
					)}
					{isAdmin && [
						<Grid key="idTypeField" item xs={12} sm={6} lg={2}>
							<FormControl fullWidth>
								<InputLabel id="idType-label">Personal</InputLabel>
								<Select
									labelId="idType-label"
									id="idType"
									label="Personal"
									name="idType"
									onChange={(e) => {
										formik.values.idType = e.target.value;
										formik.values.userId = '';
										setIdType(e.target.value);
										setIsLoading(true);
									}}
									value={formik.values.idType}
									error={formik.touched.idType && Boolean(formik.errors.idType)}
								>
									<MenuItem value="writerId">Redactor</MenuItem>
									<MenuItem value="editorId">Editor</MenuItem>
								</Select>
								<FormHelperText error>
									{formik.touched.idType && formik.errors.idType}
								</FormHelperText>
							</FormControl>
						</Grid>,
						<Grid key="userIdField" item xs={12} sm={6} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="userId-label">Usuario</InputLabel>
								<Select
									labelId="userId-label"
									id="userId"
									label="Usuario"
									name="userId"
									onChange={(e) => {
										formik.values.userId = e.target.value;
										setUserId(e.target.value);
										if (isLoading) setIsLoading(false);
									}}
									value={formik.values.userId}
									error={formik.touched.userId && Boolean(formik.errors.userId)}
								>
									{users.map(({ id, name, lastName }) => {
										return (
											<MenuItem key={id} value={id}>
												{name} {lastName}
											</MenuItem>
										);
									})}
								</Select>
								<FormHelperText error>
									{formik.touched.userId && formik.errors.userId}
								</FormHelperText>
							</FormControl>
						</Grid>,
					]}
					<Grid key="submit" item xs={12} sm={6} lg={2}>
						<Button fullWidth variant="outlined" type="submit">
							Buscar
						</Button>
					</Grid>
				</Grid>
			</form>
			<div className="PaymentFilters" style={{ marginTop: 8 }}>
				<strong>Desde: </strong>
				{startDate} - <strong>Hasta: </strong>
				{endDate}
			</div>
		</div>
	);
};

export default PaymentsFilters;
