import { useFormik } from 'formik';
import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	FormHelperText,
	TextField,
	MenuItem,
	Button,
} from '@mui/material';
import { ActionButtons } from 'components';

const EditorInfoForm = ({ paymentMethods, userInfo, onSubmit }) => {
	const formik = useFormik({
		initialValues: {
			paymentMethodId: userInfo?.paymentMethodId || '',
			paymentInfo: userInfo?.paymentInfo || '',
		},
		onSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container rowSpacing={2} columnSpacing={5}>
				<Grid item xs={12} sm={6} md={4}>
					<FormControl fullWidth margin="normal">
						<InputLabel id="paymentMethodId-label">Método de pago</InputLabel>
						<Select
							labelId="paymentMethodId-label"
							id="paymentMethodId"
							label="Método de pago"
							name="paymentMethodId"
							onChange={formik.handleChange}
							value={formik.values.paymentMethodId}
							error={formik.touched.paymentMethodId && Boolean(formik.errors.paymentMethodId)}
						>
							{paymentMethods.map(({ id, name }) => (
								<MenuItem key={id} value={id}>
									{name}
								</MenuItem>
							))}
						</Select>
						<FormHelperText error>
							{formik.touched.paymentMethodId && formik.errors.paymentMethodId}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<TextField
						fullWidth
						multiline
						minRows={2}
						maxRows={3}
						id="paymentInfo"
						name="paymentInfo"
						label="Información de pago"
						value={formik.values.paymentInfo}
						onChange={formik.handleChange}
						error={formik.touched.paymentInfo && Boolean(formik.errors.paymentInfo)}
						helperText={formik.touched.paymentInfo && formik.errors.paymentInfo}
						margin="normal"
					/>
				</Grid>
			</Grid>
			<ActionButtons style={{ marginTop: 16 }}>
				<Button type="submit" variant="outlined" size="small">
					Guardar
				</Button>
			</ActionButtons>
		</form>
	);
};

export default EditorInfoForm;
