import { useState } from 'react';
import { Button, Grid } from '@mui/material';

import { useLoggedUser } from 'hooks';
import Tools from 'utils/Tools';

import {
	ActionButtons,
	FeaturedChip,
	LabeledValue,
	Layout,
	PageTitle,
	TitledBox,
} from 'components';
import ProfileEdit from './ProfileEdit';
import WriterPreferences from './WriterPreferences';

const Profile = () => {
	const [showProfileEdit, setShowProfileEdit] = useState(false);
	const { loggedUser } = useLoggedUser();
	const { email, phone, role, isEnabled } = loggedUser;

	const profile = [
		{ key: 'name', label: 'Nombre', value: Tools.getFullName(loggedUser) },
		{ key: 'email', label: 'Correo electrónico', value: email },
		{ key: 'phone', label: 'Teléfono', value: phone || '(No disponible)' },
		{ key: 'role', label: 'Rol (no editable)', value: Tools.getRoleLabel(role) },
		{
			key: 'status',
			label: 'Estatus (no editable)',
			value: isEnabled ? 'Activo' : 'Deshabilitado',
		},
	];

	const isFeatured = loggedUser?.userInfo?.isFeatured || false;
	const featuredChip = [isFeatured && <FeaturedChip />];

	return (
		<Layout>
			<PageTitle title="Mi perfil" actionButtons={featuredChip} />
			<TitledBox title="Datos personales">
				<Grid container spacing={2}>
					{profile.map(({ key, label, value }) => (
						<Grid key={key} item xs={12} sm={6} md={4}>
							<LabeledValue label={label} value={value} />
						</Grid>
					))}
				</Grid>
				<ActionButtons>
					<Button variant="outlined" size="small" onClick={() => setShowProfileEdit(true)}>
						Editar perfil
					</Button>
				</ActionButtons>
			</TitledBox>
			<WriterPreferences />
			{showProfileEdit && (
				<ProfileEdit user={loggedUser} onClose={() => setShowProfileEdit(false)} />
			)}
		</Layout>
	);
};

export default Profile;
