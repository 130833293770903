import { useState } from 'react';
import { useFormik } from 'formik';
import {
	Button,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
} from '@mui/material';

import roles, { rolesArray } from 'common/constants/roles';
import validationSchemas from 'utils/validationSchemas';
import { ActionButtons } from 'components';
import NumericInput from 'material-ui-numeric-input';

const AddUserForm = ({ onSubmit, onCancel, userRole = 'WRITER' }) => {
	const [isFeaturedUser, setIsFeaturedUser] = useState(false);
	const formik = useFormik({
		initialValues: {
			name: '',
			lastName: '',
			idCont: '',
			email: '',
			phone: '',
			password: '',
			confirmPassword: '',
			role: userRole,
			isFeatured: false,
			bonus: 0,
		},
		validationSchema: validationSchemas.userAdd,
		onSubmit,
	});

	return (
		<div className="AddUserForm">
			<form onSubmit={formik.handleSubmit}>
				<TextField
					fullWidth
					id="name"
					name="name"
					label="Nombres"
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					margin="normal"
					sx={{ mt: 1 }}
				/>
				<TextField
					fullWidth
					id="lastName"
					name="lastName"
					label="Apellidos"
					value={formik.values.lastName}
					onChange={formik.handleChange}
					error={formik.touched.lastName && Boolean(formik.errors.lastName)}
					helperText={formik.touched.lastName && formik.errors.lastName}
					margin="normal"
				/>
				{formik.values.role === roles.CLIENT.value && (
					<TextField
						fullWidth
						id="idCont"
						name="idCont"
						label="ID Cont."
						value={formik.values.idCont}
						onChange={formik.handleChange}
						error={formik.touched.idCont && Boolean(formik.errors.idCont)}
						helperText={formik.touched.idCont && formik.errors.idCont}
						margin="normal"
					/>
				)}
				<TextField
					fullWidth
					id="email"
					name="email"
					label="Correo electrónico"
					value={formik.values.email}
					onChange={formik.handleChange}
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
					margin="normal"
				/>
				<TextField
					fullWidth
					id="phone"
					name="phone"
					label="Teléfono celular"
					value={formik.values.phone}
					onChange={formik.handleChange}
					error={formik.touched.phone && Boolean(formik.errors.phone)}
					helperText={formik.touched.phone && formik.errors.phone}
					margin="normal"
				/>
				<TextField
					fullWidth
					id="password"
					name="password"
					label="Contraseña"
					type="password"
					value={formik.values.password}
					onChange={formik.handleChange}
					error={formik.touched.password && Boolean(formik.errors.password)}
					helperText={formik.touched.password && formik.errors.password}
					margin="normal"
				/>
				<TextField
					fullWidth
					id="confirmPassword"
					name="confirmPassword"
					label="Confirma tu contraseña"
					type="password"
					value={formik.values.confirmPassword}
					onChange={formik.handleChange}
					error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
					helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
					margin="normal"
				/>

				<FormControl fullWidth margin="normal">
					<InputLabel id="role-label">Rol</InputLabel>
					<Select
						labelId="role-label"
						id="role"
						label="Rol"
						name="role"
						onChange={formik.handleChange}
						value={formik.values.role}
						disabled
					>
						{rolesArray.map(({ label, value }) => (
							<MenuItem key={value} value={value}>
								{label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{formik.values.role === roles.WRITER.value && (
					<NumericInput
						name="bonus"
						precision={1}
						decimalChar=","
						thousandChar="."
						label="Bono"
						onChange={(e) => (formik.values.bonus = e.target.value)}
						variant="outlined"
						value={formik.values.bonus}
						defaultValue={Number(formik.values.bonus).toLocaleString('es')}
						helperText={formik.touched.bonus && formik.errors.bonus}
						error={formik.touched.bonus && Boolean(formik.errors.bonus)}
						margin="normal"
						fullWidth
					/>
				)}
				{userRole === roles.WRITER.value && (
					<FormControlLabel
						sx={{ width: '100%' }}
						control={
							<Switch
								name="isFeatured"
								id="isFeatured"
								onChange={(event) => {
									setIsFeaturedUser(event.target.checked);
									formik.values.isFeatured = event.target.checked;
								}}
								checked={isFeaturedUser}
							/>
						}
						onChange={formik.handleChange}
						label="Redactor destacado"
					/>
				)}
				<ActionButtons style={{ marginTop: 8 }}>
					<Button onClick={onCancel}>Cancelar</Button>
					<Button variant="contained" type="submit">
						Agregar {roles[userRole].label}
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default AddUserForm;
