import { useState } from 'react';

import { SwipeableDrawer } from '@mui/material';

import { useAuth } from 'hooks';

import { Spinner } from 'components';

import UserMenu from '../UserMenu';

const SideDrawer = ({ isOpen, onClose }) => {
	const [isClosing, setIsClosing] = useState(false);
	const { onLogout } = useAuth();

	const handleLogout = () => {
		setIsClosing(true);
		setTimeout(() => {
			onLogout();
		}, 500);
	};

	return (
		<SwipeableDrawer anchor="left" open={isOpen} onOpen={() => {}} onClose={onClose}>
			<UserMenu onLogout={handleLogout} onClickItem={onClose} />
			<Spinner isOpen={isClosing} text="Cerrando sesión..." />
		</SwipeableDrawer>
	);
};

export default SideDrawer;
