import { Alert, Typography } from '@mui/material';
import assignmentStatus from 'common/constants/assignmentStatus';
import React from 'react';
import Tools from 'utils/Tools';

const fs14 = { fontSize: 14 };

const CurrentAssignments = ({ currentAssignments, isWriterSelected }) => {
	return (
		<div className="CurrentAssignments">
			{isWriterSelected && (
				<>
					{!currentAssignments.length && (
						<Alert severity="info">El redactor no tiene asignaciones en este momento.</Alert>
					)}
					{!!currentAssignments.length && (
						<div className="CurrentAssignments-wrapper">
							<Typography variant="strong">Actualmente trabajando en:</Typography>
							<div className="CurrentAssignments-list">
								{currentAssignments.map((assignment, index) => {
									const { title, status, minWords, maxWords, endDate, id } = assignment;

									return (
										<div key={id} className="CurrentAssignments-list-item">
											<Typography variant="strong" sx={fs14} component="h4">
												{index + 1}. {title}
											</Typography>
											<Typography variant="strong" sx={fs14}>
												{assignmentStatus[status].label}. De {minWords} a {maxWords} palabras. Para
												el {Tools.getDate({ date: endDate, format: 'short' })}.
											</Typography>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default CurrentAssignments;
