export const taskStatus = {
	PENDING: {
		label: 'Pendiente',
		value: 'PENDING',
	},
	ACCEPTED: {
		label: 'Aceptada',
		value: 'ACCEPTED',
	},
	REJECTED: {
		label: 'Rechazada',
		value: 'REJECTED',
	},
	PAUSED: {
		label: 'Pausada',
		value: 'PAUSED',
	},
	IN_PROGRESS: {
		label: 'En progreso',
		value: 'IN_PROGRESS',
	},
	COMPLETED: {
		label: 'Completada',
		value: 'COMPLETED',
	},
};
