import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogContent, TextField } from '@mui/material';

import { ActionButtons, CustomDialogTitle, Spinner } from 'components';
import Tools from 'utils/Tools';
import validationSchemas from 'utils/validationSchemas';
import { useService } from 'hooks';
import { TaskTypeService } from 'services';

const { createTaskType, updateTaskType } = TaskTypeService;

export const TaskTypesDialog = ({ onClose, taskType, onFinish }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { callService, isProcessing } = useService();

	const handleSubmit = (values, { setFieldError }) => {
		const handler = taskType ? updateTaskType : createTaskType;
		const args = taskType ? [taskType.id, values] : [values];

		callService(handler(...args))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onFinish(true);
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	const form = useFormik({
		initialValues: {
			code: taskType?.code || '',
			name: taskType?.name || '',
			duration: taskType?.duration || 0,
			description: taskType?.description || '',
		},
		validationSchema: validationSchemas.taskType,
		onSubmit: handleSubmit,
	});

	return (
		<Dialog open onClose={() => onClose()}>
			<Spinner isOpen={isProcessing} text={taskType ? 'Guardando tarea' : 'Agregando tarea'} />
			<CustomDialogTitle>{taskType ? 'Editar' : 'Añadir'} Tipo de tarea</CustomDialogTitle>
			<DialogContent>
				<form onSubmit={form.handleSubmit}>
					<TextField
						error={form.touched.code && Boolean(form.errors.code)}
						fullWidth
						helperText={form.touched.code && form.errors.code}
						id="code"
						label="Código"
						margin="normal"
						name="code"
						onChange={form.handleChange}
						size="small"
						value={form.values.code}
					/>
					<TextField
						error={form.touched.name && Boolean(form.errors.name)}
						fullWidth
						helperText={form.touched.name && form.errors.name}
						id="name"
						label="Nombre"
						margin="normal"
						name="name"
						onChange={form.handleChange}
						size="small"
						value={form.values.name}
					/>
					<TextField
						error={form.touched.duration && Boolean(form.errors.duration)}
						fullWidth
						helperText={form.touched.duration && form.errors.duration}
						id="duration"
						label="Duración (minutos)"
						margin="normal"
						name="duration"
						onChange={form.handleChange}
						type="number"
						value={form.values.duration}
					/>
					<TextField
						fullWidth
						multiline
						minRows={2}
						maxRows={6}
						id="description"
						name="description"
						label="Descripción"
						value={form.values.description}
						onChange={form.handleChange}
						error={form.touched.description && Boolean(form.errors.description)}
						helperText={form.touched.description && form.errors.description}
						margin="normal"
					/>
					<ActionButtons sx={{ mt: 1 }}>
						<Button onClick={() => onClose()}>Cancelar</Button>
						<Button type="submit" color="primary" variant="contained">
							{taskType ? 'Guardar' : 'Añadir'}
						</Button>
					</ActionButtons>
				</form>
			</DialogContent>
		</Dialog>
	);
};
