import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import CustomDialogTitle from 'components/CustomDialogTitle';

const ConfirmDialog = ({
	onCancel,
	onOk,
	question,
	okButtonText = 'Aceptar',
	okButtonType = 'success',
	children,
}) => {
	return (
		<Dialog maxWidth="xs">
			<CustomDialogTitle>Confirmación</CustomDialogTitle>
			<DialogContent>
				<Typography fontWeight={600} fontSize={18}>
					{question}
				</Typography>
				<Typography mt={1}>{children}</Typography>
			</DialogContent>
			<DialogActions style={{ padding: 16 }}>
				<Button autoFocus onClick={onCancel}>
					Cancelar
				</Button>
				<Button onClick={onOk} variant="contained" color={okButtonType}>
					{okButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
