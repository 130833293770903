import { Typography } from '@mui/material';

const LabeledDescription = ({ label, value }) => {
	return (
		<div className="LabeledDescription">
			<div className="LabeledDescription-label">
				<Typography variant="caption" component="label" sx={{ fontWeight: 600 }}>
					{label}
				</Typography>
			</div>
			<div className="LabeledDescription-value">
				{typeof value === 'string' ? <Typography variant="body1">{value}</Typography> : value}
			</div>
		</div>
	);
};

export default LabeledDescription;
