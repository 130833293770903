const extraTypes = {
	BONUS: {
		label: 'Bono',
		value: 'BONUS',
	},
	DISCOUNT: {
		label: 'Descuento',
		value: 'DISCOUNT',
	},
};

export default extraTypes;
