import axios from 'axios';
import Http from './Http';

import { api } from 'config';

const endpoint = 'assignments';

const assignment = {
	getAssignments(query) {
		return Http.get(`${endpoint}${query && `?${query}`}`);
	},

	getAssignmentFiles(assignmentId) {
		return Http.get(`${endpoint}/${assignmentId}/files`);
	},

	getAssignment(assignmentId) {
		return Http.get(`${endpoint}/${assignmentId}`);
	},

	getMyAssignments(query) {
		return Http.get(`${endpoint}/mine${query ? `?${query}` : ''}`);
	},

	getWriterCurrentAssignments(writerId) {
		return Http.get(`${endpoint}/current/${writerId}`);
	},

	getWriterLastAssignment(writerId) {
		return Http.get(`${endpoint}/last/${writerId}`);
	},

	createAssignment(data) {
		return Http.post(endpoint, data);
	},

	updateAssignment(assignmentId, data) {
		return Http.put(`${endpoint}/${assignmentId}`, data);
	},

	uploadAssignment(assignmentId, data) {
		const formData = new FormData();
		formData.append('assignment', data.file);

		axios.interceptors.request.use((config) => {
			if (localStorage.token) {
				config.headers.common.Authorization = `${localStorage.token}`; // eslint-disable-line
			}
			return config;
		});

		const headers = {
			'Content-Type': 'multipart/form-data',
		};

		return axios.post(`${api}/${endpoint}/${assignmentId}/file`, formData, { headers });
	},
};

export default assignment;
