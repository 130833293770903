import { useEffect, useState } from 'react';
import { Dialog, DialogContent, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import Draggable from 'react-draggable';

import { useService } from 'hooks';

import { AssignmentService, UserService, ProjectService } from 'services';
import Tools from 'utils/Tools';

import { CustomDialogTitle, Spinner } from 'components';
import AssignmentForm from './AssignmentForm';

import './AssignmentDialog.scss';

const { createAssignment, updateAssignment, getWriterCurrentAssignments } = AssignmentService;
const { getClients, getEditors, getWritersSuggestions } = UserService;
const { getProjects } = ProjectService;

function PaperComponent(props) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const AssignmentDialog = ({ assignment, project, onCancel, onSuccess }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [clients, setClients] = useState([]);
	const [projects, setProjects] = useState([]);
	const [editors, setEditors] = useState([]);
	const [writers, setWriters] = useState([]);
	const [currentAssignments, setCurrentAssignments] = useState([]);
	const { isProcessing, callService } = useService();
	const { isProcessing: isLoadingWriters, callService: callWriters } = useService();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			Promise.all([getClients(), getEditors()]).then(
				([{ data: clientsData }, { data: editorsData }]) => {
					setClients(clientsData);
					setEditors(editorsData);

					if (!assignment) {
						setIsLoading(false);
					}
				}
			);

			if (assignment || project) {
				Promise.all([
					getProjects(`clientId=${assignment?.clientId ?? project?.clientId}`),
					getWritersSuggestions(assignment?.projectId ?? project?.id),
				]).then(([{ data: projectsData }, { data: writersData }]) => {
					setProjects(projectsData);
					setWriters(writersData);

					setIsLoading(false);
				});
			}
		}
	}, [isLoading, assignment, project]);

	const handleSubmit = (values, { setFieldError }) => {
		if (!assignment) {
			const payload = { ...values, editorId: values?.editorId || null };

			callService(createAssignment(payload))
				.then(({ data }) => {
					enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
					onCancel();
					onSuccess();
				})
				.catch((error) => {
					const validationErrors = Tools.getValidationErrors(error);
					if (validationErrors) {
						enqueueSnackbar('Verifique los campos', { variant: 'error' });
						setFieldError(validationErrors.field, validationErrors.message);
					}
				});
		} else {
			callService(updateAssignment(assignment.id, values))
				.then(({ data }) => {
					enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
					onCancel();
					onSuccess();
				})
				.catch((error) => {
					const validationErrors = Tools.getValidationErrors(error);
					if (validationErrors) {
						enqueueSnackbar('Verifique los campos', { variant: 'error' });
						setFieldError(validationErrors.field, validationErrors.message);
					}
				});
		}
	};

	const handleChangeClient = (clientId) => {
		callService(getProjects(`clientId=${clientId}`))
			.then(({ data }) => {
				setProjects(data);
			})
			.catch((error) => {
				console.log(`error`, error);
			});
	};

	const handleChangeProject = (projectId) => {
		callWriters(getWritersSuggestions(projectId))
			.then(({ data }) => {
				setWriters(data);
			})
			.catch((error) => {
				console.log(`error`, error);
			});
	};

	const handleChangeWriter = (writerId) => {
		callService(getWriterCurrentAssignments(writerId))
			.then(({ data }) => {
				setCurrentAssignments(data);
			})
			.catch((error) => {
				console.log(`error`, error);
			});
	};

	return (
		<Dialog
			open
			className="AssignmentDialog"
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
		>
			<CustomDialogTitle draggable>{assignment ? 'Editar' : 'Nueva'} asignación</CustomDialogTitle>
			<DialogContent>
				{isLoading && <Spinner small text="Cargando datos" isOpen />}
				{!isLoading && (
					<AssignmentForm
						assignment={assignment}
						clients={clients}
						currentAssignments={currentAssignments}
						editors={editors}
						isLoading={isLoading}
						isLoadingWriters={isLoadingWriters}
						onCancel={onCancel}
						onChangeClient={handleChangeClient}
						onChangeProject={handleChangeProject}
						onChangeWriter={handleChangeWriter}
						onSubmit={handleSubmit}
						project={project}
						projects={projects}
						writers={writers}
					/>
				)}
			</DialogContent>
			<Spinner text="Procesando" isOpen={isProcessing} />
		</Dialog>
	);
};

export default AssignmentDialog;
