import { useState } from 'react';

import { PaymentService } from 'services';

import { Layout, PageTitle, PaymentsFilters, UserPaymentsList } from 'components';
import Tools from 'utils/Tools';
import { Divider, Grid } from '@mui/material';
import UserNotificationButton from 'components/UserNotificationButton';
// import { useService } from 'hooks';
// import paymentStatus from 'common/constants/paymentStatus';
// import { useSnackbar } from 'notistack';

const { getPaymentsByUser } = PaymentService;
const { objectToQueryString } = Tools;

const Payments = () => {
	// const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(true);
	const [payments, setPayments] = useState(null);
	// const { isProcessing: isPostponing, callService } = useService();
	// const [period, setPeriod] = useState('last');

	const handleSearch = (dates) => {
		// setPeriod(dates.period);
		const payload = { ...dates, idType: 'writerId' };
		const query = objectToQueryString(payload);
		setIsLoading(true);
		getPaymentsByUser(query)
			.then(({ data }) => {
				setPayments(data);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(`error`, error);
				setIsLoading(false);
			});
	};

	// const handlePostpone = () => {
	// 	const { payments: currentPayments = [] } = payments;
	// 	if (!!currentPayments?.length) {
	// 		const pendingPaymentsIds = currentPayments
	// 			.filter(({ status }) => status === paymentStatus.PENDING.value)
	// 			.map(({ id }) => id);

	// 		if (!!pendingPaymentsIds.length) {
	// 			callService(postponeMyPayments(pendingPaymentsIds))
	// 				.then(({ data }) => {
	// 					const { message, extras, postponedPayments } = data;
	// 					let updatedPayments = [];
	// 					for (const payment of postponedPayments) {
	// 						updatedPayments = Tools.updateById(payments.payments, payment);
	// 					}

	// 					console.log('data', data);
	// 					setPayments((state) => ({
	// 						payments: updatedPayments,
	// 						extra: [...state.extra, ...extras],
	// 					}));

	// 					enqueueSnackbar(message, { variant: 'success' });
	// 				})
	// 				.catch((error) => {
	// 					console.log(`error`, error);
	// 				});
	// 		} else enqueueSnackbar('No hay pagos pendientes', { variant: 'info' });
	// 	} else enqueueSnackbar('No hay pagos a procesar', { variant: 'info' });
	// };

	return (
		<Layout>
			<PageTitle title="Mi facturación" />
			<Grid container spacing={2}>
				<Grid item md={6}>
					<PaymentsFilters onSearch={handleSearch} />
				</Grid>
				<Grid item md={6} display="flex" justifyContent="flex-end" />
			</Grid>
			<UserPaymentsList paymentData={payments} isLoading={isLoading} />
			<Divider sx={{ my: 2 }} />
			<UserNotificationButton />
		</Layout>
	);
};

export default Payments;
