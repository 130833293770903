import { useLoggedUser } from 'hooks';
import roles from 'common/constants/roles';

const CanI = ({ make, children }) => {
	const {
		loggedUser: { role, permissions },
	} = useLoggedUser();

	if (role === roles.ADMIN.value) return children;

	return permissions.includes(make) ? children : null;
};

export default CanI;
