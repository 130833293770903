import { Button, TextField } from '@mui/material';
import ActionButtons from 'components/ActionButtons';
import { useFormik } from 'formik';
import validationSchemas from 'utils/validationSchemas';

const initialValues = { message: '' };

const UserNotificationForm = ({ onSubmit, isProcessing }) => {
	const form = useFormik({
		initialValues,
		onSubmit,
		validationSchema: validationSchemas.userNotification,
	});

	return (
		<form className="UserNotificationForm" onSubmit={form.handleSubmit}>
			<TextField
				fullWidth
				multiline
				minRows={3}
				maxRows={10}
				id="message"
				name="message"
				label="Mensaje"
				value={form.values.message}
				onChange={form.handleChange}
				error={form.touched.message && Boolean(form.errors.message)}
				helperText={form.touched.message && form.errors.message}
				margin="normal"
			/>
			<ActionButtons>
				<Button color="primary" type="submit" variant="contained" disabled={isProcessing}>
					Notificar
				</Button>
			</ActionButtons>
		</form>
	);
};

export default UserNotificationForm;
