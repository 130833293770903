import Http from './Http';

const endpoint = 'client-rates';

const rates = {
	getWriterClientRates() {
		return Http.get(endpoint);
	},

	updateWriterClientRates(data) {
		return Http.put(endpoint, data);
	},
};

export default rates;
