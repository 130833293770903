import { useEffect, useState } from 'react';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Link,
	Typography,
} from '@mui/material';

import { UserService } from 'services';
import { CustomDialogTitle, Spinner, StatusChip, TitledBox } from 'components';
import roles from 'common/constants/roles';
import LabeledDescription from 'components/LabeledDescription';
import Tools from 'utils/Tools';

import './UserDetails.scss';
import { ExpandMoreOutlined } from '@mui/icons-material';

const { getFullName, getDate, getDangerousHtmlObject } = Tools;

const UserDetails = ({ user, onClose }) => {
	const [writer, setWriter] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (isLoading) {
			UserService.getWriterInfo(user.id)
				.then(({ data }) => {
					setWriter(data);
					setIsLoading(false);
				})
				.catch((error) => {
					console.log('error', error);
					setIsLoading(false);
				});
		}
	}, [isLoading, user]);

	const {
		id,
		email,
		isEnabled,
		phone,
		role,
		userInfo: { createdAt, isAvailable, isFeatured, wordsPerDay },
	} = user;

	const { profileInfo, ratings } = writer || {};

	const waMeLink = phone ? (
		<Link target="_blank" href={`https://wa.me/${phone.replace('+', '')}`}>
			{phone}
		</Link>
	) : (
		'(No registrado)'
	);

	return (
		<Dialog maxWidth="lg" className="UserDetails" onClose={onClose}>
			<CustomDialogTitle>{getFullName(user)}</CustomDialogTitle>
			<DialogContent>
				<TitledBox title="Información básica" style={{ marginTop: 8 }}>
					<div className="UserDetails-info">
						<LabeledDescription label="Rol" value={<strong>{roles[role].label}</strong>} />
						<LabeledDescription label="ID de usuario" value={id} />
						<LabeledDescription
							label="Estatus"
							value={<StatusChip isEnabled={isEnabled} size="small" />}
						/>
						<LabeledDescription label="Correo electrónico" value={email} />
						<LabeledDescription label="Teléfono" value={waMeLink} />
						<LabeledDescription
							label="Creado en"
							value={getDate({ date: createdAt, format: 'long' })}
						/>
						<LabeledDescription label="Disponible" value={isAvailable ? 'Sí' : 'No'} />
						<LabeledDescription label="Destacado" value={isFeatured ? 'Sí' : 'No'} />
						<LabeledDescription
							label="Palabras estimadas por día"
							value={wordsPerDay || '(No ha indicado)'}
						/>
					</div>
				</TitledBox>
				<TitledBox title="Acerca de" style={{ marginTop: 8 }}>
					<div className="UserDetails-about">
						<LabeledDescription
							label="Perfil"
							value={
								profileInfo ? (
									<div dangerouslySetInnerHTML={getDangerousHtmlObject(profileInfo)} />
								) : (
									'(No agregado)'
								)
							}
						/>
						{/* <LabeledDescription label="Aspectos a mejorar" value={goals || '(No agregados)'} />
						<LabeledDescription label="Fortalezas" value={strength || '(No agregados)'} /> */}
					</div>
				</TitledBox>
				{isLoading && <Spinner text="Cargando preferencias" small isOpen />}
				{!isLoading && (
					<TitledBox title="Preferencias" style={{ marginTop: 8 }}>
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreOutlined />}
								aria-controls="categories-content"
								id="categories-header"
							>
								<Typography>Etiquetas/Categorías</Typography>
							</AccordionSummary>
							<AccordionDetails className="UserDetails-ratings">
								{ratings?.categories?.map(({ categoryId, name, rate }) => (
									<LabeledDescription label={name} key={categoryId} value={rate} />
								))}
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreOutlined />}
								aria-controls="clients-content"
								id="clients-header"
							>
								<Typography>Clientes</Typography>
							</AccordionSummary>
							<AccordionDetails className="UserDetails-ratings">
								{ratings?.clients?.map(({ clientId, name, lastName, rate }) => (
									<LabeledDescription
										label={getFullName({ name, lastName })}
										key={clientId}
										value={rate}
									/>
								))}
							</AccordionDetails>
						</Accordion>
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreOutlined />}
								aria-controls="postTypes-content"
								id="postTypes-header"
							>
								<Typography>Tipos de posts</Typography>
							</AccordionSummary>
							<AccordionDetails className="UserDetails-ratings">
								{ratings?.postTypes?.map(({ postTypeId, name, rate }) => (
									<LabeledDescription label={name} key={postTypeId} value={rate} />
								))}
							</AccordionDetails>
						</Accordion>
					</TitledBox>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Listo</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UserDetails;
