import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
	Button,
	Dialog,
	DialogContent,
	Divider,
	Grid,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';

import { useLoggedUser, useService } from 'hooks';

import QualificationService from 'services/QualificationService';
import { AssignmentService } from 'services';
import validationSchemas from 'utils/validationSchemas';
import CustomDialogTitle from 'components/CustomDialogTitle';
import ActionButtons from 'components/ActionButtons';
import Spinner from 'components/Spinner';
import { TotalWordsForm } from './TotalWordsForm';
import Tools from 'utils/Tools';
import assignmentStatus from 'common/constants/assignmentStatus';
import { EditorQualification } from './EditorQualification';
import { findCriteria } from './findCriteria';

const {
	getQualificationCriteria,
	addQualification,
	getAssignmentQualification,
	updateQualification,
} = QualificationService;

const { getWriterLastAssignment } = AssignmentService;

// const calcPercentageLost = (criteriaPercentage, totalWords) => {
// 	return 100 * (1 - Math.exp((-1000 * criteriaPercentage) / totalWords / 100));
// };

const calcEfficiency = (records, totalWords) => {
	const totalPercentage = records.reduce((accumulator, { percentage, timesFound }) => {
		let subtotal = 0;

		for (let index = 0; index < timesFound; index += 1) {
			subtotal = accumulator + subtotal + percentage;
		}

		return subtotal;
	}, 0);

	const efficiency = 100 * Math.exp((-1000 * totalPercentage) / totalWords / 100);

	return efficiency;
};

const nonComment = '(N/A)';

const QualificationSystem = ({ onCancel, assignment, open, onFinish, onUpdateAssignment }) => {
	const [loadingCriteria, setLoadingCriteria] = useState(true);
	const [loadingLastAssignment, setLoadingLastAssignment] = useState(true);
	const [lastAssignment, setLastAssignment] = useState([]);
	const [isLoadingQualification, setIsLoadingQualification] = useState(true);
	const [criteria, setCriteria] = useState([]);
	const [qualification, setQualification] = useState(null);
	const { isProcessing, callService } = useService();
	const {
		loggedUser: { isAdmin, isWriter, isAssistant, isEditor },
	} = useLoggedUser();

	const currentDateTime = useMemo(() => dayjs(new Date()).utc().format('YYYY-MM-DD HH:mm:ss'), []);

	const countCriteria = () => {
		const { comment } = formik.values;
		const canAdmin = isAdmin || isAssistant;
		const theComment = String(comment).trim();
		const { isRated, isFeatured } = assignment;

		let writerQualification = null;

		const criteriaFound = findCriteria(criteria, comment);
		const efficiency = (calcEfficiency(criteriaFound, assignment.totalWords) * 5) / 100;

		if (canAdmin) {
			if (isFeatured) {
				if (!['', nonComment].includes(theComment)) {
					writerQualification = efficiency;
				} else {
					const [last, beforeLast] = lastAssignment;

					if (!isRated) {
						writerQualification = last?.writerQualification || 5;
					} else {
						if (last.id !== assignment.id) {
							writerQualification = last?.writerQualification || 5;
						} else {
							writerQualification = beforeLast?.writerQualification || 5;
						}
					}
				}
			} else {
				writerQualification = efficiency;
			}
		} else {
			writerQualification = efficiency;
		}

		onUpdateAssignment({
			writerQualification,
			qualifiedAt: currentDateTime,
			isRated: true,
		});
	};

	const handleAddQualification = (values) => {
		const payload = {
			...values,
			criteriaId: criteria[0]?.id,
			article_text: 'Artículo',
			isRated: true,
		};

		const apiCall = qualification ? updateQualification : addQualification;
		const args = qualification ? [qualification.id, payload] : [assignment.id, payload];

		callService(apiCall(...args))
			.then(() => {
				if (!qualification) setIsLoadingQualification(true);
				countCriteria();
			})
			.catch(console.log);
	};

	const formik = useFormik({
		initialValues: {
			wellDone: assignment?.wellDone || '',
			mustDoBetter: assignment?.mustDoBetter || '',
			mustFix: assignment?.mustFix || '',
			comment: !!assignment.isFeatured ? nonComment : '',
		},
		validationSchema: validationSchemas.qualification,
		onSubmit: handleAddQualification,
	});

	useEffect(() => {
		if (loadingCriteria) {
			getQualificationCriteria()
				.then(({ data }) => {
					setCriteria(data);
				})
				.catch(console.log)
				.finally(() => setLoadingCriteria(false));
		}
	}, [loadingCriteria]);

	useEffect(() => {
		if (!!assignment.isFeatured && loadingLastAssignment) {
			getWriterLastAssignment(assignment.writerId)
				.then(({ data }) => {
					if (data) {
						setLastAssignment(data);
						const [last] = data;

						if (!assignment.isRated) {
							if (!!last)
								onUpdateAssignment({
									writerQualification: last.writerQualification,
									qualifiedAt: currentDateTime,
									isRated: true,
								});
						}
					}
				})
				.catch(console.log)
				.finally(() => setLoadingLastAssignment(false));
		} else setLoadingLastAssignment(false);
	}, [assignment, loadingLastAssignment, onUpdateAssignment, currentDateTime]);

	useEffect(() => {
		if (isLoadingQualification) {
			getAssignmentQualification(assignment.id)
				.then(({ data }) => {
					if (!!data?.length) {
						formik.setFieldValue('comment', data[data.length - 1].comment);
						setQualification(data[data.length - 1]);
					}
				})
				.catch(console.log)
				.finally(() => setIsLoadingQualification(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoadingQualification]);

	const handleClickCriteria = (description) => {
		formik.setFieldValue(
			'comment',
			`${formik.values.comment}${formik.values.comment ? '\n' : ''}${description}: `
		);
	};

	const hasTotalWords = !!assignment.totalWords;

	const disableForFeaturedWriter = !!assignment.isFeatured && isEditor;

	return (
		<Dialog open={open} maxWidth="lg" fullWidth onClose={onCancel}>
			<CustomDialogTitle>Calificaciones para "{assignment.title}"</CustomDialogTitle>
			<DialogContent sx={{ overflow: 'hidden' }}>
				{!isWriter && (
					<div>
						<TotalWordsForm assignment={assignment} onSubmit={onUpdateAssignment} />
						<Divider sx={{ my: 1 }} />
					</div>
				)}

				{hasTotalWords && (
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={2}>
							{!isWriter && (
								<Grid item xs={12} md={4}>
									<Typography variant="h5" component="h2" mb={1}>
										Aspectos:
									</Typography>
									<Stack spacing={2}>
										{criteria.map((aspect) => {
											const { id, description } = aspect;

											return (
												<Button
													variant="outlined"
													key={id}
													onClick={() => handleClickCriteria(description)}
													disabled={disableForFeaturedWriter}
												>
													{description}
												</Button>
											);
										})}
									</Stack>
								</Grid>
							)}
							<Grid item xs={12} md={8}>
								<Typography variant="h5" component="h2" mb={1}>
									Detalle:
								</Typography>
								<TextField
									fullWidth
									multiline
									minRows={10}
									maxRows={20}
									id="comment"
									name="comment"
									label="Comentario"
									value={formik.values.comment}
									onChange={formik.handleChange}
									error={formik.touched.comment && Boolean(formik.errors.comment)}
									helperText={formik.touched.comment && formik.errors.comment}
									disabled={isWriter || disableForFeaturedWriter}
								/>
							</Grid>
						</Grid>
						<Divider sx={{ my: 1 }} />
						<Grid container spacing={2}>
							<Grid item xs={12} md={4}>
								<strong>Calificación del Redactor:</strong>{' '}
								{Tools.toFixed2(assignment?.writerQualification)}
								{!!assignment.isFeatured && <small>{` (Redactor Destacado)`}</small>}
							</Grid>
							{!!assignment?.editorQualification && (isAdmin || isAssistant) && (
								<Grid item xs={12} md={8}>
									<strong>Calificación del Editor:</strong> {assignment.editorQualification}%
								</Grid>
							)}
						</Grid>
						{assignment.status === assignmentStatus.COMPLETED.value && isAdmin && (
							<>
								<Divider sx={{ my: 1 }} />
								<EditorQualification
									comment={formik.values.comment}
									onClickSave={onUpdateAssignment}
									criteria={criteria}
								/>
							</>
						)}
						<ActionButtons style={{ marginTop: 16 }}>
							<Button onClick={onCancel}>Cerrar</Button>
							{!isWriter && !disableForFeaturedWriter && (
								<Button type="submit" variant="contained">
									Guardar
								</Button>
							)}
						</ActionButtons>
					</form>
				)}
			</DialogContent>
			<Spinner text="Procesando" isOpen={isProcessing} />
		</Dialog>
	);
};

export default QualificationSystem;
