import { useState } from 'react';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import dateType from 'common/constants/dateType';

const datesRange = [dayjs().startOf('month').toDate(), dayjs().toDate()];

export const AccountingFilters = ({ onSubmit, defaultFilters = null, hideOrderBy }) => {
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = ({ dates, orderBy, dateType }) => {
		const [from, to] = dates;

		if (from && to) {
			onSubmit({
				dateType,
				from: dayjs(from).format('YYYY-MM-DD'),
				orderBy,
				to: dayjs(to).format('YYYY-MM-DD'),
			});
		} else {
			enqueueSnackbar('Seleccione un rango de fechas');
		}
	};

	const [, setDates] = useState(datesRange);
	const formik = useFormik({
		onSubmit: handleSubmit,
		initialValues: {
			dates: defaultFilters
				? [dayjs(defaultFilters.from).toDate(), dayjs(defaultFilters.to).toDate()]
				: datesRange,
			dateType: dateType.END.value,
			orderBy: 'name',
		},
	});

	return (
		<div className="AccountingFilters">
			<form onSubmit={formik.handleSubmit} className="AccountingFilters-form">
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
					<DateRangePicker
						disableFuture
						labelId="dates-label"
						name="dates"
						value={formik.values.dates}
						onChange={(values) => {
							formik.values.dates = values;
							setDates(values);
						}}
						error={formik.touched.dates && Boolean(formik.errors.dates)}
						renderInput={(startProps, endProps) => (
							<Stack spacing={1} direction="row" sx={{ width: 250 }}>
								<TextField {...startProps} label="Desde" sx={{ flex: 1 }} />
								<TextField {...endProps} label="Hasta" sx={{ flex: 1 }} />
							</Stack>
						)}
					/>
				</LocalizationProvider>
				<FormControl style={{ width: 150 }} size="small">
					<InputLabel id="dateType-select-label">Tipo de fecha</InputLabel>
					<Select
						labelId="dateType-label"
						id="dateType"
						label="Tipo de fecha"
						name="dateType"
						size="small"
						onChange={formik.handleChange}
						value={formik.values.dateType}
					>
						<MenuItem value={dateType.START.value}>{dateType.START.label}</MenuItem>
						<MenuItem value={dateType.END.value}>{dateType.END.label}</MenuItem>
					</Select>
				</FormControl>
				{!hideOrderBy && (
					<FormControl style={{ width: 150 }} size="small">
						<InputLabel id="orderBy-select-label">Ordenar por</InputLabel>
						<Select
							labelId="orderBy-label"
							id="orderBy"
							label="Ordenar por"
							name="orderBy"
							size="small"
							onChange={formik.handleChange}
							value={formik.values.orderBy}
						>
							<MenuItem value="name">Cliente</MenuItem>
							<MenuItem value="idCont">ID Cont.</MenuItem>
							<MenuItem value="initialDebt">Deuda Inicial</MenuItem>
							<MenuItem value="payment">Abonos</MenuItem>
							<MenuItem value="clientDebt">Adeudos</MenuItem>
							<MenuItem value="finalDebt">Deuda Final</MenuItem>
						</Select>
					</FormControl>
				)}
				<div className="AccountingFilters-form-actions">
					<Button variant="outlined" color="primary" type="submit">
						Aplicar
					</Button>
				</div>
			</form>
		</div>
	);
};
