import { CheckBoxOutlineBlank, CheckBoxRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const CustomCheckButton = ({ isChecked, ...restProps }) => {
	return (
		<IconButton {...restProps}>
			{isChecked ? <CheckBoxRounded /> : <CheckBoxOutlineBlank />}
		</IconButton>
	);
};

export default CustomCheckButton;
