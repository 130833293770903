import { Alert } from '@mui/material';

const NoData = ({ message = 'No hay datos' }) => {
	return (
		<div className="NoData" style={{ padding: 8 }}>
			<Alert severity="info" variant="outlined">
				{message}
			</Alert>
		</div>
	);
};

export default NoData;
