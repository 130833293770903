import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';
import { UserNotificationService } from 'services';
import Tools from 'utils/Tools';

import UserNotificationModal from './UserNotificationModal';

const UserNotificationButton = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [notification, setNotification] = useState(null);
	const { callService, isProcessing } = useService();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			UserNotificationService.getMyNotification()
				.then(({ data }) => {
					setNotification(data);
				})
				.catch(console.log)
				.finally(() => setIsLoading(false));
		}
	}, [isLoading]);

	const handleShowNotification = () => setShowModal(true);
	const handleCloseNotification = () => setShowModal(false);

	const handleSendNotification = (values) => {
		callService(UserNotificationService.sendNotification(values))
			.then(({ data }) => {
				enqueueSnackbar(data.message, { variant: 'success' });
				handleCloseNotification();
				setIsLoading(true);
			})
			.catch((error) =>
				enqueueSnackbar(Tools.getErrorMessage(error) || 'Error', { variant: 'error' })
			);
	};

	const handleResolve = () => {
		callService(UserNotificationService.closeBySender(notification.id))
			.then(({ data }) => {
				enqueueSnackbar(data.message, { variant: 'success' });
			})
			.catch((error) =>
				enqueueSnackbar(Tools.getErrorMessage(error) || 'Error', { variant: 'error' })
			);
	};

	return (
		<div className="UserNotificationButton">
			<Button variant="outlined" disabled={isLoading} onClick={handleShowNotification}>
				{notification ? 'Ver notificación' : 'Notificar'}
			</Button>
			<UserNotificationModal
				notification={notification}
				onClose={handleCloseNotification}
				open={showModal}
				onResolve={handleResolve}
				isProcessing={isProcessing}
				onSentNotification={handleSendNotification}
			/>
		</div>
	);
};

export default UserNotificationButton;
