import { Chip } from '@mui/material';
import React from 'react';

const StatusChip = ({ isEnabled, size = 'small' }) => {
	return (
		<Chip
			size={size}
			label={isEnabled ? 'Activo' : 'Inactivo'}
			color={isEnabled ? 'success' : 'error'}
			sx={{ width: '100px', maxWidth: '100%' }}
		/>
	);
};

export default StatusChip;
