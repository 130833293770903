import { Cancel, CheckCircle } from '@mui/icons-material';
import {
	Button,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Tools from 'utils/Tools';
import { findEachCriteria } from './findCriteria';

export const EditorQualification = ({ comment, onClickSave, criteria }) => {
	const [checked, setChecked] = useState([]);
	const [nulled, setNulled] = useState([]);
	const [foundCriteria, setFoundCriteria] = useState([]);

	useEffect(() => {
		setFoundCriteria(findEachCriteria(comment));
	}, [comment]);

	const editorQualification = useMemo(() => {
		if (!checked.length && !nulled.length) return 100;

		const totalChecked = criteria.reduce((accumulator, { description, percentage }) => {
			if (!!checked.find((item) => item.includes(description))) {
				return accumulator + percentage;
			}

			return accumulator;
		}, 0);

		const totalNulled = criteria.reduce((accumulator, { description, percentage }) => {
			if (!!nulled.find((item) => item.includes(description))) {
				return accumulator + percentage;
			}

			return accumulator;
		}, 0);

		return (totalChecked / (totalChecked + totalNulled)) * 100;
	}, [checked, nulled, criteria]);

	const handleClickCheck = (text) => {
		if (!checked.includes(text)) setChecked([...checked, text]);
		if (nulled.includes(text)) setNulled(nulled.filter((nulledItem) => nulledItem !== text));
	};

	const handleClickNull = (text) => {
		if (!nulled.includes(text)) setNulled([...nulled, text]);
		if (checked.includes(text)) setChecked(checked.filter((checkedItem) => checkedItem !== text));
	};

	const handleSaveQualification = () => {
		onClickSave({ editorQualification });
	};

	return (
		<div className="EditorQualification">
			<Typography style={{ fontWeight: 700 }}>Calificar corrector:</Typography>
			<List dense>
				{foundCriteria.map((text, index) => {
					const checkedColor = checked.includes(text) ? 'success' : 'default';
					const nulledColor = nulled.includes(text) ? 'error' : 'default';

					return (
						<div key={`item-${index}`}>
							<ListItem
								secondaryAction={
									<Stack direction="row" spacing={3}>
										<IconButton
											edge="end"
											aria-label="check"
											color={checkedColor}
											onClick={() => handleClickCheck(text)}
										>
											<CheckCircle />
										</IconButton>
										<IconButton
											edge="end"
											aria-label="null"
											color={nulledColor}
											onClick={() => handleClickNull(text)}
										>
											<Cancel />
										</IconButton>
									</Stack>
								}
							>
								<ListItemText primary={text} />
							</ListItem>
							<Divider />
						</div>
					);
				})}
			</List>
			<Typography style={{ fontWeight: 700 }}>
				Calificación calculada: {Tools.toFixed2(editorQualification)}% (debe guardar)
			</Typography>
			<Button variant="outlined" onClick={handleSaveQualification}>
				Guardar Calificación del Editor
			</Button>
		</div>
	);
};
