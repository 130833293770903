import { useState } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';

import Tools from 'utils/Tools';
import { ActionButtons, LabeledSlider } from 'components';

const WriterClientPreferencesForm = ({ rates, initialRates = {}, onSubmit, isProcessing }) => {
	const [touched, setTouched] = useState(false);

	const formik = useFormik({
		initialValues: {
			rates: initialRates,
		},
		onSubmit: (values, form) => {
			if (touched) setTouched(false);
			onSubmit(values, form);
		},
	});

	const handleChange = () => {
		if (!touched) setTouched(true);
	};

	return (
		<div className="WriterClientPreferencesForm">
			<Typography component="h3" variant="h6" mb={2}>
				Ponderación de clientes:
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<Grid container rowSpacing={2} columnSpacing={5}>
					{rates.map((rate) => (
						<Grid key={rate.clientId} item xs={12} sm={4} md={3}>
							<LabeledSlider
								name={rate.clientId}
								label={Tools.getFullName(rate)}
								key={rate.clientId}
								initialValue={rate.rate}
								formik={formik}
								onChange={handleChange}
							/>
						</Grid>
					))}
				</Grid>
				<ActionButtons style={{ marginTop: 16 }}>
					<Button type="submit" variant="outlined" size="small" disabled={!touched}>
						Actualizar
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default WriterClientPreferencesForm;
