import { Stack } from '@mui/material';

const ActionButtons = ({ children, ...resProps }) => {
	return (
		<Stack
			className="ActionButtons"
			direction="row"
			spacing={1}
			justifyContent="flex-end"
			alignItems="center"
			{...resProps}
		>
			{children}
		</Stack>
	);
};

export default ActionButtons;
