import Http from './Http';

const endpoint = 'post-type';

const postType = {
	getPostTypes() {
		return Http.get(endpoint);
	},

	createPostType(data) {
		return Http.post(endpoint, data);
	},

	updatePostType(id, data) {
		return Http.put(`${endpoint}/${id}`, data);
	},
};

export default postType;
