import { useState, useEffect } from 'react';

import AuthService from 'services/AuthService';
import useLoggedUser from './useLoggedUser';
import Tools from 'utils/Tools';

function useVerifyToken() {
	const [isLoading, setIsLoading] = useState(true);
	const { updateLoggedUser } = useLoggedUser();

	useEffect(() => {
		const tokenExists = localStorage.getItem('token');
		if (tokenExists) {
			AuthService.check()
				.then(({ data: { user } }) => {
					if (user) updateLoggedUser(user);
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
					const errorMessage = Tools.getErrorMessage(error);
					if (errorMessage) alert(errorMessage);
				});
		} else {
			setIsLoading(false);
		}
	}, []); // eslint-disable-line

	return { isLoading };
}

export default useVerifyToken;
