import { Typography } from '@mui/material';

import Logo from 'common/images/logo-guest.png';

import './GuestLayout.scss';

const GuestLayout = ({ title, description, extra = [], children }) => {
	return (
		<div className="GuestLayout">
			<div className="GuestLayout-container">
				<div className="GuestLayout-container-logo">
					<img src={Logo} alt="Logo de Redactores Alfa" />
				</div>
				<div className="GuestLayout-container-title">
					<Typography variant="h3" component="h1">
						{title}
					</Typography>
				</div>
				<div className="GuestLayout-container-description">{description}</div>
				<div className="GuestLayout-container-content">{children}</div>
				<div className="GuestLayout-container-extra">
					{extra.map((item, index) => (
						<div key={`item-${index}`} className="GuestLayout-container-extra-item">
							{item}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default GuestLayout;
