import Http from './Http';

const endpoint = 'task-types';

const taskType = {
	getTaskTypes() {
		return Http.get(endpoint);
	},

	createTaskType(data) {
		return Http.post(endpoint, data);
	},

	updateTaskType(id, data) {
		return Http.put(`${endpoint}/${id}`, data);
	},

	deleteTaskType(id) {
		return Http.delete(`${endpoint}/${id}`);
	},
};

export default taskType;
