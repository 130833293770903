import Http from './Http';

const endpoint = 'post-type-rates';

const rates = {
	getPostTypeRates(postTypeId) {
		return Http.get(`${endpoint}/${postTypeId}`);
	},
};

export default rates;
