import { useEffect, useState } from 'react';

import { useLoggedUser } from 'hooks';
import Tools from 'utils/Tools';
import roles from 'common/constants/roles';
import { ProjectService, UserService } from 'services';

import AssignmentFilterForm from './Form';
import './AssignmentFilters.scss';

const { getProjects } = ProjectService;
const { getClients, getWriters, getEditors } = UserService;

const AssignmentFilters = ({ onSearch }) => {
	const [writers, setWriters] = useState([]);
	const [clients, setClients] = useState([]);
	const [projects, setProjects] = useState([]);
	const [editors, setEditors] = useState([]);
	const { loggedUser } = useLoggedUser();

	const isAdminOrAssistant = [roles.ADMIN.value, roles.ASSISTANT.value].includes(loggedUser.role);

	useEffect(() => {
		const writersQuery = Tools.objectToQueryString({ orderBy: 'name', order: 'ASC' });

		if (isAdminOrAssistant)
			Promise.all([getClients(), getProjects(), getWriters(writersQuery), getEditors(writersQuery)])
				.then(
					([
						{ data: clientsData },
						{ data: projectsData },
						{ data: writersData },
						{ data: editorsData },
					]) => {
						setClients(clientsData);
						setProjects(projectsData);
						setWriters(writersData);
						setEditors(editorsData);
					}
				)
				.catch((error) => {
					console.log(`error`, error);
				});
		else
			Promise.all([getClients(), getProjects()])
				.then(([{ data: clientsData }, { data: projectsData }]) => {
					setClients(clientsData);
					setProjects(projectsData);
				})
				.catch((error) => {
					console.log(`error`, error);
				});
	}, [isAdminOrAssistant]);

	const handleSubmit = (values) => {
		onSearch(values);
	};

	return (
		<div className="AssignmentFilters">
			<AssignmentFilterForm
				clients={clients}
				editors={editors}
				onSubmit={handleSubmit}
				projects={projects}
				writers={writers}
			/>
		</div>
	);
};

export default AssignmentFilters;
