import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
	TableContainer,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
} from '@mui/material';

import { AssignmentService } from 'services';
import Tools from 'utils/Tools';

import { AssignmentFilters, Layout, NoData, PageTitle, Paginator, Spinner } from 'components';

const { getMyAssignments } = AssignmentService;
const { getDate, getErrorMessage, getStatusLabel, getCategoriesNames } = Tools;

const limit = 15;

const Assignments = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [filters, setFilters] = useState({});
	const [assignments, setAssignments] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			const queryString = Tools.objectToQueryString(filters);
			getMyAssignments(queryString)
				.then(({ data }) => {
					setAssignments(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [isLoading, enqueueSnackbar, filters]);

	const handleSearch = (filters) => {
		setFilters(filters);
		setIsLoading(true);
	};

	const handleChangePage = (page) => {
		setFilters({ ...filters, offset: page === 1 ? null : `${(page - 1) * limit}` });
		setIsLoading(true);
	};

	const pages = isLoading ? 0 : Math.floor((assignments.total + limit - 1) / limit);

	return (
		<Layout>
			<PageTitle title="Mis asignaciones" />
			<AssignmentFilters onSearch={handleSearch} />
			{isLoading ? (
				<Spinner text="Cargando asignaciones" isOpen small />
			) : (
				<div>
					<Typography>Total: {assignments.total}</Typography>
					<TableContainer component={Box}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell size="medium">Estatus</TableCell>
									<TableCell>Fecha de entrega</TableCell>
									<TableCell>Título</TableCell>
									<TableCell>Cliente</TableCell>
									<TableCell>Etiquetas</TableCell>
									<TableCell>Calificación</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{assignments.data.map((assignment) => (
									<TableRow key={assignment.id}>
										<TableCell size="medium">{getStatusLabel(assignment.status)}</TableCell>
										<TableCell>
											{getDate({ date: assignment.deliveryDate, format: 'short' })}
										</TableCell>
										<TableCell>
											<Link target="_blank" to={`/mis-asignaciones/${assignment.id}`}>
												{assignment.title}
											</Link>
										</TableCell>
										<TableCell>{assignment.clientName}</TableCell>
										<TableCell>{getCategoriesNames(assignment.categories)}</TableCell>
										<TableCell align="right">
											{assignment.isRated ? assignment.writerQualification : 'N/A'}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
			{!assignments?.data?.length && !isLoading && <NoData message="No hay asignaciones" />}
			{!!assignments?.data?.length && <Paginator pages={pages} onChange={handleChangePage} />}
		</Layout>
	);
};

export default Assignments;
