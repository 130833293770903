import NumericInput from 'material-ui-numeric-input';
import { useFormik } from 'formik';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';

import { ActionButtons, CustomDatePicker, CustomDialogTitle, Spinner } from 'components';
import validationSchemas from 'utils/validationSchemas';
import { useSnackbar } from 'notistack';
import { useService } from 'hooks';
import { AccountingService } from 'services';
import Tools from 'utils/Tools';

const { addClientPayment, editClientPayment } = AccountingService;

export const AddPaymentModal = ({ onCancel, clientId, onSuccess, payment = null }) => {
	const { callService, isProcessing } = useService();
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = (values, { setFieldError }) => {
		if (!payment && !values.payment) {
			enqueueSnackbar('Indique un monto válido', { variant: 'error' });
			return 0;
		}

		const service = payment ? editClientPayment : addClientPayment;
		const id = payment ? payment.id : clientId;

		callService(service(id, values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType || 'success' });
				onSuccess();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	const formik = useFormik({
		onSubmit: handleSubmit,
		initialValues: {
			payment: payment?.amount || 0,
			paidAt: payment ? new Date(payment.date) : new Date(),
		},
		validationSchema: validationSchemas.clientPayment,
	});

	return (
		<Dialog open>
			<CustomDialogTitle>{payment ? 'Editar' : 'Añadir'} pago</CustomDialogTitle>
			<Spinner text="Añadiendo pago..." isOpen={isProcessing} />
			<DialogContent sx={{ pt: 0 }}>
				{payment && (
					<Typography color="red">(Para borrar el pago, ponga cero (0) en el Monto)</Typography>
				)}
				<form onSubmit={formik.handleSubmit}>
					<NumericInput
						name="payment"
						precision={2}
						decimalChar=","
						thousandChar="."
						label="Monto"
						onChange={(e) => (formik.values.payment = e.target.value)}
						variant="outlined"
						value={formik.values.payment}
						defaultValue={Number(formik.values.payment).toLocaleString('es')}
						helperText={formik.touched.payment && formik.errors.payment}
						error={formik.touched.payment && Boolean(formik.errors.payment)}
						margin="normal"
						fullWidth
					/>
					<CustomDatePicker
						name="paidAt"
						initialValue={formik.values.paidAt}
						formik={formik}
						label="Fecha de pago"
						style={{ width: '100%' }}
						error={formik.touched.paidAt && Boolean(formik.errors.paidAt)}
						helperText={formik.touched.paidAt && formik.errors.paidAt}
						margin="normal"
					/>
					<ActionButtons style={{ marginTop: 16 }}>
						<Button onClick={onCancel}>Cancelar</Button>
						<Button variant="contained" type="submit">
							{payment ? 'Editar pago' : 'Añadir pago'}
						</Button>
					</ActionButtons>
				</form>
			</DialogContent>
		</Dialog>
	);
};
