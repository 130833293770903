import { FunctionsOutlined, Person, StarHalfOutlined, StarOutlined } from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import Tools from 'utils/Tools';

export const RankingStatistics = ({ isLoading, ranking = [] }) => {
	if (isLoading) return <Typography>Cargando estadísticas...</Typography>;

	if (!ranking.length) return null;

	const totalWriters = ranking?.length ?? 0;
	const totalFeatured = ranking?.filter(({ isFeatured }) => !!isFeatured).length;
	const words = ranking.reduce(
		(accumulator, { totalWords }) => accumulator + Number(totalWords),
		0
	);
	const avg =
		ranking.reduce(
			(accumulator, { average, totalWords }) => accumulator + average * totalWords,
			0
		) / words;

	return (
		<Box className="RankingStatistics" my={2}>
			<Stack direction="row" spacing={2}>
				<Chip
					color="primary"
					icon={<Person />}
					label={
						<Typography>
							Redactores: <strong>{totalWriters}</strong>
						</Typography>
					}
				/>
				<Chip
					color="warning"
					icon={<StarOutlined />}
					label={
						<Typography>
							Destacados: <strong>{totalFeatured}</strong>
						</Typography>
					}
				/>
				<Chip
					color="info"
					icon={<FunctionsOutlined />}
					label={
						<Typography>
							Palabras totales: <strong>{words}</strong>
						</Typography>
					}
				/>
				<Chip
					color="success"
					icon={<StarHalfOutlined />}
					label={
						<Typography>
							Promedio total: <strong>{Tools.toFixed2(avg)}</strong>
						</Typography>
					}
				/>
			</Stack>
		</Box>
	);
};
