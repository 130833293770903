import { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from '@mui/material';

import { AssignmentService } from 'services';
import CustomDialogTitle from 'components/CustomDialogTitle';
import LabeledValue from 'components/LabeledValue';
import Spinner from 'components/Spinner';
import { FileDownloadOutlined } from '@mui/icons-material';
import Tools from 'utils/Tools';
import roles from 'common/constants/roles';
import CanI from 'components/CanI';

const AssignmentFiles = ({ assignment, onClose }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [files, setFiles] = useState([]);
	const { id, title } = assignment;

	useEffect(() => {
		if (isLoading) {
			AssignmentService.getAssignmentFiles(id)
				.then(({ data }) => {
					setFiles(data);
				})
				.catch(console.log)
				.finally(() => setIsLoading(false));
		}
	}, [id, isLoading]);

	return (
		<Dialog maxWidth="xs">
			<CustomDialogTitle>Descarga de archivos</CustomDialogTitle>
			<DialogContent>
				<LabeledValue label="Asignación:" value={title} />
				{isLoading && <Spinner text="Cargando archivos" small isOpen />}
				{!isLoading && (
					<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
						{files.map((file) => {
							const prefix = file.role === roles.WRITER.value ? 'Actualizado por' : 'Revisión del';
							const role = roles[file.role].label;

							return (
								<ListItem alignItems="flex-start">
									<ListItemAvatar>
										<CanI make="ASSIGNMENT_DOWNLOAD">
											<a href={file.url}>
												<IconButton sx={{ backgroundColor: 'lightblue' }}>
													<FileDownloadOutlined />
												</IconButton>
											</a>
										</CanI>
									</ListItemAvatar>
									<ListItemText
										primary={`${prefix} ${role}`}
										secondary={
											<>
												<Typography
													sx={{ display: 'block' }}
													component="span"
													variant="body2"
													color="text.primary"
												>
													{Tools.getShortName(file)}
												</Typography>
												{Tools.getDate({ date: file.createdAt, format: 'dateTime' })}
											</>
										}
									/>
								</ListItem>
							);
						})}
					</List>
				)}
				<DialogActions>
					<Button onClick={onClose}>Listo</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};

export default AssignmentFiles;
