const dateType = {
	START: {
		label: 'Fecha de Inicio',
		value: 'startDate',
	},
	END: {
		label: 'Fecha de Fin',
		value: 'endDate',
	},
};

export default dateType;
