import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProjectService } from 'services';

const { getProjects } = ProjectService;

const ProjectsSelect = ({ formik }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [projects, setProject] = useState(null);

	useEffect(() => {
		if (isLoading) {
			getProjects()
				.then(({ data }) => {
					setProject(data);
					setIsLoading(false);
				})
				.catch((error) => {
					console.log(`error`, error);
					setIsLoading(false);
				});
		}
	}, [isLoading]);

	console.log('projects', projects);

	if (isLoading) return null;

	return (
		<FormControl fullWidth>
			<InputLabel id="project-label">Proyecto</InputLabel>
			<Select
				labelId="project-label"
				id="project"
				label="Proyecto"
				name="projectId"
				onChange={formik.handleChange}
				value={formik.values.projectId}
				error={formik.touched.projectId && Boolean(formik.errors.projectId)}
			>
				<MenuItem value="all">Todos</MenuItem>
				{Object.values(projects).map(({ name, id }) => (
					<MenuItem value={id} key={id}>
						{name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default ProjectsSelect;
