import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';

import { PostTypeService, UserService, PostTypeRatesService } from 'services';
import Tools from 'utils/Tools';

import { CustomDialogTitle, Spinner } from 'components';
import PostTypeForm from './PostTypeForm';

import './PostTypeDialog.scss';

const { updatePostType, createPostType } = PostTypeService;
const { getPostTypeRates } = PostTypeRatesService;
const { getWriters } = UserService;

const PostTypeDialog = ({ postType, onCancel, onSuccess }) => {
	const { isProcessing, callService } = useService();
	const [initialRates, setInitialRates] = useState({});
	const [postTypeRates, setPostTypeRates] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			if (postType)
				getPostTypeRates(postType.id)
					.then(({ data }) => {
						let rates = {};

						data.map(({ userId, rate }) => {
							rates[userId] = rate;
							return true;
						});

						setPostTypeRates(data);
						setInitialRates(rates);
						setIsLoading(false);
					})
					.catch((error) => {
						setIsLoading(false);
						enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
						setIsLoading(false);
					});
			else
				getWriters('orderBy=name')
					.then(({ data }) => {
						const rates = {};

						data.map(({ id }) => {
							rates[id] = 0.5;
							return true;
						});

						const realRates = data.map(({ id, name, lastName }) => ({
							name,
							lastName,
							userId: id,
							rate: 0.5,
						}));

						setPostTypeRates(realRates);
						setInitialRates(rates);
						setIsLoading(false);
					})
					.catch((error) => {
						setIsLoading(false);
						enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
						setIsLoading(false);
					});
		}
	}, [isLoading, enqueueSnackbar, postType]);

	const handleSubmit = (values, { setFieldError }) => {
		callService(postType ? updatePostType(postType.id, values) : createPostType(values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onCancel();
				onSuccess();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	return (
		<Dialog className="PostTypeDialog">
			<CustomDialogTitle>{postType ? 'Editar' : 'Nuevo'} tipo de publicación</CustomDialogTitle>
			<DialogContent>
				{isLoading ? (
					<Spinner text={`Cargando ${postType ? 'calificaciones' : 'redactores'}`} small />
				) : (
					<PostTypeForm
						onCancel={onCancel}
						onSubmit={handleSubmit}
						postType={postType}
						rates={postTypeRates}
						initialRates={initialRates}
					/>
				)}
			</DialogContent>
			<Spinner text={`${!postType ? 'Agregando' : 'Guardando'} etiqueta`} isOpen={isProcessing} />
		</Dialog>
	);
};

export default PostTypeDialog;
