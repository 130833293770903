import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { AccountingService } from 'services';
import { AddButton, CanI, Layout, PageTitle, Spinner } from 'components';
import { useLocation, useParams } from 'react-router-dom';
import { AccountingFilters } from '../ClientsAccounting/AccountingFilters';
import {
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Tools from 'utils/Tools';
import { AddPaymentModal } from './AddPaymentModal';
import { EditOutlined } from '@mui/icons-material';
import dateType from 'common/constants/dateType';

const { toFixed2, getDate } = Tools;

const defaultFilters = {
	from: dayjs().startOf('month').format('YYYY-MM-DD'),
	to: dayjs().format('YYYY-MM-DD'),
	dateType: dateType.END.value,
};

const getDefaultFilters = ({ from, to, dateType }) => {
	if (from && to) return { from, to, dateType };

	return defaultFilters;
};

const { getClientAccounting } = AccountingService;

function useQuery() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}

const ClientAccounting = () => {
	const [selectedEditPayment, setSelectedEditPayment] = useState(null);
	const [showPaymentDialog, setShowPaymentDialog] = useState(false);
	const { clientId } = useParams();
	const query = useQuery();
	const [accounting, setAccounting] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [filters, setFilters] = useState(() =>
		getDefaultFilters({
			from: query.get('from'),
			to: query.get('to'),
			dateType: query.get('dateType'),
		})
	);

	useEffect(() => {
		if (isLoading) {
			getClientAccounting(clientId, filters)
				.then(({ data }) => {
					setAccounting(data);
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
				});
		}
	}, [isLoading, filters, clientId]);

	const handleSearch = (values) => {
		setFilters(values);
		setIsLoading(true);
	};

	const handleShowPaymentDialog = () => setShowPaymentDialog(true);
	const handleClosePaymentModal = () => {
		setShowPaymentDialog(false);
		if (selectedEditPayment) setSelectedEditPayment(null);
	};
	const handleAddPayment = () => {
		handleClosePaymentModal();
		setIsLoading(true);
	};

	const handleClickEditPayment = (payment) => {
		console.log('payment', payment);
		setSelectedEditPayment(payment);
		handleShowPaymentDialog();
	};

	const toDollarValue = (value) => `$${toFixed2(value)}`;

	const { accounting: records, initialDebt = 0 } = accounting || {};

	let finalDebt = initialDebt;
	const totalDebts = (records || []).reduce(
		(accumulator, { amount, type }) => (type === 'project' ? accumulator + amount : accumulator),
		0
	);
	const totalPayments = (records || []).reduce(
		(accumulator, { amount, type }) => (type === 'payment' ? accumulator + amount : accumulator),
		0
	);

	return (
		<Layout>
			<PageTitle
				title={`Contabilidad: ${accounting?.name} ${accounting?.lastName}`}
				loadingText={isLoading ? 'Cargando contabilidad...' : null}
				actionButtons={[
					<CanI make="ACCOUNTING_ADD">
						<AddButton onClick={handleShowPaymentDialog} text="Añadir pago" />
					</CanI>,
				]}
			/>
			<AccountingFilters onSubmit={handleSearch} defaultFilters={filters} hideOrderBy />
			{isLoading && <Spinner text="Cargando registros" isOpen small />}
			{!isLoading && (
				<div className="ClientAccounting">
					<TableContainer component={Box}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="center">Fecha</TableCell>
									<TableCell align="center">Adeudo</TableCell>
									<TableCell align="center">Abonos</TableCell>
									<TableCell align="center">Deuda Final</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{records.map((record) => {
									const { id, type, date, amount } = record;

									const emptyCell = <TableCell align="right">-</TableCell>;
									const amountCell = (
										<TableCell align="right">
											{type === 'payment' && (
												<IconButton sx={{ mr: 1 }} onClick={() => handleClickEditPayment(record)}>
													<EditOutlined />
												</IconButton>
											)}
											{toDollarValue(amount)}
										</TableCell>
									);

									finalDebt = type === 'project' ? finalDebt + amount : finalDebt - amount;

									return (
										<TableRow key={id}>
											<TableCell sx={{ py: 1 }} align="center">
												{getDate({ date, format: 'short' })}
											</TableCell>
											{type === 'payment' && (
												<>
													{emptyCell}
													{amountCell}
												</>
											)}
											{type === 'project' && (
												<>
													{amountCell}
													{emptyCell}
												</>
											)}
											<TableCell align="right">{toDollarValue(finalDebt)}</TableCell>
										</TableRow>
									);
								})}

								<TableRow sx={{ borderTop: '2px solid #dcdcdc' }}>
									<TableCell sx={{ borderRight: '1px solid #dcdcdc', fontWeight: 500 }}>
										Deuda Inicial
									</TableCell>
									<TableCell sx={{ borderRight: '1px solid #dcdcdc', fontWeight: 500 }}>
										Suma Adeudos
									</TableCell>
									<TableCell sx={{ borderRight: '1px solid #dcdcdc', fontWeight: 500 }}>
										Suma Abonos
									</TableCell>
									<TableCell sx={{ fontWeight: 500 }}>Deuda Final</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ fontWeight: 500 }} align="right">
										{toDollarValue(initialDebt)}
									</TableCell>
									<TableCell sx={{ fontWeight: 500 }} align="right">
										{toDollarValue(totalDebts)}
									</TableCell>
									<TableCell sx={{ fontWeight: 500 }} align="right">
										{toDollarValue(totalPayments)}
									</TableCell>
									<TableCell sx={{ fontWeight: 500 }} align="right">
										{toDollarValue(finalDebt)}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
			{showPaymentDialog && (
				<AddPaymentModal
					clientId={clientId}
					onCancel={handleClosePaymentModal}
					onSuccess={handleAddPayment}
					payment={selectedEditPayment}
				/>
			)}
		</Layout>
	);
};

export default ClientAccounting;
