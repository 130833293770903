import { Dialog, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';

import { PaymentMethodService } from 'services';
import Tools from 'utils/Tools';

import { CustomDialogTitle, Spinner } from 'components';
import PaymentMethodForm from './PaymentMethodForm';

import './PaymentMethodDialog.scss';

const { updatePaymentMethod, createPaymentMethod } = PaymentMethodService;

const PaymentMethodDialog = ({ paymentMethod, onCancel, onSuccess }) => {
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = (values, { setFieldError }) => {
		callService(
			paymentMethod ? updatePaymentMethod(paymentMethod.id, values) : createPaymentMethod(values)
		)
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onCancel();
				onSuccess();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	return (
		<Dialog className="PaymentMethodDialog">
			<CustomDialogTitle>
				{paymentMethod ? 'Editar' : 'Nuevo'} tipo de publicación
			</CustomDialogTitle>
			<DialogContent>
				<PaymentMethodForm
					onCancel={onCancel}
					onSubmit={handleSubmit}
					paymentMethod={paymentMethod}
				/>
			</DialogContent>
			<Spinner
				text={`${!paymentMethod ? 'Agregando' : 'Guardando'} método de pago`}
				isOpen={isProcessing}
			/>
		</Dialog>
	);
};

export default PaymentMethodDialog;
