import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material';
import Tools from 'utils/Tools';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { ClientSelect } from 'components';

const getFromToDates = (dates) => ({
	from: dates[0] ? dayjs(dates[0]).set('hour', 0).format('YYYY-MM-DD HH:mm:ss') : null,
	to: dates[1]
		? dayjs(dates[1])
				.set('hour', 23)
				.set('minute', 59)
				.set('second', 59)
				.format('YYYY-MM-DD HH:mm:ss')
		: null,
});

const ProjectFilters = ({ onSearch }) => {
	const [, setPaidAtRange] = useState([null, null]);
	const [, setEndDateRange] = useState([null, null]);
	const { enqueueSnackbar } = useSnackbar();
	const [hasFilters, setHasFilters] = useState(false);

	const handleSubmit = (values) => {
		const filters = { ...values };
		const { paidAt, endDate } = filters;
		if (filters.isEnabled === 'all') filters.isEnabled = null;

		const { from: paidAtFrom, to: paidAtTo } = getFromToDates(paidAt);
		const { from: endDateFrom, to: endDateTo } = getFromToDates(endDate);

		delete filters.paidAt;
		delete filters.endDate;

		const queryObject = { ...filters, paidAtFrom, paidAtTo, endDateFrom, endDateTo };

		const query = Tools.objectToQueryString(queryObject);

		if (query) {
			setHasFilters(true);
			onSearch(query);
		} else {
			enqueueSnackbar('No hay filtros seleccionados');
		}

		setHasFilters(true);
	};

	const formik = useFormik({
		initialValues: {
			clientId: '',
			endDate: [null, null],
			isEnabled: 'true',
			order: 'ASC',
			orderBy: 'clientName',
			paidAt: [null, null],
		},
		onSubmit: handleSubmit,
	});

	const deleteFilters = () => {
		setHasFilters(false);
		formik.resetForm();
		formik.values.paidAt = [null, null];
		formik.values.endDate = [null, null];
		const values = { ...formik.values };
		delete values.paidAt;
		delete values.endDate;
		onSearch(Tools.objectToQueryString(values));
	};

	return (
		<div className="ProjectFilters">
			<form onSubmit={formik.handleSubmit} className="ProjectFilters-form">
				<ClientSelect formik={formik} />
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
					<DateRangePicker
						disableFuture
						labelId="endDate-label"
						name="endDate"
						value={formik.values.endDate}
						onChange={(values) => {
							setEndDateRange(values);
							formik.values.endDate = values;
						}}
						error={formik.touched.endDate && Boolean(formik.errors.endDate)}
						renderInput={(startProps, endProps) => (
							<Stack spacing={1} direction="row" sx={{ width: 250 }}>
								<TextField {...startProps} label="Finaliza desde" sx={{ flex: 1 }} />
								<TextField {...endProps} label="Finaliza hasta" sx={{ flex: 1 }} />
							</Stack>
						)}
					/>
				</LocalizationProvider>
				<FormControl fullWidth>
					<InputLabel id="orderBy-label">Ordenar por</InputLabel>
					<Select
						labelId="orderBy-label"
						id="orderBy"
						label="Ordenar por"
						name="orderBy"
						onChange={formik.handleChange}
						value={formik.values.orderBy}
					>
						<MenuItem value="startDate">Fecha de inicio</MenuItem>
						<MenuItem value="endDate">Fecha de culminación</MenuItem>
						<MenuItem value="clientName">Cliente</MenuItem>
					</Select>
					<FormHelperText error>{formik.touched.orderBy && formik.errors.orderBy}</FormHelperText>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel id="order-label">Orden</InputLabel>
					<Select
						labelId="order-label"
						id="order"
						label="Orden"
						name="order"
						onChange={formik.handleChange}
						value={formik.values.order}
					>
						<MenuItem value="DESC">Descendente</MenuItem>
						<MenuItem value="ASC">Ascendente</MenuItem>
					</Select>
					<FormHelperText error>{formik.touched.order && formik.errors.order}</FormHelperText>
				</FormControl>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
					<DateRangePicker
						disableFuture
						labelId="range-label"
						name="paidAt"
						value={formik.values.paidAt}
						onChange={(values) => {
							setPaidAtRange(values);
							formik.values.paidAt = values;
						}}
						error={formik.touched.paidAt && Boolean(formik.errors.paidAt)}
						renderInput={(startProps, endProps) => (
							<Stack spacing={1} direction="row" sx={{ width: 250 }}>
								<TextField {...startProps} label="Pago desde" sx={{ flex: 1 }} />
								<TextField {...endProps} label="Hasta" sx={{ flex: 1 }} />
							</Stack>
						)}
					/>
				</LocalizationProvider>
				<FormControl fullWidth>
					<InputLabel id="isEnabled-label">Estatus</InputLabel>
					<Select
						labelId="isEnabled-label"
						id="isEnabled"
						label="Estatus"
						name="isEnabled"
						onChange={formik.handleChange}
						value={formik.values.isEnabled}
					>
						<MenuItem value="all">Todos</MenuItem>
						<MenuItem value="true">Activo</MenuItem>
						<MenuItem value="false">Inactivo</MenuItem>
					</Select>
				</FormControl>
				<div className="RankingFilters-form-actions">
					<Button variant="outlined" color="primary" type="submit">
						Aplicar
					</Button>
					{hasFilters && (
						<Button variant="outlined" color="secondary" onClick={deleteFilters}>
							Quitar
						</Button>
					)}
				</div>
			</form>
		</div>
	);
};

export default ProjectFilters;
