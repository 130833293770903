import Http from './Http';

const endpoint = 'payment-method';

const paymentMethod = {
	getPaymentMethods() {
		return Http.get(endpoint);
	},

	createPaymentMethod(data) {
		return Http.post(endpoint, data);
	},

	updatePaymentMethod(id, data) {
		return Http.put(`${endpoint}/${id}`, data);
	},
};

export default paymentMethod;
