import { Typography } from '@mui/material';

import './TitledBox.scss';

const index = ({ title, children, ...restProps }) => {
	return (
		<div className="TitledBox" {...restProps}>
			<Typography className="TitledBox-title">{title}</Typography>
			<div className="TitledBox-content">{children}</div>
		</div>
	);
};

export default index;
