import { createTheme } from '@mui/material/styles';

const fontSizes = {
	title: 500,
	body: 400,
};

const theme = createTheme({
	palette: {
		primary: {
			main: '#005d90',
		},
		secondary: {
			main: '#ff6504',
			contrastText: 'white',
		},
	},

	typography: {
		fontSize: 14,
		h1: {
			fontSize: 36,
			fontWeight: fontSizes.title,
		},
		h2: {
			fontSize: 30,
			fontWeight: fontSizes.title,
		},
		h3: {
			fontSize: 26,
			fontWeight: fontSizes.title,
		},
		h4: {
			fontSize: 22,
			fontWeight: fontSizes.title,
		},
		h5: {
			fontSize: 18,
			fontWeight: fontSizes.title,
		},
		h6: {
			fontSize: 16,
			fontWeight: fontSizes.title,
		},
	},

	components: {
		MuiTable: {
			defaultProps: {
				size: 'small',
			},
		},

		MuiTableCell: {
			defaultProps: {
				size: 'small',
			},
			styleOverrides: {
				sizeSmall: {
					padding: '2px 8px',
				},
			},
		},

		MuiTextField: {
			defaultProps: {
				size: 'small',
			},
		},

		MuiFormControl: {
			defaultProps: {
				size: 'small',
			},
		},

		MuiSelect: {
			defaultProps: {
				size: 'small',
			},
		},

		MuiDialog: {
			defaultProps: {
				scroll: 'body',
				maxWidth: 'xs',
				open: true,
				disableEscapeKeyDown: true,
			},
		},

		MuiSlider: {
			defaultProps: {
				size: 'small',
			},
		},
	},
});

export default theme;
