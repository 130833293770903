import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AddButton, Layout, NoData, PageTitle, Spinner, StatusChip } from 'components';
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';
import { EditRounded } from '@mui/icons-material';

import Tools from 'utils/Tools';

import { CategoryService } from 'services';
import CategoryDialog from './CategoryDialog';
import CanI from 'components/CanI';

const { getCategories } = CategoryService;

const Categories = () => {
	const [categories, setCategories] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [showAddForm, setShowAddForm] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			getCategories()
				.then(({ data }) => {
					setCategories(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [enqueueSnackbar, isLoading]);

	const handleClose = () => {
		if (selectedCategory) setSelectedCategory(null);
		if (showAddForm) setShowAddForm(false);
	};

	return (
		<Layout>
			<PageTitle
				title="Etiquetas"
				actionButtons={[
					<CanI make="CATEGORY_ADD">
						<AddButton onClick={() => setShowAddForm(true)} />
					</CanI>,
				]}
			/>
			{isLoading && <Spinner text="Cargando etiquetas" isOpen small />}
			{!isLoading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Etiqueta</TableCell>
								<TableCell>Ponderable por redactor</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{categories.map((category) => (
								<TableRow key={category.id}>
									<TableCell>{category.name}</TableCell>
									<TableCell>{category.isWriterEditable ? 'Sí' : 'No'}</TableCell>
									<TableCell>
										<StatusChip isEnabled={category.isEnabled} />
									</TableCell>
									<TableCell>
										<CanI make="CATEGORY_EDIT">
											<IconButton size="small" onClick={() => setSelectedCategory(category)}>
												<EditRounded />
											</IconButton>
										</CanI>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{!categories.length && !isLoading && <NoData message="No hay etiquetas" />}
			{(showAddForm || selectedCategory) && (
				<CategoryDialog
					category={selectedCategory}
					onCancel={handleClose}
					onSuccess={() => setIsLoading(true)}
				/>
			)}
		</Layout>
	);
};

export default Categories;
