import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import {
	TableContainer,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	Typography,
	Badge,
	Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
	AttachmentOutlined,
	EditRounded,
	FileDownloadOutlined,
	StarOutlined,
} from '@mui/icons-material';

import { AssignmentService } from 'services';
import Tools from 'utils/Tools';
import assignmentStatus from 'common/constants/assignmentStatus';

import AssignmentDialog from './AssignmentDialog';

import {
	AddButton,
	AssignmentFiles,
	AssignmentFilters,
	Layout,
	NoData,
	PageTitle,
	Paginator,
	Spinner,
	WriterCommentModal,
} from 'components';
import CanI from 'components/CanI';

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: -3,
		top: 5,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
	},
}));

const { getAssignments } = AssignmentService;
const { getDate, getErrorMessage } = Tools;

const limit = 15;
const smallFontSize = { fontSize: 13 };

const Assignments = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [filters, setFilters] = useState({});
	const [assignments, setAssignments] = useState([]);
	const [selectedAssignment, setSelectedAssignment] = useState(null);
	const [viewAssignmentFiles, setViewAssignmentFiles] = useState(null);
	const [showAddForm, setShowAddForm] = useState(false);
	const [selectedWriter, setSelectedWriter] = useState(null);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			const queryString = Tools.objectToQueryString(filters);
			getAssignments(queryString)
				.then(({ data }) => {
					setAssignments(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [isLoading, enqueueSnackbar, filters]);

	const handleClose = () => {
		if (selectedAssignment) setSelectedAssignment(null);
		if (showAddForm) setShowAddForm(false);
	};

	const handleSearch = (filters) => {
		setFilters(filters);
		setIsLoading(true);
	};

	const handleChangePage = (page) => {
		setFilters({ ...filters, offset: page === 1 ? null : `${(page - 1) * limit}` });
		setIsLoading(true);
	};

	const handleCloseWriterCommentModal = () => {
		setSelectedWriter(null);
	};

	const handleClickWriter = (user) => {
		setSelectedWriter(user);
	};

	const pages = isLoading ? 0 : Math.floor((assignments.total + limit - 1) / limit);

	return (
		<Layout>
			<PageTitle
				title="Asignaciones"
				actionButtons={[
					<CanI make="TASK_ADD">
						<Link to="/tareas">
							<Button onClick={null}>Tareas</Button>
						</Link>
					</CanI>,
					<CanI make="ASSIGNMENT_ADD">
						<AddButton onClick={() => setShowAddForm(true)} />
					</CanI>,
				]}
			/>
			<AssignmentFilters onSearch={handleSearch} />
			{isLoading && <Spinner text="Cargando asignaciones" isOpen small />}
			{!isLoading && (
				<div>
					<Typography>Total: {assignments.total}</Typography>
					<TableContainer component={Box}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Estatus</TableCell>
									<TableCell>Entrega</TableCell>
									<TableCell>Título</TableCell>
									<TableCell>Cliente</TableCell>
									<TableCell>Proyecto</TableCell>
									<TableCell>Redactor</TableCell>
									<TableCell>Editor</TableCell>
									<TableCell>Acciones</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{assignments.data.map((assignment) => {
									const {
										clientName,
										deliveryDate,
										docUrl,
										editorName,
										id,
										isFeatured,
										projectName,
										status,
										title,
										writerName,
									} = assignment;

									return (
										<TableRow key={id}>
											<TableCell sx={{ whiteSpace: 'nowrap' }}>
												{assignmentStatus[status].label}
											</TableCell>
											<TableCell sx={smallFontSize}>
												{getDate({ date: deliveryDate, format: 'short' })}
											</TableCell>
											<TableCell>
												<Link target="_blank" to={`/asignaciones/${id}`}>
													{title}
												</Link>
											</TableCell>
											<TableCell sx={smallFontSize}>{clientName}</TableCell>
											<TableCell sx={smallFontSize}>{projectName}</TableCell>
											<TableCell sx={smallFontSize}>
												<Button
													endIcon={
														!!isFeatured && <StarOutlined htmlColor="orange" fontSize="small" />
													}
													style={{
														display: 'flex',
														alignItems: 'center',
														textTransform: 'none',
														whiteSpace: 'nowrap',
													}}
													variant="text"
													size="small"
													onClick={() => handleClickWriter(assignment)}
												>
													{writerName}
												</Button>
											</TableCell>
											<TableCell sx={smallFontSize}>{editorName || ''}</TableCell>
											<TableCell>
												<div style={{ display: 'flex' }}>
													<CanI make="ASSIGNMENT_EDIT">
														<IconButton
															size="small"
															onClick={() => setSelectedAssignment(assignment)}
														>
															<EditRounded />
														</IconButton>
													</CanI>
													{docUrl && (
														<>
															<CanI make="ASSIGNMENT_DOWNLOAD">
																<a href={docUrl}>
																	<IconButton size="small">
																		<FileDownloadOutlined />
																	</IconButton>
																</a>
															</CanI>
															<IconButton
																size="small"
																onClick={() => setViewAssignmentFiles(assignment)}
																disabled={!assignment.totalFiles}
															>
																<StyledBadge badgeContent={assignment.totalFiles} color="info">
																	<AttachmentOutlined />
																</StyledBadge>
															</IconButton>
														</>
													)}
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
			{!assignments?.data?.length && !isLoading && <NoData message="No hay asignaciones" />}
			{!!assignments?.data?.length && <Paginator pages={pages} onChange={handleChangePage} />}
			{(showAddForm || selectedAssignment) && (
				<AssignmentDialog
					assignment={selectedAssignment}
					onCancel={handleClose}
					onSuccess={() => setIsLoading(true)}
				/>
			)}
			{viewAssignmentFiles && (
				<AssignmentFiles
					assignment={viewAssignmentFiles}
					onClose={() => setViewAssignmentFiles(null)}
				/>
			)}
			{selectedWriter && (
				<WriterCommentModal user={selectedWriter} onClose={handleCloseWriterCommentModal} />
			)}
		</Layout>
	);
};

export default Assignments;
