import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';

import { TaskService } from 'services';
import { Layout, PageTitle, Spinner } from 'components';
import Tools from 'utils/Tools';
import { taskStatus } from 'common/constants/taskStatus';
import MyTaskDialog from './MyTaskDialog';

const MyTasks = () => {
	const [tasks, setTasks] = useState(null);
	const [isLoadingTasks, setIsLoadingTasks] = useState(true);
	const [selectedTask, setSelectedTask] = useState(null);

	const openDialog = (task) => setSelectedTask(task);
	const closeDialog = () => setSelectedTask(null);

	useEffect(() => {
		if (isLoadingTasks) {
			TaskService.getMyTasks()
				.then(({ data }) => {
					setTasks(data);
				})
				.catch(console.log)
				.finally(() => {
					setIsLoadingTasks(false);
				});
		}
	}, [isLoadingTasks]);

	return (
		<Layout>
			<PageTitle title="Mis tareas" />
			{isLoadingTasks && <Spinner small text="Cargando mis tareas" isOpen />}
			{!isLoadingTasks && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Código</TableCell>
								<TableCell>Tarea</TableCell>
								<TableCell>Descripción</TableCell>
								<TableCell>Detalles</TableCell>
								<TableCell>Entrega</TableCell>
								<TableCell>Duración (min)</TableCell>
								<TableCell>Efectividad</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Monto</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tasks?.map((task) => {
								const {
									deliveryDate,
									description,
									duration,
									guidelines,
									id,
									name,
									status,
									taskTypeCode,
								} = task;
								const date = Tools.getDate({ date: deliveryDate });
								const amount = Tools.toFixed2(duration * 120);

								return (
									<TableRow key={id}>
										<TableCell>{taskTypeCode}</TableCell>
										<TableCell>{name}</TableCell>
										<TableCell>{description}</TableCell>
										<TableCell>{guidelines}</TableCell>
										<TableCell sx={{ whiteSpace: 'nowrap' }}>{date}</TableCell>
										<TableCell align="right">{duration}</TableCell>
										<TableCell>-</TableCell>
										<TableCell>{taskStatus[status].label}</TableCell>
										<TableCell align="right">{amount}</TableCell>
										<TableCell>
											<Button
												size="small"
												color="info"
												variant="outlined"
												style={{ margin: '8px 0' }}
												onClick={() => openDialog(task)}
											>
												Ver detalles
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{selectedTask && <MyTaskDialog onClose={closeDialog} task={selectedTask} />}
		</Layout>
	);
};

export default MyTasks;
