import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';

import CustomDialogTitle from 'components/CustomDialogTitle';
import { UserInfoService } from 'services';

import WriterCommentForm from './WriterCommentForm';
import Spinner from 'components/Spinner';
import { useService } from 'hooks';
import { useSnackbar } from 'notistack';

const WriterCommentModal = ({ user, onClose }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [userInfo, setUserInfo] = useState({});
	const { writerId, writerName } = user;
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			UserInfoService.getUserInfo(writerId)
				.then(({ data }) => setUserInfo(data))
				.catch(console.log)
				.finally(() => setIsLoading(false));
		}
	}, [isLoading, writerId]);

	const handleSubmit = (values) => {
		callService(UserInfoService.updateUserInfo(writerId, values))
			.then(() => {
				enqueueSnackbar('Comentarios actualizados', { variant: 'success' });
				onClose();
			})
			.catch(console.log);
	};

	return (
		<Dialog open onClose={onClose}>
			<CustomDialogTitle>Comentarios para {writerName}</CustomDialogTitle>
			<DialogContent>
				{isLoading && <Spinner isOpen small text="Cargando comentarios..." />}
				{!isLoading && (
					<WriterCommentForm
						comments={userInfo.comments}
						onCancel={onClose}
						onSubmit={handleSubmit}
					/>
				)}
			</DialogContent>
			<Spinner isOpen={isProcessing} text="Guardando..." />
		</Dialog>
	);
};

export default WriterCommentModal;
