import { useEffect, useState } from 'react';

import { UserService } from 'services';

import { useSnackbar } from 'notistack';

import Tools from 'utils/Tools';

import { AddButton, Layout, NoData, PageTitle, Spinner, StatusChip, UserFilters } from 'components';
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';
import { EditRounded } from '@mui/icons-material';

import EditUserDialog from '../EditUserDialog';
import AddUserDialog from '../AddUserDialog';
import CanI from 'components/CanI';

const { getEditors } = UserService;

const Editors = () => {
	const [editors, setEditors] = useState([]);
	const [selectedEditor, setSelectedEditor] = useState(null);
	const [showAddForm, setShowAddForm] = useState(false);
	const [filters, setFilters] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	const handleCloseEditDialog = () => {
		setSelectedEditor(null);
	};

	useEffect(() => {
		if (isLoading) {
			getEditors(filters)
				.then(({ data }) => {
					setEditors(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [enqueueSnackbar, filters, isLoading]);

	const handleFilters = (queryString) => {
		setFilters(queryString);
		setIsLoading(true);
	};

	return (
		<Layout>
			<PageTitle
				title="Editores"
				actionButtons={[
					<CanI make="EDITOR_ADD">
						<AddButton onClick={() => setShowAddForm(true)} />
					</CanI>,
				]}
			/>
			<UserFilters onSearch={handleFilters} />
			{isLoading && <Spinner text="Cargando editores" isOpen small />}
			{!isLoading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Apellidos</TableCell>
								<TableCell>Nombres</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Teléfono</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{editors.map((editor) => (
								<TableRow key={editor.id}>
									<TableCell>{editor.lastName}</TableCell>
									<TableCell>{editor.name}</TableCell>
									<TableCell>{editor.email}</TableCell>
									<TableCell>{editor.phone || '(No disponible)'}</TableCell>
									<TableCell>
										<StatusChip isEnabled={editor.isEnabled} />
									</TableCell>
									<TableCell>
										<CanI make="EDITOR_EDIT">
											<IconButton size="small" onClick={() => setSelectedEditor(editor)}>
												<EditRounded />
											</IconButton>
										</CanI>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{!editors.length && !isLoading && <NoData message="No hay editores" />}

			{showAddForm && (
				<AddUserDialog
					userRole="EDITOR"
					onClose={() => setShowAddForm(false)}
					onSuccess={() => setIsLoading(true)}
				/>
			)}
			{selectedEditor && (
				<EditUserDialog
					onSuccess={() => setIsLoading(true)}
					user={selectedEditor}
					onClose={handleCloseEditDialog}
				/>
			)}
		</Layout>
	);
};

export default Editors;
