import { Button, TextField } from '@mui/material';
import { ActionButtons } from 'components';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import validationSchemas from 'utils/validationSchemas';

const getOptionsInitialValues = (options) => {
	const initialValues = {};

	options.map(({ key, value }) => (initialValues[key] = value));

	return initialValues;
};

const OptionsForm = ({ options, onFinish }) => {
	const { enqueueSnackbar } = useSnackbar();

	const handleFinish = (values) => {
		const EDITOR_FEE = Number(values.EDITOR_FEE);
		if (Number.isNaN(EDITOR_FEE)) {
			enqueueSnackbar('El monto del editor debe ser un número (Ej.: 0.7)', { variant: 'error' });
			return 0;
		}

		onFinish({ ...values, EDITOR_FEE });
	};

	const formik = useFormik({
		initialValues: {
			...getOptionsInitialValues(options),
		},
		validationSchema: validationSchemas.options,
		onSubmit: handleFinish,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			{options.map((option) => {
				const { key, label } = option;

				return (
					<TextField
						key={key}
						fullWidth
						id={key}
						name={key}
						label={label}
						value={formik.values[key]}
						onChange={formik.handleChange}
						error={formik.touched[key] && Boolean(formik.errors[key])}
						helperText={formik.touched[key] && formik.errors[key]}
						margin="normal"
						size="medium"
					/>
				);
			})}
			<ActionButtons style={{ marginTop: 16 }}>
				<Button color="primary" variant="contained" type="submit">
					Guardar
				</Button>
			</ActionButtons>
		</form>
	);
};

export default OptionsForm;
