import { Dialog, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';

import { CustomDialogTitle, Spinner } from 'components';

import Tools from 'utils/Tools';

import { UserService } from 'services';

import UserEditionForm from './UserEditionForm';

const EditUserDialog = ({ user, onClose, onSuccess }) => {
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = (values, { setFieldError }) => {
		callService(UserService.update(user.id, values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onSuccess();
				onClose();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	return (
		<Dialog
			onClose={(_, reason) => {
				Tools.handleCloseDialog(_, reason, onClose);
			}}
		>
			<CustomDialogTitle>Editar usuario</CustomDialogTitle>
			<DialogContent>
				<UserEditionForm user={user} onSubmit={handleSubmit} onCancel={onClose} />
			</DialogContent>
			<Spinner isOpen={isProcessing} text="Actualizando usuario..." />
		</Dialog>
	);
};

export default EditUserDialog;
