import { useEffect, useState } from 'react';

import { Dialog, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';

import { CategoryService, CategoryRatesService, UserService } from 'services';
import Tools from 'utils/Tools';

import { CustomDialogTitle, Spinner } from 'components';
import CategoryForm from './CategoryForm';

import './CategoryDialog.scss';

const { updateCategory, createCategory } = CategoryService;
const { getCategoryRates } = CategoryRatesService;
const { getWriters } = UserService;

const CategoryDialog = ({ category, onCancel, onSuccess }) => {
	const [categoryRates, setCategoryRates] = useState([]);
	const [initialRates, setInitialRates] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			if (category)
				getCategoryRates(category.id)
					.then(({ data }) => {
						let rates = {};

						data.map(({ userId, rate }) => {
							rates[userId] = rate;
							return true;
						});

						setCategoryRates(data);
						setInitialRates(rates);
						setIsLoading(false);
					})
					.catch((error) => {
						enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
						setIsLoading(false);
					});
			else
				getWriters('orderBy=name')
					.then(({ data }) => {
						const rates = {};

						data.map(({ id }) => {
							rates[id] = 0.5;
							return true;
						});

						const realRates = data.map(({ id, name, lastName }) => ({
							name,
							lastName,
							userId: id,
							rate: 0.5,
						}));

						setCategoryRates(realRates);
						setInitialRates(rates);
						setIsLoading(false);
					})
					.catch((error) => {
						setIsLoading(false);
						enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
						setIsLoading(false);
					});
		}
	}, [isLoading, enqueueSnackbar, category]);

	const handleSubmit = (values, { setFieldError }) => {
		callService(category ? updateCategory(category.id, values) : createCategory(values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onCancel();
				onSuccess();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	return (
		<Dialog className="CategoryDialog">
			<CustomDialogTitle>{category ? 'Editar' : 'Nueva'} etiqueta</CustomDialogTitle>
			<DialogContent>
				{isLoading ? (
					<Spinner text="Cargando calificaciones" small />
				) : (
					<CategoryForm
						onCancel={onCancel}
						onSubmit={handleSubmit}
						category={category}
						rates={categoryRates}
						initialRates={initialRates}
					/>
				)}
			</DialogContent>
			<Spinner text={`${!category ? 'Agregando' : 'Guardando'} etiqueta`} isOpen={isProcessing} />
		</Dialog>
	);
};

export default CategoryDialog;
