import { useState } from 'react';

import { Alert, AlertTitle, Button, Grid, IconButton } from '@mui/material';
import { ListOutlined, VisibilityOutlined } from '@mui/icons-material';

import { useLoggedUser } from 'hooks';
import roles from 'common/constants/roles';
import ProjectDetails from 'components/ProjectDetails';
import TitledBox from 'components/TitledBox';
import LabeledValue from 'components/LabeledValue';

import Tools from 'utils/Tools';
import ActionButtons from 'components/ActionButtons';
import CommentDialog from './CommentDialog';

const {
	getDate,
	getStatusLabel,
	getDangerousHtmlObject,
	getCategoriesNames,
	countWords,
	getFirstWords,
	toFixed2,
} = Tools;

const AssignmentDetails = ({ assignment }) => {
	const [selectedComment, setSelectedComment] = useState(null);
	const { loggedUser } = useLoggedUser();
	const [viewProjectDetails, setViewProjectDetails] = useState(false);

	const { role: myRole, userInfo } = loggedUser;

	let info = [
		{ key: 'client', label: 'Cliente', value: assignment.clientName },
		{
			key: 'project',
			label: 'Proyecto',
			value: (
				<>
					{assignment.projectName}{' '}
					<IconButton size="small" onClick={() => setViewProjectDetails(true)}>
						<VisibilityOutlined />
					</IconButton>
				</>
			),
		},
		{ key: 'category', label: 'Etiquetas', value: getCategoriesNames(assignment.categories) },
		{
			key: 'writer',
			label: 'Redactor',
			value: myRole === roles.EDITOR.value ? '-' : assignment.writerName,
		},
		{
			key: 'endDate',
			label: 'Fecha tope',
			value: getDate({ date: assignment.deliveryDate, format: 'short' }),
		},
		{
			key: 'pricePer1000',
			label: 'Precio x 1000 palabras',
			value: `$${
				myRole === roles.WRITER.value
					? assignment.pricePer1000 + userInfo.bonus
					: assignment.pricePer1000
			}`,
		},
		{
			key: 'status',
			label: 'Estatus',
			value: getStatusLabel(assignment.status),
		},
		{
			key: 'guidelines',
			label: '',
			fullWidth: true,
			value: (
				<Alert variant="outlined" color="info" icon={<ListOutlined />}>
					<AlertTitle>Indicaciones a seguir:</AlertTitle>
					<div
						style={{ fontSize: 16 }}
						dangerouslySetInnerHTML={getDangerousHtmlObject(assignment?.guidelines || '-')}
					/>
				</Alert>
			),
		},
	];

	const { isRated, wellDone, mustDoBetter, mustFix, totalWords, writerQualification } = assignment;

	let comments = [];

	if (isRated) {
		info = info.concat([
			{
				key: 'writerQualification',
				label: 'Calificación',
				value: toFixed2(writerQualification),
			},
			{
				key: 'totalWords',
				label: 'Total de palabras',
				value: totalWords.toLocaleString('es-ES'),
			},
		]);

		comments = [
			{ title: 'Bien hecho:', type: 'success', comment: wellDone },
			{ title: 'Debe mejorar:', type: 'info', comment: mustDoBetter },
			{ title: 'Atender con urgencia:', type: 'error', comment: mustFix },
		];
	}

	return (
		<TitledBox title="Detalles de la asignación">
			<Grid container spacing={2}>
				{info.map(({ key, value, label, fullWidth }) => {
					if (['writer'].includes(key) && loggedUser.role === roles.WRITER.value) return null;

					return (
						<Grid key={key} item xs={12} sm={fullWidth ? 12 : 6} md={fullWidth ? 12 : 4}>
							<LabeledValue label={label} value={value} />
						</Grid>
					);
				})}
			</Grid>
			{viewProjectDetails && (
				<ProjectDetails
					projectId={assignment?.projectId}
					onClose={() => setViewProjectDetails(false)}
				/>
			)}
			{!!isRated && (
				<Grid container spacing={2} sx={{ marginTop: 2 }}>
					{comments.map((comment) => {
						const { title, type, comment: commentText } = comment;
						const maxWords = 25;
						const isTooLong = countWords(commentText) > maxWords;

						const text = isTooLong ? `${getFirstWords(commentText, maxWords)}...` : commentText;

						return (
							<Grid item xs={12} sm={6} md={4} key={type}>
								<Alert severity={type}>
									<AlertTitle>{title}</AlertTitle>
									<div dangerouslySetInnerHTML={getDangerousHtmlObject(text)} />
									{isTooLong && (
										<ActionButtons style={{ marginTop: 16 }}>
											<Button
												color="inherit"
												size="small"
												onClick={() => setSelectedComment(comment)}
											>
												Ver más
											</Button>
										</ActionButtons>
									)}
								</Alert>
							</Grid>
						);
					})}
				</Grid>
			)}
			{selectedComment && (
				<CommentDialog {...selectedComment} onClose={() => setSelectedComment(null)} />
			)}
		</TitledBox>
	);
};

export default AssignmentDetails;
