import { useState } from 'react';

import { FormControlLabel, Switch } from '@mui/material';

const CustomSwitch = ({
	name = 'isEnabled',
	id = 'isEnabled',
	label = 'Activo',
	formik,
	initialValue,
	...restProps
}) => {
	const [value, setValue] = useState(initialValue);

	return (
		<FormControlLabel
			control={
				<Switch
					color="success"
					name={name}
					id={id}
					onChange={(event) => {
						setValue(event.target.checked);
						formik.values[name] = event.target.checked;
					}}
					checked={value}
					{...restProps}
				/>
			}
			onChange={formik.handleChange}
			label={label}
		/>
	);
};

export default CustomSwitch;
