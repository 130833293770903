import { Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';

const AddButton = ({ onClick, text = 'Agregar' }) => {
	return (
		<Button
			onClick={onClick}
			size="small"
			color="primary"
			variant="contained"
			startIcon={<AddRounded />}
		>
			{text}
		</Button>
	);
};

export default AddButton;
