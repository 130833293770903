import { useSnackbar } from 'notistack';
import { Dialog, DialogContent } from '@mui/material';

import { useService } from 'hooks';
import { CustomDialogTitle, Spinner } from 'components';

import Tools from 'utils/Tools';
import { PermissionsForm } from './PermissionsForm';
import { OptionService } from 'services';

export const UserPermissionsDialog = ({ onClose, user, onSuccess }) => {
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = (values) => {
		const payload = { permissions: Object.keys(values).filter((key) => values[key]) };

		callService(OptionService.savePermissions(user.id, payload))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onSuccess();
				onClose();
			})
			.catch(console.log);
	};

	return (
		<Dialog
			onClose={(_, reason) => {
				Tools.handleCloseDialog(_, reason, onClose);
			}}
		>
			<CustomDialogTitle>Permisos para {user.name}</CustomDialogTitle>
			<DialogContent>
				<PermissionsForm onCancel={onClose} user={user} onSubmit={handleSubmit} />
			</DialogContent>
			<Spinner isOpen={isProcessing} text="Agregando usuario..." />
		</Dialog>
	);
};
