import { NavLink } from 'react-router-dom';

import {
	Box,
	Divider,
	MenuList,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LogoutRounded, DashboardRounded, AccountBoxRounded } from '@mui/icons-material';

import { useLoggedUser } from 'hooks';

import icons from 'common/constants/menuIcons';
import userMenu from 'common/data/userMenu.json';

import Tools from 'utils/Tools';

import './UserMenu.scss';

const { getDate, getFullName, getRoleLabel } = Tools;

const UserMenu = ({ onLogout, onClickItem }) => {
	const { loggedUser } = useLoggedUser();
	const handleClick = () => {
		onClickItem();
	};

	return (
		<Box width={250} className="UserMenu">
			<ThemeProvider theme={createTheme({})}>
				<MenuList className="UserMenu-list">
					<div className="UserMenu-list-name">
						<Typography component="span" variant="h5">
							{getFullName(loggedUser)}
						</Typography>
						<Typography component="span" variant="body1">
							{getRoleLabel(loggedUser.role)}
						</Typography>
						<Typography sx={{ mt: 1 }} component="span" variant="body2" color="lightblue">
							Hoy es {getDate({ format: 'long' })}
						</Typography>
					</div>
					<MenuItem onClick={handleClick} className="UserMenu-list-item">
						<NavLink exact activeClassName="activeLink" to="/">
							<DashboardRounded />
							<ListItemText>Dashboard</ListItemText>
						</NavLink>
					</MenuItem>
					{userMenu[loggedUser.role].map(({ key, to, text }) => (
						<MenuItem key={key} onClick={handleClick} className="UserMenu-list-item">
							<NavLink exact activeClassName="activeLink" to={to}>
								{icons[key]}
								<ListItemText>{text}</ListItemText>
							</NavLink>
						</MenuItem>
					))}
					<MenuItem onClick={handleClick} className="UserMenu-list-item">
						<NavLink exact activeClassName="activeLink" to="/perfil">
							<AccountBoxRounded />
							<ListItemText>Mi perfil</ListItemText>
						</NavLink>
					</MenuItem>
					<Divider />
					<MenuItem onClick={onLogout}>
						<ListItemIcon>
							<LogoutRounded color="error" />
						</ListItemIcon>
						<ListItemText primaryTypographyProps={{ color: 'error' }}>Salir</ListItemText>
					</MenuItem>
				</MenuList>
			</ThemeProvider>
		</Box>
	);
};

export default UserMenu;
