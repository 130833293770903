import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { Button, Dialog, DialogContent, TextField } from '@mui/material';

import { ActionButtons, CustomDialogTitle, Spinner } from 'components';
import { useService } from 'hooks';
import QualificationService from 'services/QualificationService';
import validationSchemas from 'utils/validationSchemas';
import Tools from 'utils/Tools';

const { createQualificationCriteria, updateQualificationCriteria } = QualificationService;

export const CriteriaFormDialog = ({ onClose, criteria, onFinish }) => {
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = (values, { setFieldError }) => {
		const handler = criteria ? updateQualificationCriteria : createQualificationCriteria;
		const args = criteria ? [criteria.id, values] : [values];

		callService(handler(...args))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onFinish();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	const form = useFormik({
		initialValues: {
			description: criteria?.description || '',
			percentage: criteria?.percentage || 0,
		},
		validationSchema: validationSchemas.criteria,
		onSubmit: handleSubmit,
	});

	return (
		<Dialog open onClose={onClose}>
			<CustomDialogTitle>{criteria ? 'Editar' : 'Añadir'} aspecto</CustomDialogTitle>
			<DialogContent>
				<form onSubmit={form.handleSubmit}>
					<TextField
						error={form.touched.description && Boolean(form.errors.description)}
						fullWidth
						helperText={form.touched.description && form.errors.description}
						id="description"
						label="Descripción"
						margin="normal"
						name="description"
						onChange={form.handleChange}
						size="small"
						value={form.values.description}
					/>
					<TextField
						error={form.touched.percentage && Boolean(form.errors.percentage)}
						fullWidth
						helperText={form.touched.percentage && form.errors.percentage}
						id="percentage"
						label="Porcentaje de afectación"
						name="percentage"
						onChange={form.handleChange}
						type="number"
						value={form.values.percentage}
						margin="normal"
					/>
					<ActionButtons sx={{ mt: 1 }}>
						<Button onClick={onClose}>Cancelar</Button>
						<Button type="submit" color="primary" variant="contained">
							{criteria ? 'Guardar' : 'Añadir'}
						</Button>
					</ActionButtons>
				</form>
			</DialogContent>
			<Spinner isOpen={isProcessing} text="Guardando..." />
		</Dialog>
	);
};
