import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';

import { UserService, TaskTypeService, TaskService } from 'services';
import { CustomDialogTitle, Spinner } from 'components';
import Tools from 'utils/Tools';

import { TaskDialogForm } from './TaskDialogForm';
import { useService } from 'hooks';

const { getAdministrators } = UserService;
const { getTaskTypes } = TaskTypeService;
const { createTask, updateTask } = TaskService;

const TaskDialog = ({ task, onClose, onSuccess }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [administrators, setAdministrators] = useState([]);
	const [taskTypes, setTaskTypes] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	const { callService, isProcessing } = useService();

	useEffect(() => {
		Promise.all([getAdministrators(), getTaskTypes()])
			.then(([{ data: adminsData }, { data: taskTypesData }]) => {
				setAdministrators(adminsData);
				setTaskTypes(taskTypesData);
			})
			.catch(console.log)
			.finally(() => {
				setIsLoading(false);
			});
	}, [isLoading]);

	const handleSubmit = (values, { setFieldError }) => {
		const { userId, deliveryDate, guidelines, recurrence, code } = values;
		const payload = {
			userId,
			deliveryDate,
			guidelines,
			recurrence,
			taskTypeCode: code,
		};

		const handler = task ? updateTask : createTask;
		const args = task ? [task.id, payload] : [payload];

		callService(handler(...args))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onSuccess();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	return (
		<Dialog className="ProjectDialog" open onClose={onClose}>
			<CustomDialogTitle>{task ? 'Editar' : 'Nueva'} tarea</CustomDialogTitle>
			<DialogContent>
				{isLoading && <Spinner small text="Cargando datos" />}
				{!isLoading && (
					<TaskDialogForm
						onSubmit={handleSubmit}
						admins={administrators}
						taskTypes={taskTypes}
						onClose={onClose}
						task={task}
					/>
				)}
			</DialogContent>
			{isProcessing && <Spinner isOpen text="Procesando..." />}
		</Dialog>
	);
};

export default TaskDialog;
