import {
	Box,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { useLoggedUser } from 'hooks';
import Tools from 'utils/Tools';
import NoData from 'components/NoData';
import Spinner from 'components/Spinner';

import extraTypes from 'common/constants/extraType';
import roles from 'common/constants/roles';

const { getDate, toFixed2, getPaymentStatusLabel } = Tools;

const UserPaymentsList = ({ paymentData, isLoading }) => {
	const {
		loggedUser: { role, userInfo },
	} = useLoggedUser();
	const { payments, extra } = paymentData || {};
	const thereArePayments = !!payments?.length;
	const thereAreExtras = !!extra?.length;

	const isWriter = role === roles.WRITER.value;

	let subTotal = 0;
	let subTotalExtra = 0;
	if (thereArePayments)
		subTotal = payments.reduce((accumulator, { amount }) => accumulator + amount, 0);

	if (thereAreExtras)
		subTotalExtra = extra.reduce(
			(accumulator, { amount, extraType }) =>
				accumulator + (extraType === extraTypes.DISCOUNT.value ? amount * -1 : amount),
			0
		);

	return (
		<>
			<Typography component="h2" variant="h4" sx={{ marginBottom: 1 }}>
				Pagos
			</Typography>
			{isLoading && <Spinner small text="Cargando facturación" isOpen />}
			{payments && !!payments?.length && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell size="medium">Estatus</TableCell>
								<TableCell>Fecha de envío</TableCell>
								<TableCell>Título</TableCell>
								<TableCell>Calidad</TableCell>
								<TableCell>Fluidez</TableCell>
								<TableCell>Precio x 1000</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>Palabras</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>Pago</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{payments.map((payment) => {
								const { totalWords, maxWords } = payment;

								return (
									<TableRow key={payment.id}>
										<TableCell size="medium">{getPaymentStatusLabel(payment.status)}</TableCell>
										<TableCell>{getDate({ date: payment.sentAt, format: 'dateTime' })}</TableCell>
										<TableCell>
											<Link target="_blank" to={`/mis-asignaciones/${payment.assignmentId}`}>
												{payment.title}
											</Link>
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{payment.isRated ? payment.quality : 'N/A'}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{payment.isRated ? payment.fluency : 'N/A'}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											$
											{toFixed2(
												isWriter ? userInfo.bonus + payment.pricePer1000 : payment.pricePer1000
											)}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{totalWords}
											{totalWords > maxWords && (
												<>
													<br />
													<small>(máx. {maxWords})</small>
												</>
											)}
										</TableCell>
										<TableCell sx={{ textAlign: 'right' }}>${toFixed2(payment.amount)}</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell colSpan={7}>
									<Typography variant="h5" textAlign="right">
										Total pagos:
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="h5" textAlign="right">
										$
										{toFixed2(
											payments?.reduce((accumulator, { amount }) => accumulator + amount, 0)
										)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{payments && !payments?.length && <NoData message="No hay pagos registrados" />}
			<Divider sx={{ my: 2 }} />
			<Typography component="h2" variant="h4" sx={{ marginBottom: 1 }}>
				Extras
			</Typography>
			{isLoading && <Spinner small text="Cargando montos extra" isOpen />}
			{extra && !!extra?.length && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell size="medium">Estatus</TableCell>
								<TableCell>Tipo</TableCell>
								<TableCell>Motivo/Descripción</TableCell>
								<TableCell>Monto</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{extra.map((extra) => {
								const { id, status, extraType, amount, description } = extra;
								const isDiscount = extraType === extraTypes.DISCOUNT.value;

								return (
									<TableRow key={id}>
										<TableCell>{getPaymentStatusLabel(status)}</TableCell>
										<TableCell>{extraTypes[extraType].label}</TableCell>
										<TableCell>{description}</TableCell>
										<TableCell
											sx={{
												textAlign: 'right',
												color: isDiscount ? 'darkred' : 'green',
												fontWeight: 500,
											}}
										>
											{isDiscount && '-'}${toFixed2(amount)}
										</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell colSpan={3}>
									<Typography variant="h5" textAlign="right">
										Total extras:
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="h5" textAlign="right">
										$
										{toFixed2(
											extra.reduce(
												(accumulator, { amount, extraType }) =>
													accumulator +
													(extraType === extraTypes.DISCOUNT.value ? amount * -1 : amount),
												0
											)
										)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{extra && !extra?.length && <NoData message="No hay montos extras" />}
			<Divider sx={{ my: 2 }} />
			<Typography component="h2" variant="h4" textAlign="right">
				Total: ${toFixed2(subTotal + subTotalExtra)}
			</Typography>
		</>
	);
};

export default UserPaymentsList;
