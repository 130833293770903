import Http from './Http';

const endpoint = 'user-rates';

const userRates = {
	getUsersRates() {
		return Http.get(endpoint);
	},

	updateUserRates(data) {
		return Http.put(endpoint, data);
	},
};

export default userRates;
