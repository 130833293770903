import Http from './Http';

const endpoint = 'category';

const category = {
	getCategories() {
		return Http.get(endpoint);
	},

	createCategory(data) {
		return Http.post(endpoint, data);
	},

	updateCategory(id, data) {
		return Http.put(`${endpoint}/${id}`, data);
	},
};

export default category;
