import Http from './Http';

const endpoint = 'payments';

const payments = {
	getPayments(query) {
		return Http.get(`${endpoint}/list?${query}`);
	},

	getPaymentsByUser(query) {
		return Http.get(`${endpoint}?${query}`);
	},

	processPayments(data) {
		return Http.put(endpoint, data);
	},

	postponePayments(pendingUsersIds, from, to) {
		return Http.put(`${endpoint}/postpone`, { pendingUsersIds, from, to });
	},

	postponeMyPayments(pendingPayments) {
		return Http.put(`${endpoint}/postpone-mine`, { pendingPayments });
	},

	deletePayment(paymentId) {
		return Http.delete(`${endpoint}/${paymentId}`);
	},
};

export default payments;
