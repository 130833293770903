import { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import Tools from 'utils/Tools';

import './UserFilters.scss';

const UserFilters = ({ onSearch }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [hasFilters, setHasFilters] = useState(false);

	const handleSubmit = (values) => {
		const filters = { ...values };
		if (filters.isEnabled === 'all') filters.isEnabled = null;
		if (filters.isAvailable === 'all') filters.isAvailable = null;

		const query = Tools.objectToQueryString(filters);

		if (query) {
			setHasFilters(true);
			onSearch(query);
		} else {
			enqueueSnackbar('No hay filtros seleccionados');
		}
	};

	const formik = useFormik({
		initialValues: {
			term: '',
			orderBy: 'createdAt',
			order: 'DESC',
			isEnabled: 'true',
			isAvailable: 'true',
		},
		onSubmit: handleSubmit,
	});

	const deleteFilters = () => {
		setHasFilters(false);
		formik.resetForm();
		handleSubmit(formik.values);
	};

	return (
		<div className="UserFilters">
			<form onSubmit={formik.handleSubmit} className="UserFilters-form">
				<TextField
					size="small"
					fullWidth
					id="term"
					name="term"
					label="Término de búsqueda"
					value={formik.values.term}
					onChange={formik.handleChange}
					helperText="Buscar por nombre, apellido, email o teléfono"
				/>
				<FormControl fullWidth size="small">
					<InputLabel id="demo-simple-select-label">Ordenar por</InputLabel>
					<Select
						labelId="orderBy-label"
						id="orderBy"
						label="Ordenar por"
						name="orderBy"
						size="small"
						onChange={formik.handleChange}
						value={formik.values.orderBy}
					>
						<MenuItem value="createdAt">Fecha de ingreso</MenuItem>
						<MenuItem value="name">Nombres</MenuItem>
						<MenuItem value="lastName">Apellidos</MenuItem>
						<MenuItem value="bonus">Bono</MenuItem>
						<MenuItem value="email">Correo electrónico</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth size="small">
					<InputLabel id="demo-simple-select-label">Orden</InputLabel>
					<Select
						labelId="order-label"
						id="order"
						label="Orden"
						name="order"
						size="small"
						onChange={formik.handleChange}
						value={formik.values.order}
					>
						<MenuItem value="ASC">Ascendente</MenuItem>
						<MenuItem value="DESC">Descendente</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth size="small">
					<InputLabel id="demo-simple-select-label">Estatus</InputLabel>
					<Select
						labelId="isEnabled-label"
						id="isEnabled"
						label="Estatus"
						name="isEnabled"
						size="small"
						onChange={formik.handleChange}
						value={formik.values.isEnabled}
					>
						<MenuItem value="all">Todos</MenuItem>
						<MenuItem value="true">Activo</MenuItem>
						<MenuItem value="false">Deshabilitado</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth size="small">
					<InputLabel id="demo-simple-select-label">Disponibilidad</InputLabel>
					<Select
						labelId="isAvailable-label"
						id="isAvailable"
						label="Disponibilidad"
						name="isAvailable"
						size="small"
						onChange={formik.handleChange}
						value={formik.values.isAvailable}
					>
						<MenuItem value="all">Todos</MenuItem>
						<MenuItem value="true">Disponible</MenuItem>
						<MenuItem value="false">No Disponible</MenuItem>
					</Select>
				</FormControl>
				<div className="UserFilters-form-actions">
					<Button variant="outlined" color="primary" type="submit">
						Aplicar
					</Button>
					{hasFilters && (
						<Button variant="outlined" color="secondary" onClick={deleteFilters}>
							Quitar
						</Button>
					)}
				</div>
			</form>
		</div>
	);
};

export default UserFilters;
