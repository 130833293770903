import React, { useEffect, useState } from 'react';

import { Tab } from '@mui/material';
import { useSnackbar } from 'notistack';
import { TabPanel, TabContext, TabList } from '@mui/lab';

import { useLoggedUser } from 'hooks';

import { useService } from 'hooks';
import {
	CategoryRatesService,
	ClientRatesService,
	UserInfoService,
	PaymentMethodService,
} from 'services';
import Tools from 'utils/Tools';

import { Spinner, TitledBox } from 'components';

import WriterCategoryPreferencesForm from './WriterCategoryPreferencesForm';
import WriterClientPreferencesForm from './WriterClientPreferencesForm';
import WriterInfoForm from './WriterInfoForm';
import EditorInfoForm from './EditorInfoForm';

const { getWriterCategoryRates, updateWriterCategoryRates } = CategoryRatesService;
const { getWriterClientRates, updateWriterClientRates } = ClientRatesService;
const { getUserInfo, updateUserInfo } = UserInfoService;
const { getPaymentMethods } = PaymentMethodService;

const tabPanelStyle = { style: { paddingLeft: 2, paddingRight: 2 } };

const WriterPreferences = () => {
	const [currentTab, setCurrentTab] = useState('1');
	const [userInfo, setUserInfo] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [categoryRates, setCategoryRates] = useState([]);
	const [clientRates, setClientRates] = useState([]);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [initialCategoryRates, setInitialCategoryRates] = useState({});
	const [initialClientRates, setInitialClientRates] = useState({});
	const { loggedUser } = useLoggedUser();
	const { isWriter, isEditor } = loggedUser;
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			Promise.all([getUserInfo(loggedUser.id), getPaymentMethods()])
				.then(([userData, paymentMethodsData]) => {
					setPaymentMethods(paymentMethodsData.data);
					setUserInfo(userData.data || {});
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});

			if (isWriter) {
				Promise.all([getWriterCategoryRates(), getWriterClientRates()])
					.then(([categoryRatesData, clientRatesData]) => {
						let initialCategoryValues = {};
						categoryRatesData.data.forEach((rate) => {
							initialCategoryValues[rate.categoryId] = rate.rate;
						});
						setCategoryRates(categoryRatesData.data);
						setInitialCategoryRates(initialCategoryValues);

						let initialClientValues = {};
						clientRatesData.data.forEach((rate) => {
							initialClientValues[rate.clientId] = rate.rate;
						});
						setClientRates(clientRatesData.data);
						setInitialClientRates(initialClientValues);

						setIsLoading(false);
					})
					.catch((error) => {
						enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
						setIsLoading(false);
					});
			} else {
				setIsLoading(false);
			}
		}
	}, [isLoading, enqueueSnackbar, loggedUser, isWriter]);

	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const handleSubmitCategory = (values) => {
		callService(updateWriterCategoryRates(values))
			.then(({ data }) => {
				enqueueSnackbar(data.message, { variant: 'success' });
			})
			.catch((error) => {
				const errorMessage = Tools.getErrorMessage(error);
				if (errorMessage) {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
				}
			});
	};

	const handleSubmitClient = (values) => {
		callService(updateWriterClientRates(values))
			.then(({ data }) => {
				enqueueSnackbar(data.message, { variant: 'success' });
			})
			.catch((error) => {
				const errorMessage = Tools.getErrorMessage(error);
				if (errorMessage) {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
				}
			});
	};

	const handleSubmitInfo = (values) => {
		callService(updateUserInfo(loggedUser.id, values))
			.then(({ data }) => {
				enqueueSnackbar(data.message, { variant: 'success' });
			})
			.catch((error) => {
				const errorMessage = Tools.getErrorMessage(error);
				if (errorMessage) {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
				}
			});
	};

	return (
		<div>
			{isWriter && (
				<TitledBox title="Preferencias laborales">
					<TabContext value={currentTab}>
						<TabList
							onChange={handleChange}
							aria-label="lab API tabs example"
							sx={{ borderBottom: '1px solid #ddd' }}
						>
							<Tab label="Categorías" value="1" />
							<Tab label="Clientes" value="2" />
							<Tab label="Otras" value="3" />
						</TabList>
						<TabPanel value="1" {...tabPanelStyle}>
							{isLoading && <Spinner text="Cargando categorías" small />}
							{!isLoading && (
								<WriterCategoryPreferencesForm
									rates={categoryRates}
									initialRates={initialCategoryRates}
									onSubmit={handleSubmitCategory}
									isProcessing={isProcessing}
								/>
							)}
						</TabPanel>
						<TabPanel value="2" {...tabPanelStyle}>
							{isLoading && <Spinner text="Cargando clientes" small />}
							{!isLoading && (
								<WriterClientPreferencesForm
									rates={clientRates}
									initialRates={initialClientRates}
									onSubmit={handleSubmitClient}
									isProcessing={isProcessing}
								/>
							)}
						</TabPanel>
						<TabPanel value="3" {...tabPanelStyle}>
							{isLoading && <Spinner text="Cargando información" small />}
							{!isLoading && (
								<WriterInfoForm
									paymentMethods={paymentMethods}
									userInfo={userInfo}
									onSubmit={handleSubmitInfo}
								/>
							)}
						</TabPanel>
					</TabContext>

					<Spinner text="Procesando..." isOpen={isProcessing} />
				</TitledBox>
			)}
			{isEditor && (
				<TitledBox title="Preferencias de pago">
					{isLoading && <Spinner text="Cargando información" small />}
					{!isLoading && (
						<EditorInfoForm
							onSubmit={handleSubmitInfo}
							paymentMethods={paymentMethods}
							userInfo={userInfo}
						/>
					)}
				</TitledBox>
			)}
		</div>
	);
};

export default WriterPreferences;
