import axios from 'axios';

import { api } from 'config';

const Http = axios.create({
	baseURL: api,
});

// Add a request interceptor
Http.interceptors.request.use((config) => {
	if (localStorage.token) {
		// eslint-disable-next-line no-param-reassign
		config.headers.common.Authorization = `${localStorage.token}`;
	}
	return config;
});

// Add a response interceptor
Http.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response) {
			const {
				data: { message, error: errorMessage, validation: validationErrors },
			} = error.response;

			if (message) {
				console.log(`message`, message);
			} else if (errorMessage) {
				console.log(`errorMessage`, errorMessage);
			} else if (validationErrors) {
				console.log(`validationErrors`, validationErrors);
			}
		}
		return Promise.reject(error);
	}
);

export default Http;
