import { Button, Grid, TextField } from '@mui/material';
import { ActionButtons } from 'components';
import { useFormik } from 'formik';

export const MyTaskForm = ({ onSubmit, onCancel }) => {
	const formik = useFormik({
		initialValues: {
			productiveTime: 0,
			subtractTime: 0,
		},
		onSubmit,
	});

	return (
		<div className="MyTaskForm">
			<form onSubmit={formik.handleSubmit}>
				<Grid container direction="row" spacing={2}>
					<Grid item xs={6}>
						<TextField
							type="number"
							name="productiveTime"
							fullWidth
							id="productiveTime"
							label="Tiempo efectivo"
							value={formik.values.productiveTime}
							onChange={formik.handleChange}
							error={formik.touched.productiveTime && Boolean(formik.errors.productiveTime)}
							helperText={formik.touched.productiveTime && formik.errors.productiveTime}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							type="number"
							name="subtractTime"
							fullWidth
							id="subtractTime"
							label="Restar minutos"
							value={formik.values.subtractTime}
							onChange={formik.handleChange}
							error={formik.touched.subtractTime && Boolean(formik.errors.subtractTime)}
							helperText={formik.touched.subtractTime && formik.errors.subtractTime}
						/>
					</Grid>
				</Grid>
				<ActionButtons style={{ marginTop: 16 }}>
					<Button onClick={onCancel}>Cerrar</Button>
				</ActionButtons>
			</form>
		</div>
	);
};
