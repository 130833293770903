import dayjs from 'dayjs';

import roles from 'common/constants/roles';
import assignmentStatus from 'common/constants/assignmentStatus';
import paymentStatus from 'common/constants/paymentStatus';

// eslint-disable-next-line
import { es } from 'dayjs/locale/es';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

const defaultTimeZone = 'America/Bogota';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('es');

class Tools {
	static getFullName = (userObject = {}) => {
		return `${userObject?.name || 'Nombre'} ${userObject?.lastName || 'Apellido'}`;
	};

	static getShortName = (userObject = {}) => {
		const { name, lastName } = userObject;
		const shortName = name ? name.split(' ') : '';
		const shortLastName = lastName ? lastName.split(' ') : '';

		return `${shortName[0] || 'Nombre'} ${shortLastName[0] || 'Apellido'}`;
	};

	static toFixed2 = (number) => Number(number).toFixed(2);

	static getValidationErrors = (error) => {
		return error?.response?.data.validation || null;
	};

	static getErrorMessage = (error) => {
		return error?.response?.data?.message || null;
	};

	static getDate = ({ date = new Date(), format = 'db' }) => {
		const formats = {
			db: 'YYYY-MM-DD',
			long: 'dddd[,] DD [de] MMMM',
			short: 'DD/MM/YYYY',
			dateTime: 'DD/MM/YYYY hh:mm a [(Col)]',
		};

		return dayjs(date).tz(defaultTimeZone).format(formats[format]);
	};

	static getRoleLabel = (role) => roles[role].label;

	static getRoles = () =>
		Object.values(roles).map(({ label, value }) => ({
			label,
			value,
		}));

	static handleCloseDialog = (_, reason, onClose) => {
		if (reason !== 'backdropClick') {
			onClose();
		}

		return false;
	};

	static getStatusLabel = (status) => {
		return assignmentStatus[status].label;
	};

	static getPaymentStatusLabel = (status) => {
		return paymentStatus[status].label;
	};

	static objectToQueryString = (object) =>
		Object.entries(object)
			.filter(([field, value]) => field && value)
			.map(([field, value]) => `${field}=${value.trim()}`)
			.join('&');

	static queryStringToObject = (query) => {
		if (!query) return {};

		const object = {};
		for (const parameter of query.split('&')) {
			const [label, value] = parameter.split('=');
			object[label] = value;
		}

		return object;
	};

	static getDangerousHtmlObject = (text = '') => ({
		__html: text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
	});

	static sortByField = (stack, field, order = 'ASC') => {
		const sortedArray = stack?.sort(function (a, b) {
			if (a[field] > b[field]) {
				return order === 'ASC' ? 1 : -1;
			}
			if (a[field] < b[field]) {
				return order === 'ASC' ? -1 : 1;
			}

			return 0;
		});

		return sortedArray;
	};

	static calculatePeriod = (period) => {
		const getTime = (date) => dayjs(date).add(5, 'hours');

		const today = dayjs();
		const currentDay = today.date();
		let from = null;
		let to = null;

		if (period === 'current') {
			if (currentDay >= 7 && currentDay <= 21) {
				from = this.getDate({ date: getTime(`${today.year()}-${today.month() + 1}-7`) });
				to = this.getDate({ date: getTime(`${today.year()}-${today.month() + 1}-21`) });
			} else {
				let startMonth = today.month() + 1;
				let endMonth = today.month() + 1;
				let startYear = today.year();
				let endYear = today.year();

				if (currentDay < 7) {
					startMonth = today.subtract(1, 'month').month() + 1;

					if (today.month() === 0) {
						startYear = startYear - 1;
					}
				} else {
					endMonth = today.add(1, 'month').month() + 1;

					if (today.month() === 11) {
						endYear = startYear + 1;
					}
				}

				from = this.getDate({ date: getTime(`${startYear}-${startMonth}-22`) });
				to = this.getDate({ date: getTime(`${endYear}-${endMonth}-06`) });
			}
		} else {
			let startMonth = today.subtract(1, 'month').month() + 1;
			let endMonth = today.month() + 1;
			let startYear = today.year();
			let endYear = today.year();

			if (today.month() === 0) {
				startYear = startYear - 1;
			}

			if (currentDay >= 7 && currentDay <= 21) {
				from = this.getDate({ date: getTime(`${startYear}-${startMonth}-22`) });
				to = this.getDate({ date: getTime(`${endYear}-${endMonth}-06`) });
			} else {
				if (currentDay < 7) {
					if (today.month() === 0) {
						endYear = endYear - 1;
					}
					endMonth = today.subtract(1, 'month').month() + 1;
				} else {
					if (today.month() === 0) {
						startYear = startYear + 1;
					}
					startMonth = today.month() + 1;
				}

				from = this.getDate({ date: getTime(`${startYear}-${startMonth}-07`) });
				to = this.getDate({ date: getTime(`${endYear}-${endMonth}-21`) });
			}
		}

		return { from, to };
	};

	static getOptionValue(options = [], optionKey) {
		const { value } = options.find(({ key }) => key === optionKey);

		return value;
	}

	static getCategoriesNames(categories) {
		return categories.map(({ name }) => name).join(', ');
	}

	static countWords(text = '') {
		return text.split(' ').filter((word) => word !== '').length;
	}

	static getFirstWords(string = '', n = 10) {
		return string
			.split(' ')
			.slice(0, n - 1)
			.join(' ');
	}

	static get30DaysPeriod() {
		const today = dayjs();
		const endDate = today.subtract(1, 'day').endOf('day');
		const startDate = endDate.subtract(1, 'month').add(1, 'day').endOf('day');

		const period = {
			startDate: Tools.getDate({ date: startDate }),
			endDate: Tools.getDate({ date: endDate }),
		};

		return period;
	}

	static updateById(stack, newItem) {
		return stack.map((item) => (item.id === newItem.id ? { ...item, ...newItem } : item));
	}

	static getFromNow = (date) => {
		const farDate = dayjs(date);
		const now = dayjs();

		const days = farDate.diff(now, 'days');
		const hours = now.startOf('day').diff(now, 'hours');

		const fromNow = `${days > 0 ? `${days} días,` : ''} ${hours > 0 ? `${hours} horas` : ''}`;

		return fromNow;
	};
}

export default Tools;
