import { GuestLayout, Spinner } from 'components';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';

import { AuthService } from 'services';
import Tools from 'utils/Tools';

import SignUpForm from './SignUpForm';

const SignUp = () => {
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const handleSubmit = (values, { setFieldError }) => {
		callService(AuthService.signUp(values))
			.then(({ data }) => {
				enqueueSnackbar(data.message, { variant: 'success' });
				history.push('/');
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};
	return (
		<GuestLayout
			title="Solicitud de registro"
			description="Envía una solicitud de registro como Redactor o Editor"
			extra={[<Link to="/">Iniciar sesión</Link>]}
		>
			<SignUpForm onSubmit={handleSubmit} />
			<Spinner isOpen={isProcessing} text="Enviando solicitud" />
		</GuestLayout>
	);
};

export default SignUp;
