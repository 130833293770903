import Http from './Http';

const endpoint = 'tasks';

const taskService = {
	getTasks() {
		return Http.get(endpoint);
	},

	createTask(data) {
		return Http.post(endpoint, data);
	},

	updateTask(id, data) {
		return Http.put(`${endpoint}/${id}`, data);
	},

	getMyTasks() {
		return Http.get(`${endpoint}/mine`);
	},

	// deleteTaskType(id) {
	// 	return Http.delete(`${endpoint}/${id}`);
	// },
};

export default taskService;
