import Http from './Http';

const endpoint = 'payments-extra';

const payments = {
	addExtra(data) {
		return Http.post(endpoint, data);
	},
};

export default payments;
