const errorMessages = {
	EMAIL_FORMAT_ERROR: 'Correo electrónico inválido',
	EMAIL_REQUIRED_ERROR: 'Indica tu correo electrónico',
	EMAIL_SOME_REQUIRED_ERROR: 'Indica un correo electrónico',
	PHONE_FORMAT_ERROR: 'El formato debe ser +57XXXXXXXXXX',
	PASSWORD_NOT_EQUAL_ERROR: 'Las contraseñas no son iguales',
	PASSWORD_CONFIRM_ERROR: 'Debes confirmar la contraseña',
};

export const {
	EMAIL_FORMAT_ERROR,
	EMAIL_REQUIRED_ERROR,
	EMAIL_SOME_REQUIRED_ERROR,
	PHONE_FORMAT_ERROR,
	PASSWORD_NOT_EQUAL_ERROR,
	PASSWORD_CONFIRM_ERROR,
} = errorMessages;

export default errorMessages;
