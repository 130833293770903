import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Dialog, DialogContent } from '@mui/material';

import { UserService, CategoryService, PostTypeService, ProjectService } from 'services';

import { CustomDialogTitle, Spinner } from 'components';
import ProjectForm from './ProjectForm';
import { useService } from 'hooks';
import Tools from 'utils/Tools';

const { getClients } = UserService;
const { getCategories } = CategoryService;
const { getPostTypes } = PostTypeService;
const { updateProject, createProject } = ProjectService;

const ProjectDialog = ({ onClose, project, onSuccess }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [clients, setClients] = useState([]);
	const [categories, setCategories] = useState([]);
	const [postTypes, setPostTypes] = useState([]);
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			Promise.all([getClients(), getCategories(), getPostTypes()])
				.then(([{ data: clientsData }, { data: categoriesData }, { data: postTypesData }]) => {
					setClients(clientsData);
					setCategories(categoriesData);
					setPostTypes(postTypesData);
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	}, [isLoading]);

	const handleSubmit = (values, { setFieldError }) => {
		callService(project ? updateProject(project.id, values) : createProject(values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				onClose();
				onSuccess();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	return (
		<Dialog className="ProjectDialog">
			<CustomDialogTitle>{project ? 'Editar' : 'Nuevo'} proyecto</CustomDialogTitle>
			<DialogContent>
				{isLoading && <Spinner small text="Cargando datos" />}
				{!isLoading && (
					<ProjectForm
						onCancel={onClose}
						clients={clients}
						categories={categories}
						postTypes={postTypes}
						onSubmit={handleSubmit}
						project={project}
					/>
				)}
			</DialogContent>
			<Spinner isOpen={isProcessing} text={`${project ? 'Guardando' : 'Creando'} proyecto`} />
		</Dialog>
	);
};

export default ProjectDialog;
