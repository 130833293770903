import { useHistory } from 'react-router-dom';

import useLoggedUser from './useLoggedUser';

export default function useAuth() {
	const { updateLoggedUser } = useLoggedUser();
	const history = useHistory();

	const onLogin = ({ token, user }) => {
		localStorage.setItem('token', token);
		updateLoggedUser(user);
	};

	const onLogout = () => {
		history.push('/');
		localStorage.removeItem('token');
		updateLoggedUser(null);
	};

	return { onLogin, onLogout };
}
