import { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Tab } from '@mui/material';
import { CheckRounded, ChevronLeft, DownloadRounded, SendRounded, Star } from '@mui/icons-material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';
import { AssignmentService } from 'services';

import {
	ActionButtons,
	AssignmentDetails,
	ConfirmDialog,
	Layout,
	PageTitle,
	QualificationSystem,
	Spinner,
} from 'components';
import { DropzoneArea } from 'react-mui-dropzone';
import Tools from 'utils/Tools';
import assignmentStatus from 'common/constants/assignmentStatus';

const { getAssignment, updateAssignment, uploadAssignment } = AssignmentService;

const tabPanelStyle = { style: { paddingLeft: 2, paddingRight: 2 } };

const Assignment = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [assignment, setAssignment] = useState(null);
	const [currentTab, setCurrentTab] = useState('1');
	const [showRatingForm, setShowRatingForm] = useState(false);
	const { assignmentId } = useParams();
	const { callService, isProcessing } = useService();
	const { enqueueSnackbar } = useSnackbar();
	const [showConfirmReject, setShowConfirmReject] = useState(false);

	useEffect(() => {
		if (isLoading) {
			getAssignment(assignmentId)
				.then(({ data }) => {
					setAssignment(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error) || 'Error', { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [isLoading, assignmentId, enqueueSnackbar]);

	const handleChangeTab = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const handleUpdateAssignment = (values) => {
		callService(updateAssignment(assignmentId, values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				setAssignment({ ...assignment, ...(data?.assignment || {}) });
			})
			.catch((error) => {
				console.log(`error`, error);
			});
	};

	const handleChangeStatus = (status) => {
		handleUpdateAssignment({ status });
	};

	const handleChangeFiles = (fileList) => {
		const file = fileList[0];

		if (file) {
			callService(uploadAssignment(assignmentId, { file }))
				.then(({ data }) => {
					enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
					setAssignment({ ...assignment, ...(data?.assignment || {}) });
				})
				.catch((error) => {
					console.log(`error`, error);
				});
		}
	};

	const handleRejectAssignment = () => {
		handleChangeStatus(assignmentStatus.REJECTED_BY_WRITER.value);
		setShowConfirmReject(false);
	};

	return (
		<Layout>
			<PageTitle
				title={isLoading ? 'Espere...' : assignment?.title || 'Error'}
				actionButtons={[
					<Link to="/mis-asignaciones">
						<Button startIcon={<ChevronLeft />}>Asignaciones</Button>
					</Link>,
				]}
			/>

			{isLoading && <Spinner small text="Cargando asignación" />}
			{!isLoading && assignment && <AssignmentDetails assignment={assignment} />}
			{assignment?.status === 'RETURNED' && (
				<Alert variant="filled" severity="warning" sx={{ fontSize: '1.1em' }}>
					Esta asignación ha sido DEVUELTA. Por favor, descarga el archivo, haz las correcciones
					necesarias, sube el archivo actualizado y presiona REENVIAR.
				</Alert>
			)}
			{assignment?.status === 'ACCEPTED' && assignment?.docUrl && (
				<Alert variant="filled" severity="info" sx={{ fontSize: '1.1em', mb: 2 }}>
					¡Muy bien! Ya has subido un archivo. Recuerda hacer click en ENVIAR ARTÍCULO.
				</Alert>
			)}
			{['APPROVED', 'COMPLETED'].includes(assignment?.status) && (
				<Alert variant="filled" severity="success" sx={{ fontSize: '1.1em', mb: 2 }}>
					¡Buen trabajo! La asignación ha sido APROBADA y enviada a los administradores.
				</Alert>
			)}
			{assignment?.status === 'REJECTED_BY_EDITOR' && (
				<Alert variant="filled" severity="error" sx={{ fontSize: '1.1em', mb: 2 }}>
					Esta asignación ha sido DEVUELTA por segunda vez. No tienes permiso para subir nuevas
					versiones.
				</Alert>
			)}
			{assignment && ['ACCEPTED', 'RETURNED'].includes(assignment?.status) && (
				<TabContext value={currentTab}>
					<TabList
						onChange={handleChangeTab}
						aria-label="lab API tabs example"
						sx={{ borderBottom: '1px solid #ddd' }}
					>
						<Tab label="Cargar desde ordenador" value="1" />
					</TabList>
					<TabPanel value="1" {...tabPanelStyle}>
						<DropzoneArea
							onChange={handleChangeFiles}
							acceptedFiles={[
								'application/msword',
								'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
							]}
							maxFileSize={5000000}
							filesLimit={1}
							getFileLimitExceedMessage={() => 'Solo se admite un archivo a la vez'}
							dropzoneText="Arrastra un documento o haz click"
							getFileAddedMessage={(fileName) => `"${fileName}" ha sido añadido`}
							getFileRemovedMessage={(fileName) => `Se ha removido "${fileName}"`}
							getDropRejectMessage={() => 'Solo archivos: .doc y .docx. Máximo 5MB'}
							showPreviews={false}
							showPreviewsInDropzone={false}
						/>
					</TabPanel>
				</TabContext>
			)}
			<ActionButtons>
				{assignment?.status === 'PENDING' && (
					<Button
						color="success"
						variant="contained"
						startIcon={<CheckRounded />}
						onClick={() => handleChangeStatus('ACCEPTED')}
					>
						Aceptar asignación
					</Button>
				)}
				{assignment?.docUrl && ['ACCEPTED'].includes(assignment?.status) && (
					<Button
						color="success"
						variant="contained"
						startIcon={<SendRounded />}
						onClick={() => handleChangeStatus('REVISION')}
					>
						Enviar artículo
					</Button>
				)}
				{assignment?.docUrl && ['RETURNED'].includes(assignment?.status) && (
					<Button
						color="success"
						variant="contained"
						startIcon={<SendRounded />}
						onClick={() => handleChangeStatus('SECOND_REVISION')}
					>
						Reenviar artículo
					</Button>
				)}
				{!!assignment?.isRated && (
					<Button
						color="success"
						variant="contained"
						startIcon={<Star />}
						onClick={() => setShowRatingForm(true)}
					>
						Ver calificación
					</Button>
				)}
				{assignment?.docUrl && (
					<Button
						color="info"
						variant="contained"
						startIcon={<DownloadRounded />}
						href={assignment?.docUrl}
					>
						Descargar archivo
					</Button>
				)}
				{assignment?.status === 'PENDING' && (
					<Button onClick={() => setShowConfirmReject(true)} variant="outlined" color="error">
						Rechazar asignación
					</Button>
				)}
			</ActionButtons>
			{showConfirmReject && (
				<ConfirmDialog
					onCancel={() => setShowConfirmReject(false)}
					onOk={handleRejectAssignment}
					question="¿Seguro que deseas rechazar esta asignación?"
					okButtonText="Sí, rechazar"
					okButtonType="error"
				>
					Una vez rechazada, no podrás tomar esta asignación nuevamente.
				</ConfirmDialog>
			)}
			{showRatingForm && (
				<QualificationSystem
					onCancel={() => setShowRatingForm(false)}
					assignment={assignment}
					open={showRatingForm}
				/>
			)}
			<Spinner isOpen={isProcessing} text="Actualizando asignación" />
		</Layout>
	);
};

export default Assignment;
