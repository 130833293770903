import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAuth, useService } from 'hooks';

import { AuthService } from 'services';
import Tools from 'utils/Tools';

import { GuestLayout, Spinner } from 'components';
import SignInForm from './SignInForm';

const { getErrorMessage, getValidationErrors } = Tools;

const SignIn = () => {
	document.title = `Iniciar sesión | Redactores Alfa`;
	const { onLogin } = useAuth();
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = (values, { setFieldError }) => {
		callService(AuthService.signIn(values))
			.then(({ data }) => {
				onLogin(data);
			})
			.catch((error) => {
				const validationErrors = getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Credenciales inválidas', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}

				const errorMessage = getErrorMessage(error);
				if (errorMessage) {
					enqueueSnackbar(errorMessage, { variant: 'error' });
				}
			});
	};

	return (
		<GuestLayout
			title="Iniciar sesión"
			description="Ingresa al sistema con tu email y contraseña"
			extra={[<Link to="/registro">Solicitud de Registro</Link>]}
		>
			<SignInForm onFinish={handleSubmit} />
			<Spinner isOpen={isProcessing} text="Ingresando al sistema" />
		</GuestLayout>
	);
};

export default SignIn;
