import Http from './Http';

const endpoint = 'category-rates';

const rates = {
	getCategoryRates(categoryId) {
		return Http.get(`${endpoint}/${categoryId}`);
	},

	getWriterCategoryRates() {
		return Http.get(endpoint);
	},

	updateWriterCategoryRates(data) {
		return Http.put(endpoint, data);
	},
};

export default rates;
