import { useFormik } from 'formik';
import { Button, TextField } from '@mui/material';

import Tools from 'utils/Tools';
import validationSchemas from 'utils/validationSchemas';

import { ActionButtons, CustomSwitch, LabeledSlider } from 'components';

const PostTypeForm = ({ postType, onSubmit, onCancel, rates, initialRates }) => {
	const formik = useFormik({
		initialValues: {
			name: postType?.name || '',
			isEnabled: !!postType?.isEnabled,
			rates: initialRates,
		},
		validationSchema: validationSchemas.postType,
		onSubmit,
	});

	return (
		<div className="PostTypeForm">
			<form onSubmit={formik.handleSubmit} className="PostTypeForm-form">
				<TextField
					fullWidth
					id="name"
					name="name"
					label="Tipo de Publicación"
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					margin="normal"
				/>
				<CustomSwitch initialValue={!!postType?.isEnabled} formik={formik} />

				<div className="PostTypeForm-form-rates">
					{rates.map((rate) => (
						<div key={rate.userId} className="PostTypeForm-form-rates-item">
							<LabeledSlider
								key={rate.userId}
								name={rate.userId}
								label={Tools.getFullName(rate)}
								initialValue={rate.rate}
								formik={formik}
							/>
						</div>
					))}
				</div>

				<ActionButtons style={{ marginTop: 16 }}>
					<Button onClick={onCancel}>Cancelar</Button>
					<Button variant="contained" type="submit">
						{postType ? 'Guardar' : 'Agregar tipo de publicación'}
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default PostTypeForm;
