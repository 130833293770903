import { Dialog, DialogContent } from '@mui/material';

import { useSnackbar } from 'notistack';

import { useService } from 'hooks';
import { UserService } from 'services';

import Tools from 'utils/Tools';
import { CustomDialogTitle, Spinner } from 'components';

import roles from 'common/constants/roles';
import AddUserForm from './AddUserForm';

const AddUserDialog = ({ onClose, userRole = roles.WRITER.value, onSuccess }) => {
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();

	const handleSubmit = (values, { setFieldError }) => {
		callService(UserService.create(values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Listo', { variant: data.messageType });
				onSuccess();
				onClose();
			})
			.catch((error) => {
				const validationErrors = Tools.getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifique los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}
			});
	};

	return (
		<Dialog
			onClose={(_, reason) => {
				Tools.handleCloseDialog(_, reason, onClose);
			}}
		>
			<CustomDialogTitle>Nuevo {roles[userRole].label}</CustomDialogTitle>
			<DialogContent>
				<AddUserForm onCancel={onClose} onSubmit={handleSubmit} userRole={userRole} />
			</DialogContent>
			<Spinner isOpen={isProcessing} text="Agregando usuario..." />
		</Dialog>
	);
};

export default AddUserDialog;
