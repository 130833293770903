import Http from './Http';

const endpoint = 'qualification';

const QualificationService = {
	getQualificationCriteria() {
		return Http.get(`${endpoint}/criteria`);
	},

	createQualificationCriteria(payload) {
		return Http.post(`${endpoint}/criteria`, payload);
	},

	updateQualificationCriteria(criteriaId, payload) {
		return Http.put(`${endpoint}/criteria/${criteriaId}`, payload);
	},

	addQualification(assignmentId, payload) {
		return Http.post(`${endpoint}/${assignmentId}`, payload);
	},

	getAssignmentQualification(assignmentId) {
		return Http.get(`${endpoint}/list/${assignmentId}`);
	},

	updateQualification(qualificationId, payload) {
		return Http.put(`${endpoint}/${qualificationId}`, payload);
	},
};

export default QualificationService;
