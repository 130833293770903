const config = {
	api:
		process.env.NODE_ENV === 'development'
			? process.env.REACT_APP_API
			: process.env.REACT_APP_API_PROD,
};

export const { api } = config;

export default config;
