import { useState } from 'react';
import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import { useSnackbar } from 'notistack';

import paymentStatus from 'common/constants/paymentStatus';
import roles from 'common/constants/roles';

import Tools from 'utils/Tools';
import { ClientSelect, ProjectsSelect } from 'components';

const { calculatePeriod } = Tools;

const PaymentsFilters = ({ onSearch, paymentMethods }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [, setRange] = useState([null, null]);
	const [searchType, setSearchType] = useState('period');

	const handleSubmit = (values) => {
		const {
			paymentMethodId,
			period,
			role,
			status,
			range: dates,
			searchType: type,
			clientId,
			projectId,
		} = values;

		let fromToDates = calculatePeriod(period);

		if (type === 'range') {
			if (!dates[0] || !dates[1]) {
				enqueueSnackbar('Debe elegir ambas fechas', { variant: 'error' });
				return false;
			}

			fromToDates = {
				from: dayjs(dates[0]).add(5, 'hours').format('YYYY-MM-DD'),
				to: dayjs(dates[1]).add(5, 'hours').format('YYYY-MM-DD'),
			};
		}

		const payload = {
			projectId,
			clientId: clientId || 'all',
			searchType,
			period,
			...fromToDates,
			role,
			paymentMethodId,
			status,
		};

		onSearch(payload);
		return true;
	};

	const formik = useFormik({
		initialValues: {
			paymentMethodId: 'all',
			period: 'last',
			range: [null, null],
			role: 'all',
			searchType: 'period',
			status: 'all',
			clientId: '',
			projectId: 'all',
		},
		onSubmit: handleSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={4} lg={2}>
					<FormControl fullWidth>
						<InputLabel id="searchType-label">Buscar por</InputLabel>
						<Select
							labelId="searchType-label"
							id="searchType"
							label="Buscar por"
							name="searchType"
							onChange={(e) => {
								formik.values.searchType = e.target.value;
								setSearchType(e.target.value);
							}}
							value={formik.values.searchType}
							error={formik.touched.searchType && Boolean(formik.errors.searchType)}
						>
							<MenuItem value="period">Quincena</MenuItem>
							<MenuItem value="range">Rango de fechas</MenuItem>
						</Select>
						<FormHelperText error>
							{formik.touched.searchType && formik.errors.searchType}
						</FormHelperText>
					</FormControl>
				</Grid>
				{searchType === 'period' && (
					<Grid item xs={12} sm={4} lg={2}>
						<FormControl fullWidth>
							<InputLabel id="period-label">Quincena</InputLabel>
							<Select
								labelId="period-label"
								id="period"
								label="Quincena"
								name="period"
								onChange={formik.handleChange}
								value={formik.values.period}
								error={formik.touched.period && Boolean(formik.errors.period)}
							>
								<MenuItem value="current">Actual</MenuItem>
								<MenuItem value="last">Anterior</MenuItem>
							</Select>
							<FormHelperText error>{formik.touched.period && formik.errors.period}</FormHelperText>
						</FormControl>
					</Grid>
				)}
				{searchType === 'range' && (
					<Grid item xs={12} sm={4} lg={2}>
						<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
							<DateRangePicker
								disableFuture
								labelId="range-label"
								name="range"
								value={formik.values.range}
								onChange={(values) => {
									setRange(values);
									formik.values.range = values;
								}}
								error={formik.touched.range && Boolean(formik.errors.range)}
								renderInput={(startProps, endProps) => (
									<Stack spacing={1} direction="row" sx={{ width: '100%' }}>
										<TextField {...startProps} label="Desde" sx={{ flex: 1 }} />
										<TextField {...endProps} label="Hasta" sx={{ flex: 1 }} />
									</Stack>
								)}
							/>
						</LocalizationProvider>
					</Grid>
				)}
				<Grid item xs={12} sm={4} lg={2}>
					<FormControl fullWidth>
						<InputLabel id="userId-label">Método de pago</InputLabel>
						<Select
							labelId="paymentMethodId-label"
							id="paymentMethodId"
							label="Método de pago"
							name="paymentMethodId"
							onChange={formik.handleChange}
							value={formik.values.paymentMethodId}
							error={formik.touched.paymentMethodId && Boolean(formik.errors.paymentMethodId)}
						>
							<MenuItem value="all">Todos</MenuItem>
							{paymentMethods.map(({ id, name }) => {
								return (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								);
							})}
						</Select>
						<FormHelperText error>
							{formik.touched.paymentMethodId && formik.errors.paymentMethodId}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid key="roleField" item xs={12} sm={4} lg={2}>
					<FormControl fullWidth>
						<InputLabel id="role-label">Rol</InputLabel>
						<Select
							labelId="role-label"
							id="role"
							label="Rol"
							name="role"
							onChange={formik.handleChange}
							value={formik.values.role}
							error={formik.touched.role && Boolean(formik.errors.role)}
						>
							<MenuItem value="all">Todos</MenuItem>
							<MenuItem value={roles.WRITER.value}>Redactor</MenuItem>
							<MenuItem value={roles.EDITOR.value}>Editor</MenuItem>
						</Select>
						<FormHelperText error>{formik.touched.role && formik.errors.role}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid key="statusField" item xs={12} sm={4} lg={2}>
					<FormControl fullWidth>
						<InputLabel id="status-label">Estatus</InputLabel>
						<Select
							labelId="status-label"
							id="status"
							label="Estatus"
							name="status"
							onChange={formik.handleChange}
							value={formik.values.status}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<MenuItem value="all">Todos</MenuItem>
							{Object.values(paymentStatus).map(({ label, value }) => (
								<MenuItem value={value} key={value}>
									{label}
								</MenuItem>
							))}
						</Select>
						<FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid key="clientsField" item xs={12} sm={4} lg={2}>
					<ClientSelect formik={formik} />
				</Grid>
				<Grid key="projectsField" item xs={12} sm={4} lg={2}>
					<ProjectsSelect formik={formik} />
				</Grid>
				<Grid key="submit" item xs={12} sm={4} lg={2}>
					<Button fullWidth variant="outlined" type="submit">
						Buscar
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default PaymentsFilters;
