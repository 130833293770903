import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

import { OptionService, PaymentService } from 'services';

import { Layout, NoData, PageTitle, PaymentsFilters, Spinner } from 'components';
import Tools from 'utils/Tools';
import { useEffect } from 'react';

const { getOptions } = OptionService;
const { getPaymentsByUser } = PaymentService;
const { getDate, getPaymentStatusLabel, objectToQueryString, toFixed2 } = Tools;

const Payments = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [payments, setPayments] = useState(null);
	const [options, setOptions] = useState(null);

	useEffect(() => {
		getOptions()
			.then(({ data }) => {
				setOptions(data);
			})
			.catch(console.log);
	}, []);

	const handleSearch = (dates) => {
		const payload = { ...dates, idType: 'editorId' };
		setIsLoading(true);
		const query = objectToQueryString(payload);
		getPaymentsByUser(query)
			.then(({ data }) => {
				setPayments(data.payments);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(`error`, error);
				setIsLoading(false);
			});
	};

	let editorFee = 0;
	if (options) {
		editorFee = options.find(({ key }) => key === 'EDITOR_FEE')?.value || 0;
	}

	return (
		<Layout>
			<PageTitle title="Mi facturación" />
			<PaymentsFilters onSearch={handleSearch} />
			{isLoading && <Spinner small text="Cargando facturación" isOpen />}
			{payments && !!payments.length && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell size="medium">Estatus</TableCell>
								<TableCell>Fecha de envío</TableCell>
								<TableCell>Título</TableCell>
								<TableCell>Calidad</TableCell>
								<TableCell>Fluidez</TableCell>
								<TableCell>Precio x 1000</TableCell>
								<TableCell>Palabras</TableCell>
								<TableCell>Pago</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{payments.map((payment) => {
								const { totalWords } = payment;

								return (
									<TableRow key={payment.id}>
										<TableCell size="medium">{getPaymentStatusLabel(payment.status)}</TableCell>
										<TableCell>{getDate({ date: payment.sentAt, format: 'dateTime' })}</TableCell>
										<TableCell>
											<Link to={`/mis-asignaciones/${payment.assignmentId}`}>{payment.title}</Link>
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{payment.isRated ? payment.quality : 'N/A'}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{payment.isRated ? payment.fluency : 'N/A'}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>${toFixed2(editorFee)}</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>{totalWords}</TableCell>
										<TableCell sx={{ textAlign: 'right' }}>${toFixed2(payment.amount)}</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell colSpan={7}>
									<Typography variant="h5" textAlign="right">
										Total
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="h5" textAlign="right">
										$
										{toFixed2(
											payments.reduce((accumulator, { amount }) => accumulator + amount, 0)
										)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{payments && !payments.length && <NoData message="No hay pagos registrados" />}
		</Layout>
	);
};

export default Payments;
