import { CreditScore, DraftsOutlined, EmailOutlined, Payment } from '@mui/icons-material';
import {
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
	Badge,
} from '@mui/material';
import { Box } from '@mui/system';
import roles from 'common/constants/roles';
import { ConfirmDialog, Layout, NoData, PageTitle, Spinner } from 'components';
import CanI from 'components/CanI';
import { useService } from 'hooks';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PaymentMethodService, PaymentService, OptionService } from 'services';
import Tools from 'utils/Tools';
import CustomCheckButton from './CustomCheckButton';
import DollarRatesForm from './DollarRatesForm';
import NotificationModal from './NotificationModal';
import PaymentsFilters from './PaymentsFilters';

const { getPayments, getPaymentsByUser, processPayments, postponePayments } = PaymentService;
const { getPaymentMethods } = PaymentMethodService;
const { calculatePeriod, objectToQueryString, toFixed2, getShortName, getOptionValue } = Tools;
const { getOptions, updateOptions } = OptionService;

// const limit = 15;

const PaymentsList = () => {
	const [selectedNotification, setSelectedNotification] = useState(null);
	const [showPostponeConfirm, setShowPostponeConfirm] = useState(false);
	const { isProcessing: isSavingRates, callService: saveRates } = useService();
	const { isProcessing: isPostponing, callService: makeRequest } = useService();
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(true);
	const [payments, setPayments] = useState(null);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [selectedPayments, setSelectedPayments] = useState(null);
	const [checkedPayments, setCheckedPayments] = useState([]);
	const [options, setOptions] = useState(null);
	const [filters, setFilters] = useState({
		...calculatePeriod('last'),
		period: 'last',
		paymentMethodId: 'all',
		role: 'all',
		status: 'all',
		searchType: 'period',
	});

	useEffect(() => {
		getPaymentMethods()
			.then(({ data }) => {
				setPaymentMethods(data);
			})
			.catch(console.log);
	}, []);

	useEffect(() => {
		getOptions()
			.then(({ data }) => {
				setOptions(data);
			})
			.catch(console.log);
	}, []);

	useEffect(() => {
		if (isLoading) {
			const query = objectToQueryString(filters);

			getPayments(query)
				.then(({ data }) => {
					setPayments(data);
				})
				.catch(console.log)
				.finally(() => setIsLoading(false));
		}
	}, [isLoading, filters]);

	const handleSearch = (values) => {
		setFilters(values);
		setIsLoading(true);
	};

	const handleSubmitRates = (values) => {
		saveRates(updateOptions(values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				setOptions(data.options.options);
			})
			.catch((error) => {
				console.log(`error`, error);
			});
	};

	const handleProcessPayments = (userId, role) => {
		setSelectedPayments(userId);

		const payload = {
			...calculatePeriod(filters.period),
			userId,
			idType: role === 'WRITER' ? 'writerId' : 'editorId',
		};

		getPaymentsByUser(objectToQueryString(payload))
			.then(({ data }) => {
				const { payments, extra: paymentsExtra } = data;
				return processPayments({ payments, paymentsExtra });
			})
			.then(() => {
				const query = objectToQueryString(filters);

				return getPayments(query);
			})
			.then(({ data }) => {
				setPayments(data);
			})
			.catch(console.log)
			.finally(() => setSelectedPayments(null));
	};

	const handleSelectPayment = (payment) => {
		const checkedPayment = checkedPayments.find(({ userId }) => userId === payment.userId);

		if (checkedPayment)
			setCheckedPayments((state) => state.filter(({ userId }) => payment.userId !== userId));
		else setCheckedPayments((state) => [...state, payment]);
	};

	const handlePostponePayments = () => {
		const usersIds = (payments || [])
			.filter(({ pendingPayments }) => !!pendingPayments)
			.map(({ userId }) => userId);

		if (!!usersIds.length) {
			makeRequest(postponePayments(usersIds, filters.from, filters.to)).then(({ data }) => {
				const { message } = data;
				enqueueSnackbar(message, { variant: 'success' });
				setIsLoading(true);
			});
		} else enqueueSnackbar('No hay pagos pendientes', { variant: 'info' });
	};

	const handleNotification = (notification) => setSelectedNotification(notification);

	const handleResolveNotification = (senderId) => {
		setPayments((state) =>
			state.map((payment) =>
				payment.userId === senderId ? { ...payment, notification: null } : payment
			)
		);
		setSelectedNotification(null);
	};

	const handleMarkNotificationAsRead = (senderId) => {
		setPayments((state) =>
			state.map((payment) =>
				payment.userId === senderId
					? { ...payment, notification: { ...payment.notification, readByAdmin: true } }
					: payment
			)
		);
	};

	let pendingTotal = 0;
	let processedTotal = 0;
	let checkedTotal = 0;

	if (payments) {
		pendingTotal = payments.reduce(
			(total, { amount, pendingExtras, pendingPayments }) =>
				pendingExtras || pendingPayments ? total + amount : total + 0,
			0
		);

		processedTotal = payments.reduce(
			(total, { amount, pendingExtras, pendingPayments }) =>
				pendingExtras || pendingPayments ? total + 0 : total + amount,
			0
		);

		if (checkedPayments.length)
			checkedTotal = checkedPayments.reduce((total, { amount }) => total + amount, 0);
		else checkedTotal = 0;
	}

	return (
		<Layout>
			<PageTitle title="Facturación" />
			<Grid container spacing={2}>
				<Grid item md={6}>
					{options && (
						<DollarRatesForm
							options={options}
							onFinish={handleSubmitRates}
							isSavingRates={isSavingRates}
						/>
					)}
				</Grid>
				<Grid item md={6} display="flex" justifyContent="flex-end">
					<CanI make="PAYMENT_POSTPONE">
						<Button
							variant="outlined"
							disabled={
								isPostponing ||
								filters.searchType === 'range' ||
								(filters.period === 'current' && filters.searchType === 'period')
							}
							onClick={() => setShowPostponeConfirm(true)}
						>
							Posponer Pagos pendientes
						</Button>
					</CanI>
				</Grid>
			</Grid>
			<Divider sx={{ my: 2 }} />
			<PaymentsFilters paymentMethods={paymentMethods} onSearch={handleSearch} />
			{isLoading && <Spinner small text="Cargando lista de pagos" />}
			{!isLoading && (
				<>
					<Typography sx={{ mt: 2 }}>
						Registros: <strong>{payments?.length}</strong>
					</Typography>
					<TableContainer component={Box}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Usuario</TableCell>
									<TableCell>Rol</TableCell>
									<TableCell>Método de pago</TableCell>
									<TableCell>Datos de pago</TableCell>
									<TableCell sx={{ textAlign: 'right' }}>Monto (USD)</TableCell>
									<TableCell sx={{ textAlign: 'right' }}>Pesos</TableCell>
									<TableCell>Estatus</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>Procesar</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{payments?.map((payment) => {
									const {
										userId,
										role,
										paymentMethod,
										paymentInfo,
										amount,
										pendingPayments,
										pendingExtras,
										notification,
									} = payment;

									const queryParams = {
										idType: role === roles.WRITER.value ? 'writerId' : 'editorId',
										userId,
										searchType: filters.searchType,
										period: filters.period,
									};

									if (filters.searchType === 'range') {
										queryParams.from = filters.from;
										queryParams.to = filters.to;
										delete queryParams.period;
									}

									const queryString = objectToQueryString(queryParams);

									const paymentIsPending = pendingExtras || pendingPayments;

									const isChecked = !!checkedPayments.find(
										({ userId: paymentUserId }) => userId === paymentUserId
									);

									return (
										<TableRow key={userId}>
											<TableCell>
												<Link target="_blank" to={`/facturacion-detalle?${queryString}`}>
													{getShortName(payment)}
												</Link>
											</TableCell>
											<TableCell>{roles[role].label}</TableCell>
											<TableCell>{paymentMethod || '(No registrado)'}</TableCell>
											<TableCell>{paymentInfo || '-'}</TableCell>
											<TableCell sx={{ textAlign: 'right' }}>
												${amount ? toFixed2(amount) : 0}
											</TableCell>
											<TableCell sx={{ textAlign: 'right' }}>
												{toFixed2(amount * getOptionValue(options, 'USD_COP'))}
											</TableCell>
											<TableCell>{paymentIsPending ? 'PAGOS PENDIENTES' : 'PROCESADO'}</TableCell>
											<TableCell align="right">
												<Stack direction="row" justifyContent="flex-end">
													<CanI make="PAYMENT_PROCESS">
														<IconButton
															size="small"
															onClick={() => handleProcessPayments(userId, role)}
															disabled={
																!paymentIsPending ||
																selectedPayments === userId ||
																filters.period === 'current'
															}
														>
															{paymentIsPending ? <Payment /> : <CreditScore />}{' '}
															{selectedPayments === userId && (
																<CircularProgress style={{ height: 20, width: 20 }} />
															)}
														</IconButton>
													</CanI>

													<IconButton
														size="small"
														disabled={!notification}
														style={{ marginLeft: 24 }}
														onClick={() =>
															handleNotification({
																...notification,
																senderName: getShortName(payment),
															})
														}
													>
														{notification ? (
															<>
																{notification.readByAdmin ? (
																	<DraftsOutlined />
																) : (
																	<Badge color="error" variant="dot">
																		<EmailOutlined />
																	</Badge>
																)}
															</>
														) : (
															<EmailOutlined />
														)}
													</IconButton>
													<CustomCheckButton
														isChecked={isChecked}
														onClick={() => handleSelectPayment(payment)}
													/>
												</Stack>
											</TableCell>
										</TableRow>
									);
								})}
								<TableRow>
									<TableCell colSpan={7}>
										<Typography variant="h5" textAlign="right">
											Pendientes estimado:
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h5" textAlign="right">
											${toFixed2(pendingTotal)}
										</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={7}>
										<Typography variant="h5" textAlign="right">
											Total Procesado:
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h5" textAlign="right">
											${toFixed2(processedTotal)}
										</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={7}>
										<Typography variant="h4" textAlign="right">
											Total Facturación:
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h4" textAlign="right">
											${toFixed2(pendingTotal + processedTotal)}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
							<TableFooter style={{ height: 75 }}>
								<TableRow>
									<TableCell colSpan={7}>
										<Typography variant="h5" textAlign="right" color="gray">
											Total seleccionados:
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h4" textAlign="right" color="gray">
											${toFixed2(checkedTotal)}
										</Typography>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</>
			)}
			{!!showPostponeConfirm && (
				<ConfirmDialog
					onCancel={() => setShowPostponeConfirm(false)}
					onOk={() => {
						setShowPostponeConfirm(false);
						handlePostponePayments();
					}}
					question="¿Seguro que desea posponer TODOS los pagos pendientes?"
					okButtonText="Sí, Posponer todos"
					okButtonType="error"
				>
					Esta acción es irreversible. Serán pospuestos todos los pagos pendientes de los usuarios
					que tengan pagos por procesar en esta quincena/período.
				</ConfirmDialog>
			)}
			{!payments?.length && !isLoading && <NoData message="No hay pagos registrados" />}
			{selectedNotification && (
				<NotificationModal
					onMarkAsRead={handleMarkNotificationAsRead}
					onClose={() => setSelectedNotification(false)}
					notification={selectedNotification}
					onResolve={handleResolveNotification}
				/>
			)}
		</Layout>
	);
};

export default PaymentsList;
