import { Button, Chip, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';

import CustomDialogTitle from 'components/CustomDialogTitle';
import Tools from 'utils/Tools';

import UserNotificationForm from './UserNotificationForm';

const UserNotificationModal = ({
	isProcessing,
	notification,
	onClose,
	onResolve,
	onSentNotification,
	open,
}) => {
	const { message, readByAdmin, closedByAdmin } = notification || {};

	return (
		<Dialog onClose={onClose} open={open}>
			<CustomDialogTitle>Notificación al administrador</CustomDialogTitle>
			<DialogContent>
				{notification && <div dangerouslySetInnerHTML={Tools.getDangerousHtmlObject(message)} />}
				{!notification && <UserNotificationForm onSubmit={onSentNotification} />}

				<Stack mt={2} justifyContent="flex-start" direction="row" spacing={1}>
					{notification && (
						<Chip
							label={readByAdmin ? 'Leído' : 'No leído'}
							color={readByAdmin ? 'success' : 'default'}
						/>
					)}
					{readByAdmin && (
						<Chip
							label={closedByAdmin ? 'Atendido' : 'Por atender'}
							color={closedByAdmin ? 'success' : 'default'}
						/>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cerrar</Button>
				{closedByAdmin && (
					<Button color="primary" variant="contained" onClick={onResolve} disabled={isProcessing}>
						Marcar como resuelto
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default UserNotificationModal;
