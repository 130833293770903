import Http from './Http';

const endpoint = 'auth';

const auth = {
	signIn(data) {
		return Http.post(`${endpoint}/signIn`, data);
	},

	signUp(data) {
		return Http.post(`${endpoint}/signUp`, data);
	},

	check() {
		return Http.get(`${endpoint}/check`);
	},

	recoverPassword(email) {
		return Http.post(`${endpoint}/recover-password`, email);
	},

	resetPassword(payload) {
		return Http.put(`${endpoint}/password-reset`, payload);
	},
};

export default auth;
