import { useEffect, useState } from 'react';

import { UserService } from 'services';

import { useSnackbar } from 'notistack';

import Tools from 'utils/Tools';

import { AddButton, Layout, NoData, PageTitle, Spinner, StatusChip, UserFilters } from 'components';
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';
import { EditRounded, SecurityRounded } from '@mui/icons-material';

import roles from 'common/constants/roles';
import EditUserDialog from '../EditUserDialog';
import AddUserDialog from '../AddUserDialog';
import CanI from 'components/CanI';
import { UserPermissionsDialog } from '../UserPermissionsDialog';

const { getAdministrators } = UserService;

const Administrators = () => {
	const [administrators, setAdministrators] = useState([]);
	const [selectedAdmin, setSelectedAdmin] = useState(null);
	const [selectedPermissions, setSelectedPermissions] = useState(null);
	const [showAddForm, setShowAddForm] = useState(false);
	const [filters, setFilters] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	const handleCloseEditDialog = () => {
		setSelectedAdmin(null);
	};

	useEffect(() => {
		if (isLoading) {
			getAdministrators(filters)
				.then(({ data }) => {
					setAdministrators(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [enqueueSnackbar, filters, isLoading]);

	const handleFilters = (queryString) => {
		setFilters(queryString);
		setIsLoading(true);
	};

	return (
		<Layout>
			<PageTitle
				title="Administradores"
				actionButtons={[
					<CanI make="ADMIN_ADD">
						<AddButton onClick={() => setShowAddForm(true)} />
					</CanI>,
				]}
			/>
			<UserFilters onSearch={handleFilters} />
			{isLoading && <Spinner text="Cargando Administradores" isOpen small />}
			{!isLoading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Apellidos</TableCell>
								<TableCell>Nombres</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Teléfono</TableCell>
								<TableCell>Rol</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{administrators.map((administrator) => {
								const { id, lastName, name, email, phone, isEnabled, role } = administrator;
								const isAssistant = role === roles.ASSISTANT.value;

								return (
									<TableRow key={id}>
										<TableCell>{lastName}</TableCell>
										<TableCell>{name}</TableCell>
										<TableCell>{email}</TableCell>
										<TableCell>{phone || '(No disponible)'}</TableCell>
										<TableCell>{roles[role].label}</TableCell>
										<TableCell>
											<StatusChip isEnabled={isEnabled} />
										</TableCell>
										<TableCell>
											<CanI make="ADMIN_EDIT">
												<IconButton size="small" onClick={() => setSelectedAdmin(administrator)}>
													<EditRounded />
												</IconButton>
											</CanI>
											{isAssistant && (
												<CanI make="ADMIN_EDIT">
													<IconButton
														size="small"
														onClick={() => setSelectedPermissions(administrator)}
													>
														<SecurityRounded />
													</IconButton>
												</CanI>
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{!administrators.length && !isLoading && <NoData message="No hay editores" />}

			{showAddForm && (
				<AddUserDialog
					userRole="ADMIN"
					onClose={() => setShowAddForm(false)}
					onSuccess={() => setIsLoading(true)}
				/>
			)}
			{selectedAdmin && (
				<EditUserDialog
					onSuccess={() => setIsLoading(true)}
					user={selectedAdmin}
					onClose={handleCloseEditDialog}
				/>
			)}
			{selectedPermissions && (
				<UserPermissionsDialog
					user={selectedPermissions}
					onClose={() => setSelectedPermissions(null)}
					onSuccess={() => setIsLoading(true)}
				/>
			)}
		</Layout>
	);
};

export default Administrators;
