import { useFormik } from 'formik';
import {
	Button,
	Checkbox,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';

import NumericInput from 'material-ui-numeric-input';

import validationSchemas from 'utils/validationSchemas';
import Tools from 'utils/Tools';

import { ActionButtons, CustomDatePicker, CustomSwitch, TitledBox } from 'components';
import CanI from 'components/CanI';

const ProjectForm = ({ clients, categories, postTypes, project, onSubmit, onCancel }) => {
	const handleSubmit = (values, form) =>
		onSubmit(
			{
				...values,
				categoryId: values?.categoryId.join(','),
			},
			form
		);

	const formik = useFormik({
		initialValues: {
			amount: project?.amount || 0,
			categoryId: project?.categoryId ? project?.categoryId.split(',') : [],
			checkAll: !!project?.checkAll,
			clientId: project?.clientId || '',
			clientWebsite: project?.clientWebsite || '',
			endDate: project?.endDate ? new Date(project.endDate) : '',
			focus: project?.focus || '',
			imageRules: project?.imageRules || '',
			isEnabled: !!project?.isEnabled,
			linksRules: project?.linksRules || '',
			maxWords: project?.maxWords || 1200,
			minWords: project?.minWords || 500,
			name: project?.name || '',
			notes: project?.notes || '',
			paidAt: project?.paidAt ? new Date(project.paidAt) : null,
			postTypeId: project?.postTypeId || '',
			pricePer1000: project?.pricePer1000 || 0,
			referenceWebsite: project?.referenceWebsite || '',
			startDate: project?.startDate ? new Date(project.startDate) : new Date(),
			tone: project?.tone || '',
		},
		validationSchema: validationSchemas.project,
		onSubmit: handleSubmit,
	});

	return (
		<div className="ProjectForm">
			<form onSubmit={formik.handleSubmit}>
				<TextField
					fullWidth
					id="name"
					name="name"
					label="Nombre del proyecto"
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					margin="normal"
				/>
				<FormControl fullWidth margin="normal">
					<InputLabel id="clientId-label">Cliente</InputLabel>
					<Select
						labelId="clientId-label"
						id="clientId"
						label="Cliente"
						name="clientId"
						onChange={formik.handleChange}
						value={formik.values.clientId}
						error={formik.touched.clientId && Boolean(formik.errors.clientId)}
					>
						{clients.map(({ id, name, lastName }) => (
							<MenuItem key={id} value={id}>
								{Tools.getFullName({ name, lastName })}
							</MenuItem>
						))}
					</Select>
					<FormHelperText error>{formik.touched.name && formik.errors.clientId}</FormHelperText>
				</FormControl>
				<FormControl fullWidth margin="normal">
					<InputLabel id="categoryId-label">Etiquetas</InputLabel>
					<Select
						error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
						id="categoryId"
						label="Etiquetas"
						labelId="categoryId-label"
						multiple
						name="categoryId"
						onChange={formik.handleChange}
						value={formik.values.categoryId}
						renderValue={() => {
							return categories
								.filter(({ id }) => formik.values.categoryId.includes(id))
								.map(({ name }) => name)
								.join(', ');
						}}
					>
						{categories.map(({ id, name }) => (
							<MenuItem key={id} value={id}>
								<Checkbox checked={formik.values.categoryId.indexOf(id) > -1} />
								{name}
							</MenuItem>
						))}
					</Select>
					<FormHelperText error>
						{formik.touched.categoryId && formik.errors.categoryId}
					</FormHelperText>
				</FormControl>
				<NumericInput
					name="amount"
					precision={1}
					decimalChar=","
					thousandChar="."
					label="Monto"
					onChange={(e) => (formik.values.amount = e.target.value)}
					variant="outlined"
					value={formik.values.amount}
					defaultValue={Number(formik.values.amount).toLocaleString('es')}
					helperText={formik.touched.amount && formik.errors.amount}
					error={formik.touched.amount && Boolean(formik.errors.amount)}
					margin="normal"
					fullWidth
				/>
				<CanI make="PROJECT_EDIT_PAID_AT">
					<CustomDatePicker
						name="paidAt"
						initialValue={formik.values.paidAt}
						formik={formik}
						label="Fecha de pago"
						style={{ width: '100%' }}
						error={formik.touched.paidAt && Boolean(formik.errors.paidAt)}
						helperText={formik.touched.paidAt && formik.errors.paidAt}
						margin="normal"
						time
					/>
				</CanI>
				<TitledBox title="Pautas" style={{ marginTop: 16 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<CustomDatePicker
								name="startDate"
								initialValue={formik.values.startDate}
								formik={formik}
								label="Desde"
								style={{ width: '100%' }}
								error={formik.touched.startDate && Boolean(formik.errors.startDate)}
								helperText={formik.touched.startDate && formik.errors.startDate}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CustomDatePicker
								name="endDate"
								initialValue={formik.values.endDate}
								formik={formik}
								label="Hasta"
								style={{ width: '100%' }}
								error={formik.touched.endDate && Boolean(formik.errors.endDate)}
								helperText={formik.touched.endDate && formik.errors.endDate}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								type="number"
								name="minWords"
								fullWidth
								id="minWords"
								label="Mínimo de palabras"
								value={formik.values.minWords}
								onChange={formik.handleChange}
								error={formik.touched.minWords && Boolean(formik.errors.minWords)}
								helperText={formik.touched.minWords && formik.errors.minWords}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								type="number"
								name="maxWords"
								fullWidth
								id="maxWords"
								label="Máximo de palabras"
								value={formik.values.maxWords}
								onChange={formik.handleChange}
								error={formik.touched.maxWords && Boolean(formik.errors.maxWords)}
								helperText={formik.touched.maxWords && formik.errors.maxWords}
							/>
						</Grid>
					</Grid>
					<TextField
						fullWidth
						id="imageRules"
						name="imageRules"
						label="Imágenes"
						value={formik.values.imageRules}
						onChange={formik.handleChange}
						error={formik.touched.imageRules && Boolean(formik.errors.imageRules)}
						helperText={formik.touched.imageRules && formik.errors.imageRules}
						margin="normal"
					/>
					<TextField
						fullWidth
						id="linksRules"
						name="linksRules"
						label="Enlaces"
						value={formik.values.linksRules}
						onChange={formik.handleChange}
						error={formik.touched.linksRules && Boolean(formik.errors.linksRules)}
						helperText={formik.touched.linksRules && formik.errors.linksRules}
						margin="normal"
					/>
					<TextField
						fullWidth
						id="clientWebsite"
						name="clientWebsite"
						label="Sitio web del cliente"
						value={formik.values.clientWebsite}
						onChange={formik.handleChange}
						error={formik.touched.clientWebsite && Boolean(formik.errors.clientWebsite)}
						helperText={formik.touched.clientWebsite && formik.errors.clientWebsite}
						margin="normal"
					/>
					<TextField
						fullWidth
						id="referenceWebsite"
						name="referenceWebsite"
						label="Sitio web de referencia"
						value={formik.values.referenceWebsite}
						onChange={formik.handleChange}
						error={formik.touched.referenceWebsite && Boolean(formik.errors.referenceWebsite)}
						helperText={formik.touched.referenceWebsite && formik.errors.referenceWebsite}
						margin="normal"
					/>
					<FormControl fullWidth margin="normal">
						<InputLabel id="categoryId-label">Tipo de publicaciones</InputLabel>
						<Select
							labelId="postTypeId-label"
							id="postTypeId"
							label="Tipo de publicaciones"
							name="postTypeId"
							onChange={formik.handleChange}
							value={formik.values.postTypeId}
							error={formik.touched.postTypeId && Boolean(formik.errors.postTypeId)}
							helperText={formik.touched.postTypeId && formik.errors.postTypeId}
						>
							{postTypes.map(({ id, name }) => (
								<MenuItem key={id} value={id}>
									{name}
								</MenuItem>
							))}
						</Select>
						<FormHelperText error>
							{formik.touched.postTypeId && formik.errors.postTypeId}
						</FormHelperText>
					</FormControl>

					<TextField
						fullWidth
						id="focus"
						name="focus"
						label="Enfoque"
						value={formik.values.focus}
						onChange={formik.handleChange}
						error={formik.touched.focus && Boolean(formik.errors.focus)}
						helperText={formik.touched.focus && formik.errors.focus}
						margin="normal"
					/>
					<TextField
						fullWidth
						id="tone"
						name="tone"
						label="Tono"
						value={formik.values.tone}
						onChange={formik.handleChange}
						error={formik.touched.tone && Boolean(formik.errors.tone)}
						helperText={formik.touched.tone && formik.errors.tone}
						margin="normal"
					/>
					<TextField
						fullWidth
						multiline
						minRows={2}
						maxRows={5}
						id="notes"
						name="notes"
						label="Notas generales"
						value={formik.values.notes}
						onChange={formik.handleChange}
						error={formik.touched.notes && Boolean(formik.errors.notes)}
						helperText={formik.touched.notes && formik.errors.notes}
						margin="normal"
					/>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<NumericInput
								id="pricePer1000"
								name="pricePer1000"
								precision={2}
								decimalChar=","
								thousandChar="."
								label="Precio por 1000 palabras"
								onChange={(e) => (formik.values.pricePer1000 = e.target.value)}
								variant="outlined"
								value={formik.values.pricePer1000}
								defaultValue={Number(formik.values.pricePer1000).toLocaleString('es')}
								helperText={formik.touched.pricePer1000 && formik.errors.pricePer1000}
								error={formik.touched.pricePer1000 && Boolean(formik.errors.pricePer1000)}
								margin="normal"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6} display="flex" alignItems="center">
							<CustomSwitch
								formik={formik}
								initialValue={formik.values.checkAll}
								name="checkAll"
								id="checkAll"
								key="checkAll"
								label="Revisar todo"
							/>
						</Grid>
					</Grid>
				</TitledBox>
				<CustomSwitch formik={formik} initialValue={formik.values.isEnabled} />

				<ActionButtons style={{ marginTop: 16 }}>
					<Button onClick={onCancel}>Cancelar</Button>
					<Button variant="contained" type="submit">
						{project ? 'Guardar' : 'Agregar proyecto'}
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default ProjectForm;
