import Http from './Http';

const endpoint = 'options';

const options = {
	getOptions() {
		return Http.get(endpoint);
	},

	updateOptions(data) {
		return Http.put(endpoint, data);
	},

	getPermissions(userId) {
		return Http.get(`permissions/${userId}`);
	},

	savePermissions(userId, data) {
		return Http.post(`permissions/${userId}`, data);
	},
};

export default options;
