import { Button, Grid, Typography } from '@mui/material';
import { ActionButtons, LabeledSlider } from 'components';
import { useFormik } from 'formik';
import { useState } from 'react';

const WriterCategoryPreferencesForm = ({ rates, initialRates = {}, onSubmit }) => {
	const [touched, setTouched] = useState(false);

	const formik = useFormik({
		initialValues: {
			rates: initialRates,
		},
		onSubmit: (values, form) => {
			if (touched) setTouched(false);
			onSubmit(values, form);
		},
	});

	const handleChange = () => {
		if (!touched) setTouched(true);
	};

	return (
		<div className="WriterCategoryPreferencesForm">
			<Typography component="h3" variant="h6" mb={2}>
				Ponderación de categorías:
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<Grid container rowSpacing={2} columnSpacing={5}>
					{rates.map((rate) => (
						<Grid key={rate.categoryId} item xs={12} sm={4} md={3}>
							<LabeledSlider
								name={rate.categoryId}
								label={rate.name}
								key={rate.categoryId}
								initialValue={rate.rate}
								formik={formik}
								disabled={!rate.isWriterEditable}
								onChange={handleChange}
							/>
						</Grid>
					))}
				</Grid>
				<ActionButtons style={{ marginTop: 16 }}>
					<Button type="submit" variant="outlined" size="small" disabled={!touched}>
						Actualizar
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default WriterCategoryPreferencesForm;
