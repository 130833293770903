import { Route, Switch, Redirect } from 'react-router-dom';

import { useLoggedUser } from 'hooks';

import roles from 'common/constants/roles';

import AdminAssignment from 'pages/Admin/Assignment';
import AdminPayments from 'pages/Admin/Payments';
import AdminPaymentsList from 'pages/Admin/PaymentsList';
import Administrators from 'pages/Admin/Administrators';
import Assignments from 'pages/Admin/Assignments';
import Categories from 'pages/Admin/Categories';
import ClientAccounting from 'pages/Admin/ClientAccounting';
import Clients from 'pages/Admin/Clients';
import ClientsAccounting from 'pages/Admin/ClientsAccounting';
import DashBoard from 'pages/Dashboard';
import EditorAssignment from 'pages/Editor/Assignment';
import EditorAssignments from 'pages/Editor/Assignments';
import EditorPayments from 'pages/Editor/Payments';
import Editors from 'pages/Admin/Editors';
import EditorsAssignments from 'pages/Editor/MyAssignments';
import MyTasks from 'pages/Admin/MyTasks';
import Options from 'pages/Admin/Options';
import PaymentMethods from 'pages/Admin/PaymentMethods';
import PostTypes from 'pages/Admin/PostTypes';
import Profile from 'pages/Profile';
import Projects from 'pages/Admin/Projects';
import Tasks from 'pages/Admin/Tasks';
import WriterAssignment from 'pages/Writer/Assignment';
import WriterAssignments from 'pages/Writer/Assignments';
import WriterPayments from 'pages/Writer/Payments';
import Writers from 'pages/Admin/Writers';
import WritersRanking from 'pages/Admin/WritersRanking';

const UserRouter = () => {
	const { loggedUser } = useLoggedUser();
	const administrativeRoles = [roles.ADMIN.value, roles.ASSISTANT.value];

	return (
		<Switch>
			<Route strict exact path="/perfil">
				<Profile />
			</Route>
			{administrativeRoles.includes(loggedUser.role) && [
				<Route key="writers" strict path="/redactores">
					<Writers />
				</Route>,
				<Route key="writers-ranking" strict path="/redactores-ranking">
					<WritersRanking />
				</Route>,
				<Route key="editors" strict path="/editores">
					<Editors />
				</Route>,
				<Route key="admins" strict path="/administradores">
					<Administrators />
				</Route>,
				<Route key="clients" strict path="/clientes">
					<Clients />
				</Route>,
				<Route key="categories" strict path="/etiquetas">
					<Categories />
				</Route>,
				<Route key="postTypes" strict path="/tipos-de-post">
					<PostTypes />
				</Route>,
				<Route key="paymentMethods" strict path="/metodos-de-pago">
					<PaymentMethods />
				</Route>,
				<Route key="projects" strict path="/proyectos">
					<Projects />
				</Route>,
				<Route key="assignments" exact strict path="/asignaciones">
					<Assignments />
				</Route>,
				<Route key="tasks" strict path="/tareas">
					<Tasks />
				</Route>,
				<Route key="my-tasks" strict path="/mis-tareas">
					<MyTasks />
				</Route>,
				<Route key="admin-assignment" exact strict path="/asignaciones/:assignmentId">
					<AdminAssignment />
				</Route>,
				<Route key="admin-payments" exact strict path="/facturacion-detalle">
					<AdminPayments />
				</Route>,
				<Route key="admin-payments-list" exact strict path="/facturacion">
					<AdminPaymentsList />
				</Route>,
				<Route key="admin-clients-accounting" exact strict path="/contabilidad">
					<ClientsAccounting />
				</Route>,
				<Route key="admin-client-accounting" exact strict path="/contabilidad/:clientId">
					<ClientAccounting />
				</Route>,
				<Route key="admin/options" exact strict path="/configuracion">
					<Options />
				</Route>,
			]}
			{loggedUser.role === roles.WRITER.value && [
				<Route key="writer-payments" exact strict path="/mi-facturacion">
					<WriterPayments />
				</Route>,
				<Route key="writer-assignment" exact strict path="/mis-asignaciones/:assignmentId">
					<WriterAssignment />
				</Route>,
				<Route key="writer-assignments" exact strict path="/mis-asignaciones">
					<WriterAssignments />
				</Route>,
			]}
			{loggedUser.role === roles.EDITOR.value && [
				<Route key="writer-payments" exact strict path="/mi-facturacion">
					<EditorPayments />
				</Route>,
				<Route key="editor-assignments" exact strict path="/mis-asignaciones">
					<EditorsAssignments />
				</Route>,
				<Route key="editor-assignments" exact strict path="/asignaciones">
					<EditorAssignments />
				</Route>,
				<Route key="editor-assignment" exact strict path="/mis-asignaciones/:assignmentId">
					<EditorAssignment />
				</Route>,
			]}
			<Route strict exact path="/">
				<DashBoard />
			</Route>
			<Route>
				<Redirect to="/" />
			</Route>
		</Switch>
	);
};

export default UserRouter;
