import roles from 'common/constants/roles';
import useLoggedUser from './useLoggedUser';

export default function useAuth() {
	const {
		loggedUser: { permissions, role },
	} = useLoggedUser();

	const canI = (make) => (role === roles.ADMIN.value ? true : permissions.includes(make));

	return { canI };
}
