import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { useFormik } from 'formik';
import NumericInput from 'material-ui-numeric-input';

export const UserRatesForm = ({ userRates, onSubmit }) => {
	const initialValues = {};
	userRates.forEach(({ userId, rate }) => {
		initialValues[userId] = rate || 0.0;
	});

	const handleSubmit = (values) => {
		const rates = Object.keys(values).map((key) => ({ userId: key, rate: values[key] }));
		onSubmit({ rates });
	};

	const formik = useFormik({
		initialValues,
		onSubmit: handleSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<TableContainer component={Box}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Usuario</TableCell>
							<TableCell>Tarifa</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{userRates?.map((userRate) => {
							const { userId, fullName } = userRate;

							return (
								<TableRow key={userId}>
									<TableCell>{fullName}</TableCell>
									<TableCell align="right">
										<NumericInput
											name={userId}
											precision={2}
											decimalChar=","
											thousandChar="."
											label="Tarifa"
											onChange={(e) => (formik.values[userId] = e.target.value)}
											variant="outlined"
											value={formik.values[userId]}
											defaultValue={Number(formik.values[userId]).toLocaleString('es')}
											helperText={formik.touched[userId] && formik.errors[userId]}
											error={formik.touched[userId] && Boolean(formik.errors[userId])}
											margin="normal"
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
				<Button onClick={null} color="primary" variant="contained" size="small" type="submit">
					Guardar
				</Button>
			</Box>
		</form>
	);
};
