import { useFormik } from 'formik';
import { Button, Stack, TextField } from '@mui/material';

import Tools from 'utils/Tools';

import validationSchemas from 'utils/validationSchemas';
import { ActionButtons, CustomSwitch, LabeledSlider } from 'components';

const CategoryForm = ({ category, onSubmit, onCancel, rates, initialRates }) => {
	const formik = useFormik({
		initialValues: {
			name: category?.name || '',
			isEnabled: !!category?.isEnabled,
			isWriterEditable: !!category?.isWriterEditable,
			rates: initialRates,
		},
		validationSchema: validationSchemas.category,
		onSubmit,
	});

	return (
		<div className="CategoryForm">
			<form onSubmit={formik.handleSubmit} className="CategoryForm-form">
				<TextField
					fullWidth
					id="name"
					name="name"
					label="Nombre de categoría"
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					margin="normal"
				/>
				<Stack direction="row" spacing={1}>
					<CustomSwitch initialValue={!!category?.isEnabled} formik={formik} />
					<CustomSwitch
						name="isWriterEditable"
						id="isWriterEditable"
						label="Editable por redactor"
						formik={formik}
						initialValue={!!category?.isWriterEditable}
					/>
				</Stack>

				<div className="CategoryForm-form-rates">
					{rates.map((rate) => (
						<div key={rate.userId} className="CategoryForm-form-rates-item">
							<LabeledSlider
								key={rate.userId}
								name={rate.userId}
								label={Tools.getFullName(rate)}
								initialValue={rate.rate}
								formik={formik}
							/>
						</div>
					))}
				</div>
				<ActionButtons style={{ marginTop: 16 }}>
					<Button onClick={onCancel}>Cancelar</Button>
					<Button variant="contained" type="submit">
						{category ? 'Guardar' : 'Agregar etiqueta'}
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default CategoryForm;
