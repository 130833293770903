import { useEffect, useState } from 'react';

import { UserService } from 'services';
import { FeaturedStar, Layout, PageTitle, Spinner } from 'components';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Tools from 'utils/Tools';
import RankingFilters from './RankingFilters';
import { RankingStatistics } from './RankingStatistics';

import './WritersRanking.scss';

const { getWritersRanking } = UserService;

const { sortByField, getFullName, queryStringToObject, objectToQueryString, get30DaysPeriod } =
	Tools;

const initialFilters = objectToQueryString({
	...get30DaysPeriod(),
	orderBy: 'average,totalWords',
	order: 'DESC',
});

const WritersRanking = () => {
	const [writers, setWriters] = useState([]);
	const [filters, setFilters] = useState(initialFilters);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (isLoading) {
			getWritersRanking(filters)
				.then(({ data }) => {
					let writersWithScore = data.map((writer) => {
						const { totalWords, average } = writer;
						const fullName = getFullName(writer);
						const score = totalWords * (average - 3.5);

						return { ...writer, fullName, score };
					});

					const { orderBy, order } = queryStringToObject(filters);
					if (orderBy && orderBy === 'average,totalWords')
						writersWithScore = sortByField(writersWithScore, 'score', order);

					setWriters(writersWithScore);
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	}, [filters, isLoading]);

	const handleSearch = (query) => {
		setFilters(query);
		setIsLoading(true);
	};

	const handleResetFilters = () => {
		setFilters(initialFilters);
		setIsLoading(true);
	};

	return (
		<Layout>
			<PageTitle title="Ranking de redactores" />
			<RankingFilters onSearch={handleSearch} isLoading={isLoading} onReset={handleResetFilters} />
			<RankingStatistics ranking={writers} isLoading={isLoading} />
			{isLoading && <Spinner text="Cargando redactores" isOpen small />}
			{!isLoading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Posición</TableCell>
								<TableCell>Nombre completo</TableCell>
								<TableCell>Destacado</TableCell>
								<TableCell>Asignaciones</TableCell>
								<TableCell>Promedio</TableCell>
								<TableCell>Palabras totales</TableCell>
								<TableCell>Puntaje</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{writers.map((writer, index) => {
								const { totalWords, average, id, totalAssignments, fullName, score, isFeatured } =
									writer;

								return (
									<TableRow key={id}>
										<TableCell size="medium" width={50}>
											{index + 1}
										</TableCell>
										<TableCell size="medium">{fullName}</TableCell>
										<TableCell size="medium">
											<FeaturedStar isFeatured={isFeatured} />
										</TableCell>
										<TableCell size="medium">{totalAssignments}</TableCell>
										<TableCell size="medium">{average}</TableCell>
										<TableCell size="medium">{totalWords}</TableCell>
										<TableCell size="medium">{Math.round(score)}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Layout>
	);
};

export default WritersRanking;
