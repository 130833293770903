import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import Tools from 'utils/Tools';

const CommentDialog = ({ title, comment, type, onClose }) => {
	return (
		<Dialog maxWidth="xs">
			<DialogContent>
				<Alert severity={type}>
					<AlertTitle>{title}</AlertTitle>
					<div dangerouslySetInnerHTML={Tools.getDangerousHtmlObject(comment)} />
				</Alert>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Listo</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CommentDialog;
