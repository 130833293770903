import { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	Box,
	Button,
	Divider,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { CheckOutlined, DeleteForeverRounded } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';
import { PaymentService, OptionService } from 'services';
import {
	ActionButtons,
	ConfirmDialog,
	Layout,
	NoData,
	PageTitle,
	PaymentsFilters,
	Spinner,
} from 'components';
import Tools from 'utils/Tools';
import extraTypes from 'common/constants/extraType';
import PaymentExtraDialog from './PaymentExtraDialog';

const { getOptions } = OptionService;
const { getPaymentsByUser, processPayments, deletePayment } = PaymentService;
const { getDate, getPaymentStatusLabel, objectToQueryString, getErrorMessage, toFixed2 } = Tools;

function useQuery() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}

const Payments = () => {
	const query = useQuery();
	const [isLoading, setIsLoading] = useState(true);
	const [showExtrasForm, setShowExtrasForm] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [options, setOptions] = useState(null);
	const [payments, setPayments] = useState(null);
	const [paymentsExtra, setPaymentsExtra] = useState(null);
	const [currentFilters, setCurrentFilters] = useState(null);
	const { callService, isProcessing } = useService();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		getOptions()
			.then(({ data }) => {
				setOptions(data);
			})
			.catch((error) => {
				console.log('error', error);
			});
	}, []);

	const handleSearch = (filters) => {
		const payload = { ...filters };
		const query = objectToQueryString(payload);

		setCurrentFilters(filters);
		setIsLoading(true);

		getPaymentsByUser(query)
			.then(({ data }) => {
				setPayments(data.payments);
				setPaymentsExtra(data.extra);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const handleProcessPayments = () => {
		callService(processPayments({ payments, paymentsExtra }))
			.then(({ data }) => {
				setPayments(data.updatedPayments);
				setPaymentsExtra(data.updatedExtras);
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
			});
	};

	const handleAddExtra = () => {
		setShowExtrasForm(false);
		handleSearch(currentFilters);
	};

	const handleDeletePayment = (paymentId) => {
		callService(deletePayment(paymentId))
			.then(() => {
				const updatedPayments = [...payments].filter(({ id }) => id !== paymentId);
				setPayments(updatedPayments);
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
			});
	};

	const canProcess =
		payments &&
		!!payments?.length &&
		currentFilters?.to &&
		dayjs(currentFilters?.to).endOf('day') < dayjs().utc();

	const thereArePayments = !!payments?.length;
	const thereAreExtras = !!paymentsExtra?.length;

	let subTotal = 0;
	let subTotalExtra = 0;
	if (thereArePayments)
		subTotal = payments.reduce((accumulator, { amount }) => accumulator + amount, 0);

	if (thereAreExtras)
		subTotalExtra = paymentsExtra.reduce(
			(accumulator, { amount, extraType }) =>
				accumulator + (extraType === extraTypes.DISCOUNT.value ? amount * -1 : amount),
			0
		);

	let editorFee = 0;
	if (options) {
		editorFee = options.find(({ key }) => key === 'EDITOR_FEE')?.value || 0;
	}

	const isWriter = currentFilters?.idType === 'writerId';

	const initialValues = {
		idType: query.get('idType') || 'WRITER',
		userId: query.get('userId') || null,
		period: query.get('period') || 'current',
		searchType: query.get('searchType') || 'period',
	};

	if (initialValues.searchType === 'range') {
		initialValues.from = query.get('from');
		initialValues.to = query.get('to');
	}

	return (
		<Layout>
			<PageTitle title="Detalle de facturación" />
			<PaymentsFilters onSearch={handleSearch} initialValues={initialValues} />
			<Typography component="h2" variant="h4" sx={{ marginBottom: 1 }}>
				Pagos
			</Typography>
			{isLoading && <Spinner small text="Cargando facturación" isOpen />}
			{thereArePayments && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell size="medium">Estatus</TableCell>
								<TableCell>Fecha de envío</TableCell>
								<TableCell>Título</TableCell>
								<TableCell>{isWriter ? 'Editor' : 'Redactor'}</TableCell>
								<TableCell>Calificación</TableCell>
								<TableCell>Precio x 1000</TableCell>
								<TableCell>Palabras</TableCell>
								<TableCell>Pago</TableCell>
								<TableCell>Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{payments.map((payment) => {
								const {
									amount,
									assignmentId,
									editorName,
									id,
									isRated,
									maxWords,
									pricePer1000,
									sentAt,
									status,
									title,
									totalWords,
									writerName,
									writerQualification,
								} = payment;

								return (
									<TableRow key={id}>
										<TableCell size="medium">{getPaymentStatusLabel(status)}</TableCell>
										<TableCell>{getDate({ date: sentAt, format: 'dateTime' })}</TableCell>
										<TableCell>
											<Link to={`/asignaciones/${assignmentId}`}>{title}</Link>
										</TableCell>
										<TableCell>{isWriter ? editorName : writerName}</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{isRated ? writerQualification : 'N/A'}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											${toFixed2(isWriter ? pricePer1000 : editorFee)}
										</TableCell>
										<TableCell sx={{ textAlign: 'center' }}>
											{totalWords}
											{totalWords > maxWords && (
												<>
													<br />
													<small>(máx. {maxWords})</small>
												</>
											)}
										</TableCell>
										<TableCell sx={{ textAlign: 'right' }}>${toFixed2(amount)}</TableCell>
										<TableCell>
											<IconButton size="small" onClick={() => setShowDeleteConfirm(payment)}>
												<DeleteForeverRounded />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell colSpan={8}>
									<Typography variant="h5" textAlign="right">
										Subtotal
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="h5" textAlign="right">
										${toFixed2(subTotal)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{payments && !payments.length && <NoData message="No hay pagos registrados" />}

			<Divider sx={{ my: 2 }} />
			<Typography component="h2" variant="h4" sx={{ marginBottom: 1 }}>
				Extras
			</Typography>
			{paymentsExtra && !paymentsExtra.length && (
				<NoData message="No hay bonos o cobro de deudas" />
			)}
			{thereAreExtras && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell size="medium">Estatus</TableCell>
								<TableCell>Tipo</TableCell>
								<TableCell>Motivo/Descripción</TableCell>
								<TableCell>Monto</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{paymentsExtra.map((extra) => {
								const { id, status, extraType, amount, description } = extra;
								const isDiscount = extraType === extraTypes.DISCOUNT.value;

								return (
									<TableRow key={id}>
										<TableCell>{getPaymentStatusLabel(status)}</TableCell>
										<TableCell>{extraTypes[extraType].label}</TableCell>
										<TableCell>{description}</TableCell>
										<TableCell
											sx={{
												textAlign: 'right',
												color: isDiscount ? 'darkred' : 'green',
												fontWeight: 500,
											}}
										>
											{isDiscount && '-'}${toFixed2(amount)}
										</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell colSpan={3}>
									<Typography variant="h5" textAlign="right">
										Subtotal extras
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="h5" textAlign="right">
										${toFixed2(subTotalExtra)}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}

			<ActionButtons style={{ marginTop: 8 }}>
				<Button size="small" variant="outlined" onClick={() => setShowExtrasForm(true)}>
					Añadir Bono o Descuento
				</Button>
			</ActionButtons>
			{showExtrasForm && (
				<PaymentExtraDialog
					onClose={() => setShowExtrasForm(false)}
					onSuccess={handleAddExtra}
					userId={currentFilters?.userId}
					sentAt={currentFilters?.to}
				/>
			)}

			<Divider sx={{ my: 2 }} />
			<Typography component="h2" variant="h4" textAlign="right">
				Total: ${toFixed2(subTotal + subTotalExtra)}
			</Typography>
			<Divider sx={{ my: 2 }} />
			{canProcess && (
				<ActionButtons style={{ marginTop: 16 }}>
					<Button
						color="primary"
						variant="contained"
						startIcon={<CheckOutlined />}
						onClick={handleProcessPayments}
						disabled={isProcessing}
					>
						{isProcessing ? 'Procesando...' : 'Procesar pagos pendientes'}
					</Button>
				</ActionButtons>
			)}
			{!!showDeleteConfirm && (
				<ConfirmDialog
					onCancel={() => setShowDeleteConfirm(false)}
					onOk={() => {
						setShowDeleteConfirm(false);
						handleDeletePayment(showDeleteConfirm.id);
					}}
					question={`¿Seguro que desea eliminar el pago de "${showDeleteConfirm.title}"?`}
					okButtonText="Sí, Borrar pago"
					okButtonType="error"
				>
					Este pago sería eliminado por completo sin posibilidad de recuperar el registro. Bórrelo
					solo en caso de estar completamente seguro.
				</ConfirmDialog>
			)}
		</Layout>
	);
};

export default Payments;
