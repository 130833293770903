import { useEffect, useState } from 'react';

import { PersonOffOutlined } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';

import { UserService } from 'services';
import Spinner from 'components/Spinner';
import Tools from 'utils/Tools';

const WritersOccupationRate = () => {
	const [writersAvailability, setWritersAvailability] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (isLoading) {
			UserService.getWritersAvailability()
				.then(({ data }) => {
					setWritersAvailability(data);
				})
				.catch(console.log)
				.finally(() => setIsLoading(false));
		}
	}, [isLoading]);

	if (isLoading) return <Spinner small isOpen text="Cargando tasa de ocupación" />;

	const { occupationRate } = writersAvailability;

	return (
		<Chip
			color="warning"
			icon={<PersonOffOutlined />}
			label={
				<Typography>
					Tasa de Ocupación: <strong>{Tools.toFixed2(occupationRate)}%</strong>
				</Typography>
			}
		/>
	);
};

export default WritersOccupationRate;
