import {
	CreateRounded,
	SpellcheckRounded,
	LabelRounded,
	ClassRounded,
	PaymentRounded,
	FolderSharedOutlined,
	FolderOutlined,
	AssignmentRounded,
	MonetizationOnRounded,
	Settings,
	AssignmentTurnedIn,
	StarBorder,
	SupervisorAccountRounded,
	MonetizationOnOutlined,
} from '@mui/icons-material';

const icons = {
	writers: <CreateRounded />,
	editors: <SpellcheckRounded />,
	projects: <FolderOutlined />,
	categories: <LabelRounded />,
	postTypes: <ClassRounded />,
	paymentMethods: <PaymentRounded />,
	clients: <FolderSharedOutlined />,
	assignments: <AssignmentRounded />,
	myAssignments: <AssignmentTurnedIn />,
	clientsAccounting: <MonetizationOnOutlined />,
	payments: <MonetizationOnRounded />,
	paymentsList: <MonetizationOnRounded />,
	options: <Settings />,
	writersRanking: <StarBorder />,
	admins: <SupervisorAccountRounded />,
};

export default icons;
