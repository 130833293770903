import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useLoggedUser, usePermissions } from 'hooks';
import { Layout, MyRank, PageTitle, WriterComments } from 'components';

import roles from 'common/constants/roles';
import icons from 'common/constants/menuIcons';
import userMenu from 'common/data/userMenu.json';

import './Dashboard.scss';

const DashBoard = () => {
	const { loggedUser } = useLoggedUser();
	const { canI } = usePermissions();

	const { role, userInfo } = loggedUser;
	const { isWriter } = loggedUser;

	return (
		<Layout>
			<PageTitle title={`¡Hola, ${loggedUser.name}!`} />
			<Typography sx={{ marginTop: -2 }}>{roles[role].label}/a</Typography>
			<div className="Dashboard">
				{userMenu[loggedUser.role].map((menuItem) => {
					const { key, to, text, permission } = menuItem;

					return (
						canI(permission) && (
							<Link key={key} to={to} className="Dashboard-item">
								{icons[key]}
								{text}
							</Link>
						)
					);
				})}
			</div>
			{isWriter && (
				<>
					<div className="WriterRanking">
						<div className="WriterRanking-title">
							<h2>
								Ranking <small>(Últimos 30 días)</small>
							</h2>
						</div>
						<div className="WriterRanking-rank">
							<MyRank />
						</div>
					</div>
					<div className="WriterComments">
						<div className="WriterComments-title">
							<h2>Observaciones generales</h2>
						</div>
						<div className="WriterComments-comments">
							<WriterComments
								comments={userInfo.comments || 'No hay comentarios por parte del administrador'}
							/>
						</div>
					</div>
				</>
			)}
		</Layout>
	);
};

export default DashBoard;
