import { useState } from 'react';

import { useFormik } from 'formik';
import {
	Button,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import assignmentStatus from 'common/constants/assignmentStatus';

import validationSchemas from 'utils/validationSchemas';
import { ActionButtons, Spinner } from 'components';
import Tools from 'utils/Tools';
import CurrentAssignments from './CurrentAssignments';
import { CircleRounded } from '@mui/icons-material';

const { getShortName, getFullName, toFixed2 } = Tools;

const AssignmentForm = ({
	assignment,
	clients,
	currentAssignments,
	editors,
	isLoadingWriters,
	onCancel,
	onChangeClient,
	onChangeProject,
	onChangeWriter,
	onSubmit,
	project,
	projects,
	writers,
}) => {
	const [sendEmail, setSendEmail] = useState(true);
	const handleSubmit = (values) => {
		if (!assignment) delete values.status;
		if (!values?.editorId) values.editorId = null;

		onSubmit(values, formik);
	};

	const formik = useFormik({
		initialValues: {
			clientId: assignment?.clientId || project?.clientId || '',
			editorId: assignment?.editorId || '',
			guidelines: assignment?.guidelines || '',
			maxWords: assignment?.maxWords || 0,
			projectId: assignment?.projectId || project?.id || '',
			sendEmail: true,
			status: assignment?.status || '',
			title: assignment?.title || '',
			writerId: assignment?.writerId || '',
		},
		validationSchema: validationSchemas.assignment,
		onSubmit: handleSubmit,
	});

	const { status } = assignment || {};
	const disabled =
		!!status &&
		![
			assignmentStatus.PENDING.value,
			assignmentStatus.ACCEPTED.value,
			assignmentStatus.REJECTED_BY_WRITER.value,
		].includes(status);

	return (
		<div className="AssignmentForm">
			<form onSubmit={formik.handleSubmit} className="AssignmentForm-form">
				<TextField
					error={formik.touched.title && Boolean(formik.errors.title)}
					fullWidth
					helperText={formik.touched.title && formik.errors.title}
					id="title"
					label="Título"
					margin="normal"
					name="title"
					onChange={formik.handleChange}
					value={formik.values.title}
				/>
				<FormControl fullWidth margin="normal">
					<InputLabel id="clientId-label">Cliente</InputLabel>
					<Select
						disabled={disabled}
						labelId="clientId-label"
						id="clientId"
						label="Cliente"
						name="clientId"
						onChange={(e) => {
							formik.values.clientId = e.target.value;
							onChangeClient(e.target.value);
						}}
						value={formik.values.clientId}
						error={formik.touched.clientId && Boolean(formik.errors.clientId)}
					>
						{clients.map(({ id, name, lastName }) => {
							return (
								<MenuItem key={id} value={id}>
									{getFullName({ name, lastName })}
								</MenuItem>
							);
						})}
					</Select>
					<FormHelperText error>{formik.touched.clientId && formik.errors.clientId}</FormHelperText>
				</FormControl>
				<FormControl fullWidth margin="normal">
					<InputLabel id="projectId-label">Proyecto</InputLabel>
					<Select
						disabled={disabled}
						labelId="projectId-label"
						id="projectId"
						label="Proyecto"
						name="projectId"
						onChange={(e) => {
							formik.values.projectId = e.target.value;
							onChangeProject(e.target.value);
						}}
						value={formik.values.projectId}
						error={formik.touched.projectId && Boolean(formik.errors.projectId)}
					>
						{projects.map(({ id, name, endDate }) => {
							const isValid = dayjs() < dayjs(endDate).endOf('day');

							return (
								<MenuItem key={id} value={id} disabled={!isValid}>
									{name} {!isValid && <small>(Finalizado)</small>}
								</MenuItem>
							);
						})}
					</Select>
					<FormHelperText error>
						{formik.touched.projectId && formik.errors.projectId}
					</FormHelperText>
				</FormControl>
				<FormControl fullWidth margin="normal">
					<InputLabel id="writerId-label">Redactor</InputLabel>
					<Select
						disabled={disabled}
						labelId="writerId-label"
						id="writerId"
						label="Redactor"
						name="writerId"
						onChange={(e) => {
							formik.values.writerId = e.target.value;
							onChangeWriter(e.target.value);
						}}
						value={formik.values.writerId}
						error={formik.touched.writerId && Boolean(formik.errors.writerId)}
						className="AssignmentForm-form-writers"
					>
						{writers.map(({ id, name, lastName, rates, qualification, isAvailable }) => {
							const scores = (
								<small>
									(Preferencias: {toFixed2(rates)} | Calidad: {toFixed2(qualification ?? 0)})
								</small>
							);

							return (
								<MenuItem key={id} value={id}>
									{getShortName({ name, lastName })}&nbsp;{scores}&nbsp;
									{isAvailable ? (
										<CircleRounded color="success" />
									) : (
										<CircleRounded color="error" />
									)}
								</MenuItem>
							);
						})}
					</Select>
					<FormHelperText error>{formik.touched.writerId && formik.errors.writerId}</FormHelperText>
				</FormControl>
				<div className="AssignmentForm-form-assignments">
					<CurrentAssignments
						isWriterSelected={!!formik.values.writerId}
						currentAssignments={currentAssignments}
					/>
				</div>
				<FormControl fullWidth margin="normal">
					<InputLabel id="editorId-label">Editor</InputLabel>
					<Select
						labelId="editorId-label"
						id="editorId"
						label="Editor"
						name="editorId"
						onChange={formik.handleChange}
						value={formik.values.editorId}
						error={formik.touched.editorId && Boolean(formik.errors.editorId)}
					>
						{editors
							.filter(
								({ id, isEnabled }) => isEnabled || (!isEnabled && assignment?.editorId === id)
							)
							.map(({ id, name, lastName }) => (
								<MenuItem key={id} value={id}>
									{getFullName({ name, lastName })}
								</MenuItem>
							))}
					</Select>
					<FormHelperText error>{formik.touched.editorId && formik.errors.editorId}</FormHelperText>
				</FormControl>
				<TextField
					type="number"
					name="maxWords"
					fullWidth
					id="maxWords"
					label="Máximo de palabras"
					value={formik.values.maxWords}
					onChange={formik.handleChange}
					error={formik.touched.maxWords && Boolean(formik.errors.maxWords)}
					helperText={
						(formik.touched.maxWords && formik.errors.maxWords) ||
						'Para omitir este campo, asigne 0'
					}
					margin="normal"
				/>
				<TextField
					fullWidth
					multiline
					minRows={2}
					maxRows={6}
					id="guidelines"
					name="guidelines"
					label="Indicaciones"
					value={formik.values.guidelines}
					onChange={formik.handleChange}
					error={formik.touched.guidelines && Boolean(formik.errors.guidelines)}
					helperText={formik.touched.guidelines && formik.errors.guidelines}
					margin="normal"
				/>
				{assignment &&
					[
						assignmentStatus.APPROVED.value,
						assignmentStatus.REVISION.value,
						assignmentStatus.SECOND_REVISION.value,
						assignmentStatus.COMPLETED.value,
						assignmentStatus.REJECTED_BY_EDITOR.value,
					].includes(assignment.status) && (
						<FormControl fullWidth margin="normal">
							<InputLabel id="status-label">Estatus</InputLabel>
							<Select
								labelId="status-label"
								id="status"
								label="Estatus"
								name="status"
								onChange={formik.handleChange}
								value={formik.values.status}
							>
								{Object.entries(assignmentStatus).map(([key, { label }]) => (
									<MenuItem key={key} value={key}>
										{label}
									</MenuItem>
								))}
							</Select>
							<FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
							<Typography variant="caption">
								Este campo solo debe ser usado en caso de necesitar corregir una asignación ya
								enviada o aprobada, no para manejar el flujo normal de la asignación.
							</Typography>
						</FormControl>
					)}
				{!assignment && (
					<FormControlLabel
						sx={{ width: '100%' }}
						control={
							<Switch
								name="sendEmail"
								id="sendEmail"
								onChange={(event) => {
									setSendEmail(event.target.checked);
									formik.values.sendEmail = event.target.checked;
								}}
								checked={sendEmail}
							/>
						}
						onChange={formik.handleChange}
						label="Enviar correo de aviso al Redactor"
					/>
				)}
				<ActionButtons style={{ marginTop: 16 }}>
					<Button onClick={onCancel}>Cancelar</Button>
					<Button variant="contained" type="submit">
						{assignment ? 'Guardar' : 'Agregar Asignación'}
					</Button>
				</ActionButtons>
			</form>
			{isLoadingWriters && <Spinner isOpen text="Cargando redactores" />}
		</div>
	);
};

export default AssignmentForm;
