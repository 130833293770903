import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';

import { CanI, Layout, PageTitle, Spinner } from 'components';
import Tools from 'utils/Tools';
import TaskDialog from './TaskDialog';
import { TaskService } from 'services';
import { taskStatus } from 'common/constants/taskStatus';
import { EditRounded } from '@mui/icons-material';

const Tasks = () => {
	const [showTaskForm, setShowTaskForm] = useState(false);
	const [tasks, setTasks] = useState(null);
	const [isLoadingTasks, setIsLoadingTasks] = useState(true);
	const [selectedTask, setSelectedTask] = useState(null);

	const handleShowTaskForm = () => setShowTaskForm(true);
	const handleCloseTaskForm = () => {
		setShowTaskForm(false);
		if (selectedTask) setSelectedTask(null);
	};

	useEffect(() => {
		if (isLoadingTasks) {
			TaskService.getTasks()
				.then(({ data }) => {
					setTasks(data);
				})
				.catch(console.log)
				.finally(() => {
					setIsLoadingTasks(false);
				});
		}
	}, [isLoadingTasks]);

	const handleSuccess = () => {
		handleCloseTaskForm();
		setIsLoadingTasks(true);
	};

	const handleClickEdit = (task) => {
		setSelectedTask(task);
		handleShowTaskForm();
	};

	return (
		<Layout>
			<PageTitle
				title="Tareas"
				actionButtons={[
					<CanI make="TASK_ADD">
						<Button onClick={handleShowTaskForm}>Añadir tarea</Button>
					</CanI>,
				]}
			/>
			{isLoadingTasks && <Spinner small text="Cargando tareas" isOpen />}
			{!isLoadingTasks && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Usuario</TableCell>
								<TableCell>Código</TableCell>
								<TableCell>Tarea</TableCell>
								<TableCell>Descripción</TableCell>
								<TableCell>Detalles</TableCell>
								<TableCell>Entrega</TableCell>
								<TableCell>Duración (min)</TableCell>
								<TableCell>Efectividad</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Monto</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tasks?.map((task) => {
								const {
									deliveryDate,
									description,
									duration,
									guidelines,
									id,
									name,
									status,
									taskTypeCode,
									userName,
								} = task;
								const date = Tools.getDate({ date: deliveryDate });
								const amount = Tools.toFixed2(duration * 120);
								// const activeStatuses = [taskStatus.COMPLETED.value, taskStatus.IN_PROGRESS.value, taskStatus.PAUSED.value];

								return (
									<TableRow key={id}>
										<TableCell>{userName}</TableCell>
										<TableCell>{taskTypeCode}</TableCell>
										<TableCell>{name}</TableCell>
										<TableCell>{description}</TableCell>
										<TableCell>{guidelines}</TableCell>
										<TableCell sx={{ whiteSpace: 'nowrap' }}>{date}</TableCell>
										<TableCell align="right">{duration}</TableCell>
										<TableCell>-</TableCell>
										<TableCell>{taskStatus[status].label}</TableCell>
										<TableCell align="right">{amount}</TableCell>
										<TableCell>
											<CanI make="TASK_EDIT">
												<IconButton size="small" onClick={() => handleClickEdit(task)}>
													<EditRounded />
												</IconButton>
											</CanI>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{showTaskForm && (
				<TaskDialog task={selectedTask} onClose={handleCloseTaskForm} onSuccess={handleSuccess} />
			)}
		</Layout>
	);
};

export default Tasks;
