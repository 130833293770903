const roles = {
	WRITER: {
		label: 'Redactor',
		value: 'WRITER',
	},
	EDITOR: {
		label: 'Editor',
		value: 'EDITOR',
	},
	CLIENT: {
		label: 'Cliente',
		value: 'CLIENT',
	},
	ASSISTANT: {
		label: 'Asistente',
		value: 'ASSISTANT',
	},
	ADMIN: {
		label: 'Administrador',
		value: 'ADMIN',
	},
};

export const rolesArray = Object.values(roles).map(({ label, value }) => ({ label, value }));

export default roles;
