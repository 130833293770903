import { Button, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { usePermissions } from 'hooks';
import { useSnackbar } from 'notistack';
import Tools from 'utils/Tools';

const DollarRatesForm = ({ onFinish, options, isSavingRates }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { canI } = usePermissions();

	const handleFinish = (values) => {
		const USD_COP = Number(values.USD_COP);
		// const USD_VES = Number(values.USD_VES);

		if (Number.isNaN(USD_COP)) {
			enqueueSnackbar('La tasa Dólar/Peso debe ser numérico', { variant: 'error' });
			return 0;
		}

		// if (Number.isNaN(USD_VES)) {
		// 	enqueueSnackbar('La tasa Dólar/BsD. debe ser numérico', { variant: 'error' });
		// 	return 0;
		// }

		onFinish({ ...values, USD_COP });
	};

	const formik = useFormik({
		initialValues: {
			USD_COP: Tools.getOptionValue(options, 'USD_COP'),
			// USD_VES: Tools.getOptionValue(options, 'USD_VES'),
		},
		onSubmit: handleFinish,
	});

	return (
		<form className="DollarRatesForm" onSubmit={formik.handleSubmit} style={{ flex: 1 }}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<TextField
						fullWidth
						id="USD_COP"
						name="USD_COP"
						label="Tasa Dólar/Pesos"
						value={formik.values.USD_COP}
						onChange={formik.handleChange}
						error={formik.touched.USD_COP && Boolean(formik.errors.USD_COP)}
						helperText={formik.touched.USD_COP && formik.errors.USD_COP}
						disabled={isSavingRates || !canI('PAYMENT_SAVE_RATE')}
					/>
				</Grid>
				<Grid key="submit" item xs={12} md={6}>
					<Button
						fullWidth
						variant="outlined"
						type="submit"
						disabled={isSavingRates || !canI('PAYMENT_SAVE_RATE')}
					>
						Guardar Tasas
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default DollarRatesForm;
