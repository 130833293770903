import { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Link, Stack } from '@mui/material';

import { ProjectService } from 'services';
import Tools from 'utils/Tools';

import { CustomDialogTitle, Spinner } from 'components';
import LabeledDescription from 'components/LabeledDescription';

const { getProjects } = ProjectService;
const { getDate } = Tools;

const ProjectDetails = ({ projectId, onClose }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [project, setProject] = useState(null);

	useEffect(() => {
		if (isLoading) {
			getProjects(`projectId=${projectId}`)
				.then(({ data }) => {
					setProject(data);
					setIsLoading(false);
				})
				.catch((error) => {
					console.log(`error`, error);
					setIsLoading(false);
				});
		}
	}, [isLoading, projectId]);

	const {
		name,
		startDate,
		endDate,
		minWords,
		maxWords,
		imageRules,
		linksRules,
		clientWebsite,
		referenceWebsite,
		focus,
		tone,
		pricePer1000,
		notes,
		clientName,
		clientLastName,
		category,
		postType,
	} = project || {};

	return (
		<Dialog onClose={onClose} disableEscapeKeyDown={false}>
			<CustomDialogTitle>Detalles de proyecto</CustomDialogTitle>
			<DialogContent sx={{ minWidth: 250 }}>
				{isLoading && <Spinner small isOpen text="Cargando proyecto" />}
				{!isLoading && (
					<Stack spacing={1}>
						<LabeledDescription label="Nombre" value={name} />
						<LabeledDescription label="Cliente" value={`${clientName} ${clientLastName}`} />
						<LabeledDescription label="Etiqueta" value={category} />
						<LabeledDescription
							label="Fecha de inicio"
							value={getDate({ date: startDate, format: 'short' })}
						/>
						<LabeledDescription
							label="Fecha de fin"
							value={getDate({ date: endDate, format: 'short' })}
						/>
						<LabeledDescription label="Precio x 1000 palabras" value={`$${pricePer1000}`} />
						<LabeledDescription label="Rango de palabras" value={`${minWords} - ${maxWords}`} />
						<LabeledDescription label="Tipo de publicaciones" value={postType} />
						<LabeledDescription
							label="Sitio web de cliente"
							value={
								clientWebsite ? (
									<Link href={clientWebsite} underline="none" target="_blank">
										{clientWebsite}
									</Link>
								) : (
									'-'
								)
							}
						/>
						<LabeledDescription
							label="Sitio web de referencia"
							value={
								referenceWebsite ? (
									<Link href={referenceWebsite} underline="none" target="_blank">
										{referenceWebsite}
									</Link>
								) : (
									'-'
								)
							}
						/>
						<LabeledDescription label="Pautas de imágenes" value={imageRules || '-'} />
						<LabeledDescription label="Pautas de enlaces" value={linksRules || '-'} />
						<LabeledDescription label="Foco" value={focus || '-'} />
						<LabeledDescription label="Tono" value={tone || '-'} />
						<LabeledDescription label="Pautas generales" value={notes || '-'} />
					</Stack>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Listo</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ProjectDetails;
