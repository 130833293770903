const paymentStatus = {
	NULLED: {
		label: 'Anulada',
		value: 'NULLED',
	},
	GENERATED: {
		label: 'Generada',
		value: 'GENERATED',
	},
	PENDING: {
		label: 'Pendiente',
		value: 'PENDING',
	},
	PROCESSED: {
		label: 'Procesada',
		value: 'PROCESSED',
	},
	POSTPONED: {
		label: 'Pospuesto',
		value: 'POSTPONED',
	},
};

export default paymentStatus;
