import { Box, Dialog, DialogContent, Divider, Grid, Stack, Typography } from '@mui/material';
import { taskStatus } from 'common/constants/taskStatus';

import { CustomDialogTitle, TitledBox } from 'components';
import LabeledDescription from 'components/LabeledDescription';
import Tools from 'utils/Tools';
import { MyTaskForm } from './MyTaskForm';

const MyTaskDialog = ({ onClose, task }) => {
	const date = Tools.getDate({ date: task.deliveryDate });
	const amount = Tools.toFixed2(task.duration * 120);

	const handleSubmit = (values) => {
		console.log('values', values);
	};

	return (
		<Dialog open onClose={onClose} fullWidth>
			<CustomDialogTitle>Detalles de la tarea</CustomDialogTitle>
			<DialogContent style={{ paddingTop: 8 }}>
				<TitledBox title={`${task.name} (${task.taskTypeCode})`}>
					<Stack spacing={1}>
						<LabeledDescription label="Descripción" value={task.description} />
						<LabeledDescription label="Duración (min)" value={task.duration} />
						<Box>
							<LabeledDescription
								label="Entrega"
								value={Tools.getDate({ date, format: 'short' })}
							/>
							<Typography fontSize={12}>{Tools.getFromNow(date)}</Typography>
						</Box>

						<Grid container direction="row">
							<Grid item xs={6}>
								<LabeledDescription label="Estatus" value={taskStatus[task.status].label} />
							</Grid>
							<Grid item xs={6}>
								<LabeledDescription label="Monto" value={amount} />
							</Grid>
						</Grid>
						<LabeledDescription label="Comentarios" value={task.guidelines} />
						<Divider />
						<MyTaskForm onSubmit={handleSubmit} onCancel={onClose} />
					</Stack>
				</TitledBox>
			</DialogContent>
		</Dialog>
	);
};

export default MyTaskDialog;
