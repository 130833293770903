import { Container } from '@mui/material';

import Logo from 'common/images/logo-footer.png';

import './Footer.scss';

const Footer = () => {
	return (
		<div className="Footer">
			<Container className="Footer-container">
				<div className="Footer-container-logo">
					<a href="https://redactoresalfa.com" target="_blank" rel="noreferrer">
						<img src={Logo} alt="Logo de Redactores Alfa" />
					</a>
				</div>
				<div className="Footer-container-credits">
					Desarrollado por{' '}
					<a href="https://loodesarrollos.com" target="_blank" rel="noreferrer">
						Luis Suárez
					</a>
				</div>
			</Container>
		</div>
	);
};

export default Footer;
