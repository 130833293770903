import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useFormik } from 'formik';

import { useLoggedUser } from 'hooks';
import { ActionButtons, CustomDatePicker } from 'components';
import validationSchemas from 'utils/validationSchemas';

import Tools from 'utils/Tools';

export const TaskDialogForm = ({ onSubmit, admins, task, taskTypes, onClose }) => {
	const { loggedUser } = useLoggedUser();
	const findTaskType = (taskId) => taskTypes.find(({ id }) => id === taskId);

	const formik = useFormik({
		initialValues: {
			userId: task?.userId || '',
			deliveryDate: task?.deliveryDate ? new Date(task.deliveryDate) : '',
			code: task?.taskTypeCode || '',
			taskId: task?.taskTypeId || '',
			duration: task?.duration || 0,
			description: task?.description || '',
			recurrence: task?.recurrence || 0,
			details: task?.details || '',
			guidelines: task?.guidelines || '',
		},
		validationSchema: validationSchemas.task,
		onSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<FormControl fullWidth margin="normal">
				<InputLabel id="writerId-label">Usuario</InputLabel>
				<Select
					labelId="userId-label"
					id="userId"
					label="Usuario"
					name="userId"
					onChange={formik.handleChange}
					value={formik.values.userId}
					error={formik.touched.userId && Boolean(formik.errors.userId)}
				>
					{admins.map(({ id, name, lastName }) => (
						<MenuItem key={id} value={id}>
							{Tools.getFullName({ name, lastName })} {id === loggedUser.id && '(Yo)'}
						</MenuItem>
					))}
				</Select>
				<FormHelperText error>{formik.touched.userId && formik.errors.userId}</FormHelperText>
			</FormControl>
			<CustomDatePicker
				name="deliveryDate"
				initialValue={formik.values.deliveryDate}
				formik={formik}
				label="Fecha de entrega"
				style={{ width: '100%' }}
				error={formik.touched.deliveryDate && Boolean(formik.errors.deliveryDate)}
				helperText={formik.touched.deliveryDate && formik.errors.deliveryDate}
				margin="normal"
			/>
			<FormControl fullWidth margin="normal">
				<InputLabel id="writerId-label">Nombre de tarea</InputLabel>
				<Select
					labelId="taskId-label"
					id="taskId"
					label="Nombre de tarea"
					name="taskId"
					value={formik.values.taskId}
					onChange={({ target }) => {
						const taskType = findTaskType(target.value);
						formik.setValues({ ...formik.values, ...taskType, taskId: taskType.id });
					}}
					error={formik.touched.taskId && Boolean(formik.errors.taskId)}
				>
					{taskTypes.map(({ name, id }) => (
						<MenuItem key={id} value={id}>
							{name}
						</MenuItem>
					))}
				</Select>
				<FormHelperText error>{formik.touched.code && formik.errors.code}</FormHelperText>
			</FormControl>
			<TextField
				fullWidth
				id="code"
				name="code"
				disabled
				label="Código de tarea"
				value={formik.values.code}
				onChange={formik.handleChange}
				error={formik.touched.code && Boolean(formik.errors.code)}
				helperText={formik.touched.code && formik.errors.code}
				margin="normal"
			/>
			<TextField
				disabled
				error={formik.touched.duration && Boolean(formik.errors.duration)}
				fullWidth
				helperText={formik.touched.duration && formik.errors.duration}
				id="duration"
				label="Duración (minutos)"
				margin="normal"
				name="duration"
				onChange={formik.handleChange}
				type="number"
				value={formik.values.duration}
			/>
			<TextField
				disabled
				fullWidth
				multiline
				minRows={2}
				maxRows={6}
				id="description"
				name="description"
				label="Descripción"
				value={formik.values.description}
				onChange={formik.handleChange}
				error={formik.touched.description && Boolean(formik.errors.description)}
				helperText={formik.touched.description && formik.errors.description}
				margin="normal"
			/>
			<TextField
				error={formik.touched.recurrence && Boolean(formik.errors.recurrence)}
				fullWidth
				helperText={formik.touched.recurrence && formik.errors.recurrence}
				id="recurrence"
				label="Número de veces"
				margin="normal"
				name="recurrence"
				onChange={formik.handleChange}
				type="number"
				value={formik.values.recurrence}
			/>
			<TextField
				fullWidth
				multiline
				minRows={2}
				maxRows={6}
				id="guidelines"
				name="guidelines"
				label="Instrucciones / Detalles"
				value={formik.values.guidelines}
				onChange={formik.handleChange}
				error={formik.touched.guidelines && Boolean(formik.errors.guidelines)}
				helperText={formik.touched.guidelines && formik.errors.guidelines}
				margin="normal"
			/>
			<ActionButtons style={{ marginTop: 16 }}>
				<Button onClick={onClose}>Cancelar</Button>
				<Button variant="contained" type="submit">
					{task ? 'Guardar' : 'Agregar tarea'}
				</Button>
			</ActionButtons>
		</form>
	);
};
