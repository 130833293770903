import { useFormik } from 'formik';
import { Button, TextField } from '@mui/material';

import { signIn } from 'utils/validationSchemas';

const SignInForm = ({ onFinish }) => {
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: signIn,
		onSubmit: onFinish,
	});

	return (
		<div className="SignInForm">
			<form onSubmit={formik.handleSubmit}>
				<TextField
					fullWidth
					id="email"
					name="email"
					label="Correo electrónico"
					value={formik.values.email}
					onChange={formik.handleChange}
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
					margin="normal"
					size="medium"
				/>
				<TextField
					fullWidth
					id="password"
					name="password"
					label="Contraseña"
					type="password"
					value={formik.values.password}
					onChange={formik.handleChange}
					error={formik.touched.password && Boolean(formik.errors.password)}
					helperText={formik.touched.password && formik.errors.password}
					margin="normal"
					size="medium"
				/>
				<Button variant="contained" fullWidth type="submit" size="large" sx={{ mt: 2 }}>
					Ingresar
				</Button>
			</form>
		</div>
	);
};

export default SignInForm;
