import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { UserService } from 'services';
import Tools from 'utils/Tools';

const ClientSelect = memo(({ formik }) => {
	const [clients, setClients] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (isLoading) {
			UserService.getClients()
				.then(({ data }) => {
					setClients(data);
				})
				.catch(console.log)
				.finally(() => setIsLoading(false));
		}
	}, [isLoading]);

	return (
		<FormControl fullWidth>
			<InputLabel id="clientId-label">Cliente</InputLabel>
			<Select
				labelId="clientId-label"
				id="clientId"
				label="Cliente"
				name="clientId"
				onChange={formik.handleChange}
				value={formik.values.clientId}
			>
				<MenuItem value="">- Todos -</MenuItem>
				{clients.map(({ id, name, lastName }) => (
					<MenuItem key={id} value={id}>
						{Tools.getFullName({ name, lastName })}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
});

export default ClientSelect;
