import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { UserService } from 'services';
import Tools from 'utils/Tools';

const filters = 'orderBy=name&order=ASC';

const EditorSelect = memo(({ formik, multiple }) => {
	const [editors, setEditors] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (isLoading) {
			UserService.getEditors(filters)
				.then(({ data }) => {
					setEditors(data);
				})
				.catch(console.log)
				.finally(() => setIsLoading(false));
		}
	}, [isLoading]);

	return (
		<FormControl fullWidth>
			<InputLabel id="editorId-label">Editor</InputLabel>
			<Select
				id="editorId"
				label="Cliente"
				labelId="editorId-label"
				multiple={multiple}
				name="editorId"
				onChange={formik.handleChange}
				value={formik.values.editorId}
			>
				{!multiple && <MenuItem value="">- Todos -</MenuItem>}
				{editors.map(({ id, name, lastName }) => (
					<MenuItem key={id} value={id}>
						{Tools.getFullName({ name, lastName })}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
});

export default EditorSelect;
