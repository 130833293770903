import Http from './Http';

const endpoint = 'client-accounting';

const accounting = {
	getClientsAccounting(params) {
		return Http.get(endpoint, { params });
	},

	getClientAccounting(clientId, params) {
		return Http.get(`${endpoint}/${clientId}`, { params });
	},

	addClientPayment(clientId, payload) {
		return Http.post(`${endpoint}/${clientId}`, payload);
	},

	editClientPayment(paymentId, payload) {
		return Http.put(`${endpoint}/edit/${paymentId}`, payload);
	},
};

export default accounting;
