import { DialogTitle, Typography } from '@mui/material';

const CustomDialogTitle = ({ children, draggable }) => {
	return (
		<DialogTitle
			style={{ cursor: draggable ? 'move' : 'inherit' }}
			id={draggable ? 'draggable-dialog-title' : ''}
		>
			<Typography component="span" variant="h5" sx={{ fontWeight: 500 }}>
				{children}
			</Typography>
		</DialogTitle>
	);
};

export default CustomDialogTitle;
