import { Button } from '@mui/material';
import { useState } from 'react';
import Tools from 'utils/Tools';

const max = 20;

const WriterComments = ({ comments }) => {
	const [collapsed, setCollapsed] = useState(false);
	const firstWords = Tools.getFirstWords(comments, max);
	const totalWords = Tools.countWords(comments);
	const isLong = totalWords > max;

	const handleToggleMore = () => setCollapsed(!collapsed);

	return (
		<>
			<div
				dangerouslySetInnerHTML={Tools.getDangerousHtmlObject(
					collapsed ? comments : `${firstWords}..`
				)}
			/>
			{isLong && (
				<Button variant="text" sx={{ mt: 2 }} onClick={handleToggleMore}>
					Ver {collapsed ? 'menos' : 'más'}
				</Button>
			)}
		</>
	);
};

export default WriterComments;
