import { useEffect, useState } from 'react';

import { UserService } from 'services';

import { useSnackbar } from 'notistack';
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';
import { EditRounded, VisibilityOutlined } from '@mui/icons-material';

import Tools from 'utils/Tools';

import {
	AddButton,
	FeaturedStar,
	Layout,
	NoData,
	PageTitle,
	Spinner,
	StatusChip,
	UserDetails,
	UserFilters,
} from 'components';

import EditUserDialog from '../EditUserDialog';
import AddUserDialog from '../AddUserDialog';
import CanI from 'components/CanI';

const { getWriters } = UserService;

const Writers = () => {
	const [writers, setWriters] = useState([]);
	const [selectedForEdition, setSelectedForEdition] = useState(null);
	const [viewUserDetails, setViewUserDetails] = useState(null);
	const [showAddForm, setShowAddForm] = useState(false);
	const [filters, setFilters] = useState(
		Tools.objectToQueryString({
			isEnabled: 'true',
			isAvailable: 'true',
			orderBy: 'createdAt',
			order: 'DESC',
		})
	);
	const [isLoading, setIsLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	const handleCloseEditDialog = () => {
		setSelectedForEdition(null);
	};

	useEffect(() => {
		if (isLoading) {
			getWriters(filters)
				.then(({ data }) => {
					setWriters(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [enqueueSnackbar, filters, isLoading]);

	const handleFilters = (queryString) => {
		setFilters(queryString);
		setIsLoading(true);
	};

	return (
		<Layout>
			<PageTitle
				title="Redactores"
				actionButtons={[
					<CanI make="WRITER_ADD">
						<AddButton onClick={() => setShowAddForm(true)} />
					</CanI>,
				]}
			/>
			<UserFilters onSearch={handleFilters} />
			{isLoading && <Spinner text="Cargando redactores" isOpen small />}
			{!isLoading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Nombres</TableCell>
								<TableCell>Apellidos</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Teléfono</TableCell>
								<TableCell>Destacado</TableCell>
								<TableCell>Bono</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Disponible</TableCell>
								<TableCell>Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{writers.map((writer) => {
								const { id, lastName, name, email, phone, isEnabled, userInfo } = writer;
								const { isFeatured, isAvailable, bonus } = userInfo;

								return (
									<TableRow key={id}>
										<TableCell>{name}</TableCell>
										<TableCell>{lastName}</TableCell>
										<TableCell>{email}</TableCell>
										<TableCell>{phone || '(No disponible)'}</TableCell>
										<TableCell>
											<FeaturedStar isFeatured={isFeatured} />
										</TableCell>
										<TableCell align="right">${bonus}</TableCell>
										<TableCell>
											<StatusChip isEnabled={isEnabled} />
										</TableCell>
										<TableCell>{isAvailable ? 'SÍ' : 'NO'}</TableCell>
										<TableCell>
											<IconButton size="small" onClick={() => setViewUserDetails(writer)}>
												<VisibilityOutlined />
											</IconButton>
											<CanI make="WRITER_EDIT">
												<IconButton size="small" onClick={() => setSelectedForEdition(writer)}>
													<EditRounded />
												</IconButton>
											</CanI>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{!writers.length && !isLoading && <NoData message="No hay redactores" />}
			{selectedForEdition && (
				<EditUserDialog
					onSuccess={() => setIsLoading(true)}
					user={selectedForEdition}
					onClose={handleCloseEditDialog}
				/>
			)}
			{showAddForm && (
				<AddUserDialog onClose={() => setShowAddForm(false)} onSuccess={() => setIsLoading(true)} />
			)}
			{viewUserDetails && (
				<UserDetails user={viewUserDetails} onClose={() => setViewUserDetails(null)} />
			)}
		</Layout>
	);
};

export default Writers;
