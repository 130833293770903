import { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Tab } from '@mui/material';
import {
	ChevronLeft,
	DownloadRounded,
	CheckRounded,
	ArrowBackOutlined,
	CloseOutlined,
	Star,
} from '@mui/icons-material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { DropzoneArea } from 'react-mui-dropzone';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';
import { AssignmentService } from 'services';

import {
	ActionButtons,
	AssignmentDetails,
	ConfirmDialog,
	Layout,
	PageTitle,
	QualificationSystem,
	Spinner,
} from 'components';

const { getAssignment, updateAssignment, uploadAssignment } = AssignmentService;

const tabPanelStyle = { style: { paddingLeft: 2, paddingRight: 2 } };
const comments = {
	wellDone: '',
	mustDoBetter: '',
	mustFix: '',
};

const Assignment = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [assignment, setAssignment] = useState(null);
	const [currentTab, setCurrentTab] = useState('1');
	const [showConfirmApprove, setShowConfirmApprove] = useState(false);
	const [showConfirmReject, setShowConfirmReject] = useState(false);
	const [showConfirmSecondReject, setShowConfirmSecondReject] = useState(false);
	const [showRatingForm, setShowRatingForm] = useState(false);
	const { assignmentId } = useParams();
	const { callService, isProcessing } = useService();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			getAssignment(assignmentId)
				.then(({ data }) => {
					setAssignment(data);
					setIsLoading(false);
				})
				.catch((error) => {
					console.log(`error`, error);
					setIsLoading(false);
				});
		}
	}, [isLoading, assignmentId]);

	const handleChangeTab = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const handleUpdateAssignment = (values) => {
		const payload = { ...values, ...comments };

		if (payload.writerQualification) {
			payload.isRated = true;
		}

		callService(updateAssignment(assignmentId, payload))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				setAssignment({ ...assignment, ...(data?.assignment || {}) });
			})
			.catch((error) => {
				console.log(`error`, error);
			});
	};

	const handleChangeStatus = (status) => {
		handleUpdateAssignment({ status });
	};

	const handleChangeFiles = (fileList) => {
		const file = fileList[0];

		if (file) {
			callService(uploadAssignment(assignmentId, { file }))
				.then(({ data }) => {
					enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
					setAssignment({ ...assignment, ...(data?.assignment || {}) });
				})
				.catch((error) => {
					console.log(`error`, error);
				});
		}
	};

	return (
		<Layout>
			<PageTitle
				title={isLoading ? 'Espere...' : assignment.title}
				actionButtons={[
					<Link to="/mis-asignaciones">
						<Button startIcon={<ChevronLeft />}>Asignaciones</Button>
					</Link>,
				]}
			/>

			{isLoading && <Spinner small text="Cargando asignación" />}
			{!isLoading && <AssignmentDetails assignment={assignment} />}
			{assignment && ['REVISION', 'SECOND_REVISION'].includes(assignment?.status) && (
				<Alert variant="filled" severity="info" sx={{ fontSize: '1.1em', mb: 2 }}>
					Recuerda descargar y revisar el artículo antes de aprobarlo o devolverlo.
				</Alert>
			)}
			{assignment && ['REVISION', 'SECOND_REVISION'].includes(assignment?.status) && (
				<TabContext value={currentTab}>
					<TabList
						onChange={handleChangeTab}
						aria-label="lab API tabs example"
						sx={{ borderBottom: '1px solid #ddd' }}
					>
						<Tab label="Cargar desde ordenador" value="1" />
					</TabList>
					<TabPanel value="1" {...tabPanelStyle}>
						<DropzoneArea
							onChange={handleChangeFiles}
							acceptedFiles={[
								'application/msword',
								'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
							]}
							maxFileSize={5000000}
							filesLimit={1}
							getFileLimitExceedMessage={() => 'Solo se admite un archivo a la vez'}
							dropzoneText="Arrastra un documento o haz click"
							getFileAddedMessage={(fileName) => `"${fileName}" ha sido añadido`}
							getFileRemovedMessage={(fileName) => `Se ha removido "${fileName}"`}
							getDropRejectMessage={() => 'Solo archivos: .doc y .docx. Máximo 5MB'}
							showPreviews={false}
							showPreviewsInDropzone={false}
						/>
					</TabPanel>
				</TabContext>
			)}

			{['APPROVED', 'COMPLETED'].includes(assignment?.status) && (
				<Alert variant="filled" severity="success" sx={{ fontSize: '1.1em', mb: 2 }}>
					La asignación está APROBADA y fue enviada a los administradores.{' '}
					{!assignment?.isRated && 'No olvides CALIFICAR la asignación.'}
				</Alert>
			)}

			<ActionButtons>
				{assignment && ['APPROVED', 'COMPLETED'].includes(assignment?.status) && (
					<Button
						color="success"
						variant="contained"
						startIcon={<Star />}
						onClick={() => setShowRatingForm(true)}
					>
						Calificar artículo
					</Button>
				)}
				{assignment?.docUrl && (
					<Button
						color="info"
						variant="contained"
						startIcon={<DownloadRounded />}
						href={assignment?.docUrl}
					>
						Descargar archivo
					</Button>
				)}
				{assignment && ['REVISION', 'SECOND_REVISION'].includes(assignment?.status) && (
					<Button
						color="success"
						variant="contained"
						startIcon={<CheckRounded />}
						onClick={() => setShowConfirmApprove(true)}
					>
						Aprobar artículo
					</Button>
				)}
				{assignment && ['REVISION'].includes(assignment?.status) && (
					<Button
						color="error"
						variant="contained"
						startIcon={<ArrowBackOutlined />}
						onClick={() => setShowConfirmReject(true)}
					>
						Devolver artículo
					</Button>
				)}
				{assignment && ['SECOND_REVISION'].includes(assignment?.status) && (
					<Button
						color="error"
						variant="contained"
						startIcon={<CloseOutlined />}
						onClick={() => setShowConfirmSecondReject(true)}
					>
						Rechazar artículo
					</Button>
				)}
			</ActionButtons>
			{showConfirmApprove && (
				<ConfirmDialog
					onCancel={() => setShowConfirmApprove(false)}
					onOk={() => {
						setShowConfirmApprove(false);
						handleChangeStatus('APPROVED');
					}}
					question="¿Deseas APROBAR el artículo?"
					okButtonText="Aprobar"
				>
					Asegúrate de haber descargado y revisado el artículo. Si has hecho modificaciones, primero
					sube la nueva versión y luego aprueba.
				</ConfirmDialog>
			)}
			{showConfirmReject && (
				<ConfirmDialog
					onCancel={() => setShowConfirmReject(false)}
					onOk={() => {
						setShowConfirmReject(false);
						handleChangeStatus('RETURNED');
					}}
					question="¿Deseas DEVOLVER el artículo?"
					okButtonText="Sí, devolver artículo"
					okButtonType="error"
				>
					Asegúrate de haber descargado y revisado el artículo. Si has hecho correcciones, sube la
					nueva versión primero.
				</ConfirmDialog>
			)}
			{showConfirmSecondReject && (
				<ConfirmDialog
					onCancel={() => setShowConfirmSecondReject(false)}
					onOk={() => {
						setShowConfirmSecondReject(false);
						handleChangeStatus('REJECTED_BY_EDITOR');
					}}
					question="¿Deseas RECHAZAR el artículo definitivamente?"
					okButtonText="Sí, rechazar artículo"
					okButtonType="error"
				>
					Una vez rechazado el artículo por segunda vez, el redactor no podrá subir nuevas
					versiones. Un administrador se encargará de gestionar la asignación.
				</ConfirmDialog>
			)}
			{showRatingForm && (
				<QualificationSystem
					onCancel={() => setShowRatingForm(false)}
					assignment={assignment}
					onUpdateAssignment={handleUpdateAssignment}
					open={showRatingForm}
				/>
			)}
			<Spinner isOpen={isProcessing} text="Actualizando asignación" />
		</Layout>
	);
};

export default Assignment;
