import { Pagination } from '@mui/material';
import { useState } from 'react';

const Paginator = ({ page, pages, onChange }) => {
	const [currentPage, setCurrentPage] = useState(page || 1);

	const handleChange = (e, page) => {
		setCurrentPage(page);
		onChange(page);
	};

	return (
		<Pagination
			count={pages}
			page={currentPage}
			onChange={handleChange}
			variant="outlined"
			sx={{ mt: 2 }}
		/>
	);
};

export default Paginator;
