import { useEffect, useState } from 'react';

import Spinner from 'components/Spinner';

import { UserService } from 'services';
import Tools from 'utils/Tools';

import './MyRank.scss';

const MyRank = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [ranking, setRanking] = useState({});

	useEffect(() => {
		if (isLoading) {
			const filters = Tools.get30DaysPeriod();
			const queryString = Tools.objectToQueryString(filters);

			UserService.getMyRank(queryString)
				.then(({ data }) => {
					setRanking(data);
				})
				.catch(console.log)
				.finally(() => setIsLoading(false));
		}
	}, [isLoading]);

	return (
		<div className="MyRank-wrapper">
			<div className="MyRank">
				<span className="MyRank-text">Eres el número</span>
				<span className="MyRank-rank">
					{isLoading ? <Spinner small text="" isOpen /> : ranking.rank}
				</span>
				<span className="MyRank-text">de {isLoading ? '?' : ranking.total} redactores</span>
			</div>
			<div className="MyAverage">
				<span className="MyAverage-text">Tu promedio es de</span>
				<span className="MyAverage-rank">
					{isLoading ? <Spinner small text="" isOpen /> : Tools.toFixed2(ranking.average)}
				</span>
				<span className="MyAverage-text">puntos</span>
			</div>
		</div>
	);
};

export default MyRank;
