import {
	Button,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';

import { ActionButtons, CustomDatePicker, CustomSwitch } from 'components';

const WriterInfoForm = ({ paymentMethods, userInfo, onSubmit }) => {
	const formik = useFormik({
		initialValues: {
			paymentMethodId: userInfo?.paymentMethodId || null,
			paymentInfo: userInfo?.paymentInfo || '',
			nextAvailableTime: userInfo?.nextAvailableTime || '',
			isAvailable: !!userInfo?.isAvailable || false,
			profileInfo: userInfo?.profileInfo || '',
			wordsPerDay: userInfo?.wordsPerDay || 0,
		},
		onSubmit,
	});

	return (
		<div className="WriterInfoForm">
			<form onSubmit={formik.handleSubmit}>
				<Typography component="h3" variant="h6" mb={2}>
					Acerca de
				</Typography>
				<Grid container rowSpacing={2} columnSpacing={5}>
					<Grid item xs={12} sm={6} md={8}>
						<TextField
							fullWidth
							multiline
							minRows={2}
							maxRows={5}
							id="profileInfo"
							name="profileInfo"
							label="Perfil"
							value={formik.values.profileInfo}
							onChange={formik.handleChange}
							error={formik.touched.profileInfo && Boolean(formik.errors.profileInfo)}
							helperText={formik.touched.profileInfo && formik.errors.profileInfo}
							margin="normal"
						/>
						<Typography variant="caption">
							Resalta tu profesión, grado de instrucción, etcétera. Por ejemplo: <br />
							<strong>Profesión</strong>: Periodista.
							<br />
							<strong>Años de experiencia</strong>: 5.
						</Typography>
					</Grid>
				</Grid>
				<Divider sx={{ my: 2 }} />
				<Typography component="h3" variant="h6" mb={2}>
					Preferencias de pago:
				</Typography>
				<Grid container rowSpacing={2} columnSpacing={5}>
					<Grid item xs={12} sm={6} md={4}>
						<FormControl fullWidth margin="normal">
							<InputLabel id="paymentMethodId-label">Método de pago</InputLabel>
							<Select
								labelId="paymentMethodId-label"
								id="paymentMethodId"
								label="Método de pago"
								name="paymentMethodId"
								onChange={formik.handleChange}
								value={formik.values.paymentMethodId}
								error={formik.touched.paymentMethodId && Boolean(formik.errors.paymentMethodId)}
							>
								{paymentMethods.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
							<FormHelperText error>
								{formik.touched.paymentMethodId && formik.errors.paymentMethodId}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							fullWidth
							multiline
							minRows={2}
							maxRows={3}
							id="paymentInfo"
							name="paymentInfo"
							label="Información de pago"
							value={formik.values.paymentInfo}
							onChange={formik.handleChange}
							error={formik.touched.paymentInfo && Boolean(formik.errors.paymentInfo)}
							helperText={formik.touched.paymentInfo && formik.errors.paymentInfo}
							margin="normal"
						/>
					</Grid>
				</Grid>
				<Divider sx={{ my: 2 }} />
				<Typography component="h3" variant="h6" mb={2}>
					Disponibilidad:
				</Typography>
				<Grid container rowSpacing={4} columnSpacing={5}>
					<Grid item xs={12} sm={6} md={4}>
						<CustomDatePicker
							time
							minDateTime={new Date()}
							name="nextAvailableTime"
							initialValue={formik.values.nextAvailableTime}
							formik={formik}
							label="Desde"
							style={{ width: '100%' }}
							error={formik.touched.nextAvailableTime && Boolean(formik.errors.nextAvailableTime)}
							helperText={formik.touched.nextAvailableTime && formik.errors.nextAvailableTime}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<CustomSwitch
							formik={formik}
							initialValue={formik.values.isAvailable}
							name="isAvailable"
							id="isAvailable"
							key="isAvailable"
							label="Disponible para asignaciones"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							type="number"
							name="wordsPerDay"
							fullWidth
							id="wordsPerDay"
							label="Estimado de palabras diarias"
							value={formik.values.wordsPerDay}
							onChange={formik.handleChange}
							error={formik.touched.wordsPerDay && Boolean(formik.errors.wordsPerDay)}
							helperText={formik.touched.wordsPerDay && formik.errors.wordsPerDay}
						/>
					</Grid>
				</Grid>
				<ActionButtons style={{ marginTop: 16 }}>
					<Button type="submit" variant="outlined" size="small">
						Guardar
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default WriterInfoForm;
