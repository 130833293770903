import { Dialog, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useService, useLoggedUser } from 'hooks';

import Tools from 'utils/Tools';
import { UserService } from 'services';

import { CustomDialogTitle, Spinner } from 'components';
import ProfileEditForm from './ProfileEditForm';

const { getErrorMessage, getValidationErrors } = Tools;

const ProfileEdit = ({ user, onClose }) => {
	const { isProcessing, callService } = useService();
	const { enqueueSnackbar } = useSnackbar();
	const { updateLoggedUser } = useLoggedUser();

	const handleSubmit = (values, { setFieldError }) => {
		callService(UserService.update(user.id, values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
				updateLoggedUser(data.user);
				onClose();
			})
			.catch((error) => {
				const validationErrors = getValidationErrors(error);
				if (validationErrors) {
					enqueueSnackbar('Verifica los campos', { variant: 'error' });
					setFieldError(validationErrors.field, validationErrors.message);
				}

				const errorMessage = getErrorMessage(error);
				if (errorMessage) {
					enqueueSnackbar(errorMessage, { variant: 'error' });
				}
			});
	};

	return (
		<Dialog
			open
			onClose={(_, reason) => {
				Tools.handleCloseDialog(_, reason, onClose);
			}}
			scroll="body"
			maxWidth="xs"
			disableEscapeKeyDown
		>
			<CustomDialogTitle>Editar perfil</CustomDialogTitle>
			<DialogContent>
				<ProfileEditForm user={user} onCancel={onClose} onSubmit={handleSubmit} />
			</DialogContent>
			<Spinner isOpen={isProcessing} text="Actualizando perfil..." />
		</Dialog>
	);
};

export default ProfileEdit;
