const assignmentStatus = {
	PENDING: {
		label: 'Pendiente',
		value: 'PENDING',
	},
	ACCEPTED: {
		label: 'Aceptada',
		value: 'ACCEPTED',
	},
	REJECTED_BY_WRITER: {
		label: 'Rechazada (redactor)',
		value: 'REJECTED_BY_WRITER',
	},
	REVISION: {
		label: 'En revisión',
		value: 'REVISION',
	},
	RETURNED: {
		label: 'Devuelta',
		value: 'RETURNED',
	},
	SECOND_REVISION: {
		label: 'Segunda revisión',
		value: 'SECOND_REVISION',
	},
	REJECTED_BY_EDITOR: {
		label: 'Rechazada (editor)',
		value: 'REJECTED_BY_EDITOR',
	},
	APPROVED: {
		label: 'Aprobada',
		value: 'APPROVED',
	},
	COMPLETED: {
		label: 'Completada',
		value: 'COMPLETED',
	},
};

export default assignmentStatus;
