import { Typography } from '@mui/material';

import './PageTitle.scss';

const PageTitle = ({ title, actionButtons = [], loadingText = null }) => {
	document.title = `${title} | Redactores Alfa`;

	return (
		<div className="PageTitle">
			<Typography component="h1" variant="h1">
				{loadingText || title}
			</Typography>
			<div className="PageTitle-actions">
				{actionButtons.map((button, index) => (
					<span key={`action-${index}`}>{button}</span>
				))}
			</div>
		</div>
	);
};

export default PageTitle;
