import { Backdrop, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Spinner = ({ isOpen, text = 'Cargando...', small }) => {
	return small ? (
		<Box sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<CircularProgress color="primary" size={28} sx={{ mb: 2 }} />
			{text}
		</Box>
	) : (
		<Backdrop
			className="Spinner"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				color: '#fff',
				zIndex: (theme) => theme.zIndex.drawer + 1,
				backgroundColor: 'rgba(0, 0, 0, 0.65)',
			}}
			open={isOpen}
		>
			<CircularProgress color="inherit" sx={{ mb: 2 }} />
			{text}
		</Backdrop>
	);
};

export default Spinner;
