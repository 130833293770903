import { Button, TextField } from '@mui/material';
import ActionButtons from 'components/ActionButtons';
import { useFormik } from 'formik';
import { usePermissions } from 'hooks';

const initialValues = {
	comments: '',
};

const WriterCommentForm = ({ comments, onSubmit, onCancel }) => {
	const { canI } = usePermissions();
	const formik = useFormik({
		initialValues: { ...initialValues, comments: comments || '' },
		onSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<TextField
				fullWidth
				multiline
				minRows={3}
				maxRows={20}
				id="comments"
				name="comments"
				label="Comentarios/observaciones"
				value={formik.values.comments}
				onChange={formik.handleChange}
				error={formik.touched.comments && Boolean(formik.errors.comments)}
				helperText={formik.touched.comments && formik.errors.comments}
				margin="normal"
				className="RatingForm-comments"
				disabled={!canI('ASSIGNMENT_COMMENT')}
			/>
			<ActionButtons style={{ marginTop: 16 }}>
				<Button onClick={onCancel}>Cancelar</Button>
				<Button variant="contained" type="submit" disabled={!canI('ASSIGNMENT_COMMENT')}>
					{comments ? 'Guardar' : 'Agregar Comentario'}
				</Button>
			</ActionButtons>
		</form>
	);
};

export default WriterCommentForm;
