import { Container, IconButton, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';

import './Header.scss';

const Header = ({ onOpenMenu }) => {
	return (
		<div className="Header">
			<Container maxWidth="lg" className="Header-container">
				<IconButton
					onClick={onOpenMenu}
					size="large"
					edge="start"
					color="inherit"
					className="Header-container-icon"
				>
					<Menu />
				</IconButton>
				<Typography component="h1" variant="h4">
					Redactores Alfa
				</Typography>
			</Container>
		</div>
	);
};

export default Header;
