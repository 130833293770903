import { Typography, Slider } from '@mui/material';

const LabeledSlider = ({ label, name, initialValue, disabled, onChange = () => {}, formik }) => {
	const marks = [];
	for (let index = 0.5; index <= 5; index += 0.5) {
		marks.push({ value: index, label: Number.isInteger(index) ? index : null });
	}

	return (
		<div className="LabeledSlider">
			<Typography variant="body2">{label}</Typography>
			<Slider
				disabled={disabled}
				color="primary"
				name={name}
				aria-label="LabeledSlider"
				defaultValue={initialValue}
				step={0.5}
				marks={marks}
				min={0.5}
				max={5}
				valueLabelDisplay="auto"
				onChange={(e) => {
					if (formik.values.rates) {
						formik.values.rates[name] = e.target.value;
					} else {
						formik.values[name] = e.target.value;
					}
					onChange({ name, value: e.target.value });
				}}
			/>
		</div>
	);
};

export default LabeledSlider;
