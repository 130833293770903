import { useState } from 'react';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

import Tools from 'utils/Tools';
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
} from '@mui/material';
import { CustomDatePicker, EditorSelect, WriterSelect } from 'components';

const { objectToQueryString, getDate, get30DaysPeriod } = Tools;

const { startDate: from, endDate: to } = get30DaysPeriod();

const initialValues = {
	editorId: [],
	endDate: new Date(dayjs(to).utc()),
	order: 'DESC',
	orderBy: 'score',
	startDate: new Date(dayjs(from).utc()),
	writerId: [],
};

const RankingFilters = ({ onSearch, isLoading, onReset }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [hasFilters, setHasFilters] = useState(false);

	const handleSubmit = (values) => {
		const { startDate, endDate, orderBy, editorId, writerId } = values;

		if (startDate) {
			if (endDate && dayjs(startDate) > dayjs(endDate)) {
				enqueueSnackbar('La fecha de inicio no puede ser mayor a la fecha de finalización', {
					variant: 'error',
				});
			}
		}

		const object = {
			...values,
			writerId: writerId.join(','),
			editorId: editorId.join(','),
			orderBy: orderBy === 'score' ? 'average,totalWords' : orderBy,
			startDate: startDate ? getDate({ date: startDate }) : null,
			endDate: endDate ? getDate({ date: endDate }) : null,
		};

		const query = objectToQueryString(object);

		if (query) {
			setHasFilters(true);
			onSearch(query);
		} else {
			enqueueSnackbar('No hay filtros seleccionados');
		}
	};

	const formik = useFormik({
		initialValues,
		onSubmit: handleSubmit,
	});

	const deleteFilters = () => {
		setHasFilters(false);
		formik.resetForm();
		onReset();
	};

	return (
		<div className="RankingFilters">
			<form onSubmit={formik.handleSubmit} className="RankingFilters-form">
				{isLoading ? (
					<LinearProgress sx={{ flex: 1 }} />
				) : (
					<CustomDatePicker
						name="startDate"
						value={formik.values.startDate}
						initialValue={formik.values.startDate}
						formik={formik}
						label="Desde"
						style={{ width: '100%' }}
						helperText={formik.touched.startDate && formik.errors.startDate}
					/>
				)}
				{isLoading ? (
					<LinearProgress sx={{ flex: 1 }} />
				) : (
					<CustomDatePicker
						name="endDate"
						value={formik.values.endDate}
						initialValue={formik.values.endDate}
						formik={formik}
						label="Hasta"
						style={{ width: '100%' }}
						helperText={formik.touched.endDate && formik.errors.endDate}
					/>
				)}
				<EditorSelect formik={formik} multiple />
				<WriterSelect formik={formik} multiple />
				<FormControl fullWidth>
					<InputLabel id="orderBy-label">Ordenar por</InputLabel>
					<Select
						labelId="orderBy-label"
						id="orderBy"
						label="Ordenar por"
						name="orderBy"
						onChange={formik.handleChange}
						value={formik.values.orderBy}
					>
						<MenuItem value="name">Nombre</MenuItem>
						<MenuItem value="average">Promedio</MenuItem>
						<MenuItem value="totalAssignments">Asignaciones</MenuItem>
						<MenuItem value="totalWords">Palabras</MenuItem>
						<MenuItem value="score">Puntaje</MenuItem>
					</Select>
					<FormHelperText error>{formik.touched.orderBy && formik.errors.orderBy}</FormHelperText>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel id="order-label">Orden</InputLabel>
					<Select
						labelId="order-label"
						id="order"
						label="Orden"
						name="order"
						onChange={formik.handleChange}
						value={formik.values.order}
					>
						<MenuItem value="DESC">Descendente</MenuItem>
						<MenuItem value="ASC">Ascendente</MenuItem>
					</Select>
					<FormHelperText error>{formik.touched.order && formik.errors.order}</FormHelperText>
				</FormControl>
				<div className="RankingFilters-form-actions">
					<Button variant="outlined" color="primary" type="submit">
						Aplicar
					</Button>
					{hasFilters && (
						<Button variant="outlined" color="secondary" onClick={deleteFilters}>
							Quitar
						</Button>
					)}
				</div>
			</form>
		</div>
	);
};

export default RankingFilters;
