import Http from './Http';

const endpoint = 'user';

const getQuery = (query) => (query ? `?${query}` : '');

const user = {
	create(data) {
		return Http.post(endpoint, data);
	},

	update(id, data) {
		return Http.put(`${endpoint}/update/${id}`, data);
	},

	getWriters(query = null) {
		return Http.get(`${endpoint}/writers${getQuery(query)}`);
	},

	getWriterInfo(writerId) {
		return Http.get(`${endpoint}/writers/${writerId}`);
	},

	getWritersSuggestions(projectId) {
		return Http.get(`${endpoint}/writers-suggestions?projectId=${projectId}`);
	},

	getWritersRanking(query) {
		return Http.get(`${endpoint}/writers-ranking${getQuery(query)}`);
	},

	getWritersAvailability() {
		return Http.get(`${endpoint}/writers-availability`);
	},

	getMyRank(query) {
		return Http.get(`${endpoint}/writer-ranking${getQuery(query)}`);
	},

	getEditors(query = null) {
		return Http.get(`${endpoint}/editors${getQuery(query)}`);
	},

	getClients(query = null) {
		return Http.get(`${endpoint}/clients${getQuery(query)}`);
	},

	getAdministrators(query = null) {
		return Http.get(`${endpoint}/admins${getQuery(query)}`);
	},
};

export default user;
