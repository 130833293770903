import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import {
	TableContainer,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	Typography,
} from '@mui/material';
import { CheckBoxOutlineBlank, CheckBoxRounded, FileDownloadOutlined } from '@mui/icons-material';

import { useLoggedUser, useService } from 'hooks';
import { AssignmentService } from 'services';
import Tools from 'utils/Tools';
import assignmentStatus from 'common/constants/assignmentStatus';

import { AssignmentFilters, Layout, NoData, PageTitle, Paginator, Spinner } from 'components';

const { getAssignments, updateAssignment } = AssignmentService;
const { getDate, getErrorMessage, getCategoriesNames } = Tools;

const limit = 15;

const Assignments = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [filters, setFilters] = useState({});
	const [assignments, setAssignments] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const { loggedUser } = useLoggedUser();
	const { isProcessing, callService } = useService();

	useEffect(() => {
		if (isLoading) {
			const queryString = Tools.objectToQueryString(filters);
			getAssignments(queryString)
				.then(({ data }) => {
					setAssignments(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [isLoading, enqueueSnackbar, filters]);

	const handleSearch = (filters) => {
		setFilters(filters);
		setIsLoading(true);
	};

	const handleChangePage = (page) => {
		setFilters({ ...filters, offset: page === 1 ? null : `${(page - 1) * limit}` });
		setIsLoading(true);
	};

	const handleTakeAssignment = ({ id, editorId }) => {
		const payload = {
			editorId: editorId && editorId === loggedUser.id ? null : loggedUser.id,
		};

		callService(updateAssignment(id, payload))
			.then(({ data }) => {
				const message = data?.assignment?.editorId
					? 'Has tomado la asignación'
					: 'Has liberado la asignación';

				enqueueSnackbar(message || 'Éxito', { variant: data.messageType });
				setIsLoading(true);
			})
			.catch((error) => {
				console.log(`error`, error);
			});
	};

	const pages = isLoading ? 0 : Math.floor((assignments.total + limit - 1) / limit);

	return (
		<Layout>
			<PageTitle title="Asignaciones" />
			<AssignmentFilters onSearch={handleSearch} />
			{isLoading && <Spinner text="Cargando asignaciones" isOpen small />}
			{!isLoading && (
				<>
					<Typography>Total: {assignments.total}</Typography>
					<TableContainer component={Box}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Estatus</TableCell>
									<TableCell>Fecha de entrega</TableCell>
									<TableCell>Título</TableCell>
									<TableCell>Cliente</TableCell>
									<TableCell>Proyecto</TableCell>
									<TableCell>Etiquetas</TableCell>
									<TableCell>Acciones</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{assignments.data.map((assignment) => {
									const {
										categories,
										clientName,
										deliveryDate,
										docUrl,
										id,
										projectName,
										status,
										title,
										editorId,
									} = assignment;

									const takeableStatuses = [
										assignmentStatus.PENDING.value,
										assignmentStatus.ACCEPTED.value,
										assignmentStatus.REVISION.value,
									];

									const hasBeenTaken = !!editorId;
									const belongsToSomeoneElse = hasBeenTaken && editorId !== loggedUser.id;
									const isTakeable = takeableStatuses.includes(status);
									const belongsToMe = editorId === loggedUser.id;

									const handleDownload = (e) => {
										if (belongsToSomeoneElse || (!hasBeenTaken && !isTakeable)) e.preventDefault();
									};

									const cellStyle = belongsToSomeoneElse
										? { color: '#cfcfcf', cursor: 'default' }
										: {};

									return (
										<TableRow key={id}>
											<TableCell style={cellStyle}>{assignmentStatus[status].label}</TableCell>
											<TableCell style={cellStyle}>
												{getDate({ date: deliveryDate, format: 'short' })}
											</TableCell>
											<TableCell style={cellStyle}>
												{!belongsToMe ? (
													title
												) : (
													<Link target="_blank" to={`/mis-asignaciones/${id}`}>
														{title}
													</Link>
												)}
											</TableCell>
											<TableCell style={cellStyle}>{clientName}</TableCell>
											<TableCell style={cellStyle}>{projectName}</TableCell>
											<TableCell style={cellStyle}>{getCategoriesNames(categories)}</TableCell>
											<TableCell>
												<IconButton
													size="small"
													onClick={() => handleTakeAssignment(assignment)}
													disabled={belongsToSomeoneElse || !isTakeable}
												>
													{hasBeenTaken ? <CheckBoxRounded /> : <CheckBoxOutlineBlank />}
												</IconButton>
												{docUrl && (
													<a
														href={docUrl}
														onClick={handleDownload}
														style={{
															cursor: belongsToSomeoneElse ? 'default' : 'pointer',
														}}
													>
														<IconButton
															size="small"
															disabled={belongsToSomeoneElse}
															color={belongsToMe ? 'primary' : 'default'}
														>
															<FileDownloadOutlined />
														</IconButton>
													</a>
												)}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
			{!assignments?.data?.length && !isLoading && <NoData message="No hay asignaciones" />}
			{!!assignments?.data?.length && <Paginator pages={pages} onChange={handleChangePage} />}
			{isProcessing && <Spinner text="Procesando" isOpen />}
		</Layout>
	);
};

export default Assignments;
