import { useEffect, useState } from 'react';

import { Button, Chip, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { CustomDialogTitle } from 'components';
import { UserNotificationService } from 'services';
import { useSnackbar } from 'notistack';
import Tools from 'utils/Tools';
import { usePermissions, useService } from 'hooks';

const NotificationModal = ({ onClose, notification, onResolve, onMarkAsRead }) => {
	const { canI } = usePermissions();
	const { senderName, readByAdmin, id, senderId, message } = notification;
	const [isLoading, setIsLoading] = useState(true);
	const [isRead, setIsRead] = useState(readByAdmin);
	const { enqueueSnackbar } = useSnackbar();
	const { callService, isProcessing } = useService();

	useEffect(() => {
		if (isLoading) {
			if (!isRead) {
				UserNotificationService.markAsRead(id)
					.then(() => {
						setIsRead(true);
						onMarkAsRead(senderId);
					})
					.catch((error) =>
						enqueueSnackbar(Tools.getErrorMessage(error) || 'Error', { variant: 'error' })
					)
					.finally(() => setIsLoading(false));
			} else setIsLoading(false);
		}
	}, [readByAdmin, id, senderId, enqueueSnackbar, onMarkAsRead, isRead, isLoading]);

	const handleResolve = () => {
		callService(UserNotificationService.closeByAdmin(id))
			.then(() => onResolve(senderId))
			.catch((error) =>
				enqueueSnackbar(Tools.getErrorMessage(error) || 'Error', { variant: 'error' })
			);
	};

	return (
		<Dialog onClose={onClose} open>
			<CustomDialogTitle>Notificación de {senderName}</CustomDialogTitle>
			<DialogContent>
				{notification && <div dangerouslySetInnerHTML={Tools.getDangerousHtmlObject(message)} />}
				<Stack mt={2} justifyContent="flex-start" direction="row" spacing={1}>
					<Chip label={isRead ? 'Leído' : 'No leído'} color={isRead ? 'success' : 'default'} />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cerrar</Button>
				<Button
					color="primary"
					disabled={isProcessing || !canI('PAYMENT_MARK_AS_READY')}
					onClick={handleResolve}
					variant="contained"
				>
					Marcar como resuelto
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NotificationModal;
