import { useContext } from 'react';

import AppContext from 'contexts/AppContext';
import roles from 'common/constants/roles';

const useLoggedUser = () => {
	const { loggedUser, updateLoggedUser } = useContext(AppContext);

	const hasRoles = {
		isWriter: loggedUser?.role === roles.WRITER.value,
		isEditor: loggedUser?.role === roles.EDITOR.value,
		isAdmin: loggedUser?.role === roles.ADMIN.value,
		isAssistant: loggedUser?.role === roles.ASSISTANT.value,
	};

	return { loggedUser: loggedUser ? { ...loggedUser, ...hasRoles } : null, updateLoggedUser };
};

export default useLoggedUser;
