import Http from './Http';

const endpoint = 'user-notification';

const notifications = {
	sendNotification(payload) {
		return Http.post(endpoint, payload);
	},

	getMyNotification() {
		return Http.get(`${endpoint}/mine`);
	},

	closeBySender(notificationId) {
		return Http.put(`${endpoint}/${notificationId}/close-sender`);
	},

	markAsRead(notificationId) {
		return Http.put(`${endpoint}/${notificationId}/read`);
	},

	closeByAdmin(notificationId) {
		return Http.put(`${endpoint}/${notificationId}/close-admin`);
	},
};

export default notifications;
