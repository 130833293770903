import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

import theme from 'config/MUITheme';
import { useLoggedUser, useVerifyToken } from 'hooks';

import { Spinner } from 'components';
import GuestRouter from 'pages/GuestRouter';
import UserRouter from 'pages/UserRouter';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.scss';

function App() {
	const { loggedUser } = useLoggedUser();
	const { isLoading } = useVerifyToken();

	if (isLoading) return <Spinner isOpen={isLoading} text="Verificando usuario..." />;

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3}>
				<BrowserRouter>{loggedUser ? <UserRouter /> : <GuestRouter />}</BrowserRouter>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
