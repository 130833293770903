import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Divider } from '@mui/material';

import { OptionService } from 'services';
import { usePermissions, useService } from 'hooks';
import { PageTitle, Layout, Spinner } from 'components';

import './Options.scss';
import OptionsForm from './OptionsForm';
import CanI from 'components/CanI';
import QualificationCriteria from './QualificationCriteria';
import TaskTypes from './TaskTypes';
import UserRates from './UserRates';

const { getOptions, updateOptions } = OptionService;

const Options = () => {
	const { canI } = usePermissions();
	const { callService, isProcessing } = useService();
	const [isLoading, setIsLoading] = useState(true);
	const [options, setOptions] = useState(null);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			if (canI('CONFIG_ACCESS'))
				getOptions()
					.then(({ data }) => {
						setOptions(data);
						setIsLoading(false);
					})
					.catch((error) => {
						console.log(`error`, error);
						setIsLoading(false);
					});
			else setIsLoading(false);
		}
	}, [isLoading, canI]);

	const handleSubmit = (values) => {
		callService(updateOptions(values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: data.messageType });
			})
			.catch((error) => {
				console.log(`error`, error);
			});
	};

	return (
		<Layout>
			<PageTitle title="Configuración" />
			<div className="Options">
				<CanI make="CONFIG_ACCESS">
					{isLoading && <Spinner text="Cargando configuración" small isOpen />}
					{!isLoading && (
						<div className="Options-list">
							<OptionsForm options={options} onFinish={handleSubmit} />
						</div>
					)}
				</CanI>
			</div>
			<CanI make="CONFIG_ACCESS">
				<Divider style={{ margin: 16 }} />
				{isProcessing && <Spinner isOpen text="Guardando cambios" />}
			</CanI>
			<QualificationCriteria />
			<CanI make="CONFIG_ACCESS">
				<Divider style={{ margin: 16 }} />
				<TaskTypes />
				<Divider style={{ margin: 16 }} />
				<UserRates />
			</CanI>
		</Layout>
	);
};

export default Options;
