import { useState } from 'react';

import { useFormik } from 'formik';
import {
	Button,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
} from '@mui/material';
import NumericInput from 'material-ui-numeric-input';

import validationSchemas from 'utils/validationSchemas';
import { ActionButtons } from 'components';

import roles, { rolesArray } from 'common/constants/roles';
import { useLoggedUser } from 'hooks';

const UserEditionForm = ({ user, onSubmit, onCancel }) => {
	const { loggedUser } = useLoggedUser();
	const { name, lastName, email, phone, role, isEnabled, userInfo = {}, idCont } = user;
	const [isUserEnabled, setIsUserEnabled] = useState(!!user.isEnabled);
	const [isFeaturedUser, setIsFeaturedUser] = useState(userInfo?.isFeatured);

	const formik = useFormik({
		initialValues: {
			name,
			lastName: lastName || '',
			idCont: idCont || '',
			email,
			phone: phone || '',
			role,
			isEnabled: !!isEnabled,
			isFeatured: !!userInfo?.isFeatured,
			bonus: userInfo?.bonus || 0,
		},
		validationSchema: validationSchemas.userEdit,
		onSubmit,
	});

	return (
		<div className="UserEditionForm">
			<form onSubmit={formik.handleSubmit}>
				<TextField
					fullWidth
					id="name"
					name="name"
					label="Nombres"
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					margin="normal"
					size="small"
				/>
				<TextField
					fullWidth
					id="lastName"
					name="lastName"
					label="Apellidos"
					value={formik.values.lastName}
					onChange={formik.handleChange}
					error={formik.touched.lastName && Boolean(formik.errors.lastName)}
					helperText={formik.touched.lastName && formik.errors.lastName}
					margin="normal"
					size="small"
				/>
				{formik.values.role === roles.CLIENT.value && (
					<TextField
						fullWidth
						id="idCont"
						name="idCont"
						label="ID Cont."
						value={formik.values.idCont}
						onChange={formik.handleChange}
						error={formik.touched.idCont && Boolean(formik.errors.idCont)}
						helperText={formik.touched.idCont && formik.errors.idCont}
						margin="normal"
					/>
				)}
				<TextField
					fullWidth
					id="email"
					name="email"
					label="Correo electrónico"
					value={formik.values.email}
					onChange={formik.handleChange}
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
					margin="normal"
					size="small"
				/>
				<TextField
					fullWidth
					id="phone"
					name="phone"
					label="Teléfono celular"
					value={formik.values.phone}
					onChange={formik.handleChange}
					error={formik.touched.phone && Boolean(formik.errors.phone)}
					helperText={formik.touched.phone && formik.errors.phone}
					margin="normal"
					size="small"
				/>
				<FormControl fullWidth size="small" margin="normal">
					<InputLabel id="role-label">Rol</InputLabel>
					<Select
						labelId="role-label"
						id="role"
						label="Rol"
						name="role"
						size="small"
						onChange={formik.handleChange}
						value={formik.values.role}
						disabled={user.role === 'CLIENT' || loggedUser.id === user?.id}
					>
						{rolesArray.map(({ label, value }) => (
							<MenuItem
								key={value}
								value={value}
								disabled={user.role !== 'CLIENT' && value === 'CLIENT'}
							>
								{label}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{formik.values.role === roles.WRITER.value && (
					<NumericInput
						name="bonus"
						precision={1}
						decimalChar=","
						thousandChar="."
						label="Bono"
						onChange={(e) => (formik.values.bonus = e.target.value)}
						variant="outlined"
						value={formik.values.bonus}
						defaultValue={Number(formik.values.bonus).toLocaleString('es')}
						helperText={formik.touched.bonus && formik.errors.bonus}
						error={formik.touched.bonus && Boolean(formik.errors.bonus)}
						margin="normal"
						fullWidth
					/>
				)}
				{role === roles.WRITER.value && (
					<FormControlLabel
						sx={{ width: '100%' }}
						control={
							<Switch
								name="isFeatured"
								id="isFeatured"
								onChange={(event) => {
									setIsFeaturedUser(event.target.checked);
									formik.values.isFeatured = event.target.checked;
								}}
								checked={isFeaturedUser}
							/>
						}
						onChange={formik.handleChange}
						label="Redactor destacado"
					/>
				)}
				<FormControlLabel
					control={
						<Switch
							name="isEnabled"
							id="isEnabled"
							onChange={(event) => {
								setIsUserEnabled(event.target.checked);
								formik.values.isEnabled = event.target.checked;
							}}
							checked={isUserEnabled}
							disabled={loggedUser.id === user?.id}
						/>
					}
					onChange={formik.handleChange}
					label="Activo"
				/>
				<ActionButtons style={{ marginTop: 8 }}>
					<Button onClick={onCancel}>Cerrar</Button>
					<Button variant="contained" type="submit">
						Guardar
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default UserEditionForm;
