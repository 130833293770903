import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AddButton, Layout, NoData, PageTitle, Spinner, StatusChip } from 'components';
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';
import { EditRounded } from '@mui/icons-material';

import Tools from 'utils/Tools';

import { PaymentMethodService } from 'services';
import PaymentMethodDialog from './PaymentMethodDialog';
import CanI from 'components/CanI';

const { getPaymentMethods } = PaymentMethodService;

const PaymentMethods = () => {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [showAddForm, setShowAddForm] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			getPaymentMethods()
				.then(({ data }) => {
					setPaymentMethods(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [enqueueSnackbar, isLoading]);

	const handleClose = () => {
		if (selectedPaymentMethod) setSelectedPaymentMethod(null);
		if (showAddForm) setShowAddForm(false);
	};

	return (
		<Layout>
			<PageTitle
				title="Métodos de pago"
				actionButtons={[
					<CanI make="PAYMENT_METHOD_ADD">
						<AddButton onClick={() => setShowAddForm(true)} />
					</CanI>,
				]}
			/>
			{isLoading && <Spinner text="Cargando etiquetas" isOpen small />}
			{!isLoading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Método de pago</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{paymentMethods.map((paymentMethod) => (
								<TableRow key={paymentMethod.id}>
									<TableCell>{paymentMethod.name}</TableCell>
									<TableCell>
										<StatusChip isEnabled={paymentMethod.isEnabled} />
									</TableCell>
									<TableCell>
										<CanI make="PAYMENT_METHOD_EDIT">
											<IconButton
												size="small"
												onClick={() => setSelectedPaymentMethod(paymentMethod)}
											>
												<EditRounded />
											</IconButton>
										</CanI>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{!paymentMethods.length && !isLoading && <NoData message="No hay métodos de pago" />}

			{(showAddForm || selectedPaymentMethod) && (
				<PaymentMethodDialog
					paymentMethod={selectedPaymentMethod}
					onCancel={handleClose}
					onSuccess={() => setIsLoading(true)}
				/>
			)}
		</Layout>
	);
};

export default PaymentMethods;
