import Http from './Http';

const endpoint = 'user-info';

const userInfo = {
	getUserInfo(userId) {
		return Http.get(`${endpoint}/${userId}`);
	},

	updateUserInfo(userId, data) {
		return Http.put(`${endpoint}/${userId}`, data);
	},
};

export default userInfo;
