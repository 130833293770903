import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

const LabeledValue = ({ label, value }) => {
	const theme = useTheme();
	const sm = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<div className="LabeledValue">
			<div className="LabeledValue-label" style={{ marginBottom: 2 }}>
				<Typography variant={sm ? 'body2' : 'caption'} component="label">
					{label}
				</Typography>
			</div>
			<div className="LabeledValue-value">
				<Typography variant={sm ? 'h4' : 'h5'}>{value}</Typography>
			</div>
		</div>
	);
};

export default LabeledValue;
