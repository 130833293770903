import Http from './Http';

const endpoint = 'projects';

const getQuery = (query) => (query ? `?${query}` : '');

const project = {
	getProjects(query) {
		return Http.get(`${endpoint}${getQuery(query)}`);
	},

	updateProject(id, data) {
		return Http.put(`${endpoint}/${id}`, data);
	},

	createProject(data) {
		return Http.post(endpoint, data);
	},
};

export default project;
