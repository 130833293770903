import { useState } from 'react';

import { Container } from '@mui/material';

import Header from './Header';
import Footer from './Footer';

import './Layout.scss';
import SideDrawer from './SideDrawer';

const Layout = ({ children }) => {
	const [showDrawer, setShowDrawer] = useState(false);

	const handleOpenMenu = () => setShowDrawer(true);

	return (
		<div className="Layout">
			<div className="Layout-header">
				<Header onOpenMenu={handleOpenMenu} />
			</div>
			<div className="Layout-body">
				<Container maxWidth="lg" className="Layout-body-container">
					{children}
				</Container>
			</div>
			<div className="Layout-footer">
				<Footer />
			</div>
			<SideDrawer onClose={() => setShowDrawer(false)} isOpen={showDrawer} />
		</div>
	);
};

export default Layout;
