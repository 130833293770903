export const findCriteria = (criteria, comment) =>
	criteria
		.map(({ description, percentage }) => {
			let substring = comment;
			let timesFound = 0;

			while (substring.includes(description)) {
				timesFound += 1;
				substring = substring.substring(substring.indexOf(description) + description.length);
			}

			return {
				criteria: description,
				timesFound,
				percentage,
			};
		})
		.filter(({ timesFound }) => !!timesFound);

export const findEachCriteria = (comment) => {
	const splittedComment = comment.split('\n').filter(Boolean);

	return splittedComment;
};
