import { useState } from 'react';

import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import esLocale from 'date-fns/locale/es';

const CustomDatePicker = ({
	name,
	label,
	initialValue,
	formik,
	error,
	helperText,
	time,
	minDateTime,
	margin = 'none',
	style,
}) => {
	const [value, setValue] = useState(initialValue);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
			{time ? (
				<DateTimePicker
					minDateTime={minDateTime || null}
					ampm
					ampmInClock
					name={name}
					label={label}
					mask="__/__/____ __:__"
					value={value}
					onChange={(newDate) => {
						setValue(newDate);
						formik.values[name] = newDate;
					}}
					renderInput={(params) => (
						<TextField
							margin={margin}
							error={error}
							helperText={helperText}
							style={style}
							{...params}
						/>
					)}
				/>
			) : (
				<DatePicker
					name={name}
					label={label}
					mask="__/__/____"
					value={value}
					onChange={(newDate) => {
						setValue(newDate);
						formik.values[name] = newDate;
					}}
					renderInput={(params) => (
						<TextField
							margin={margin}
							error={error}
							helperText={helperText}
							style={style}
							{...params}
						/>
					)}
				/>
			)}
		</LocalizationProvider>
	);
};

export default CustomDatePicker;
