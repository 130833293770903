import { useEffect, useState } from 'react';

import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';

import { AddButton, Spinner, TitledBox } from 'components';
import QualificationService from 'services/QualificationService';
import { CriteriaFormDialog } from './CriteriaFormDialog';

const { getQualificationCriteria } = QualificationService;

const QualificationCriteria = () => {
	const [loading, setLoading] = useState(true);
	const [criteria, setCriteria] = useState(null);
	const [showCriteriaForm, setShowCriteriaForm] = useState(false);
	const [selectedCriteria, setSelectedCriteria] = useState(null);

	useEffect(() => {
		getQualificationCriteria()
			.then(({ data }) => {
				setCriteria(data);
				setLoading(false);
			})
			.catch(console.log);
	}, [loading]);

	const handleShowCriteriaForm = () => setShowCriteriaForm(true);
	const handleCloseCriteriaDialog = () => setShowCriteriaForm(false);
	const handleClickEdit = (record) => {
		setSelectedCriteria(record);
		handleShowCriteriaForm();
	};
	const handleFinish = () => {
		handleCloseCriteriaDialog();
		setLoading(true);
	};

	return (
		<TitledBox title="Aspectos de Calificación" style={{ maxWidth: 566 }}>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
				<AddButton onClick={handleShowCriteriaForm} />
			</Box>
			{loading && <Spinner small text="Cargando aspectos..." />}
			{!loading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Aspecto</TableCell>
								<TableCell align="right">%</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{criteria.map((record) => {
								const { id, description, percentage } = record;

								return (
									<TableRow key={id}>
										<TableCell>{description}</TableCell>
										<TableCell align="right">{percentage}</TableCell>
										<TableCell align="right">
											<Button size="small" onClick={() => handleClickEdit(record)}>
												Editar
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
							{!criteria?.length && (
								<TableRow>
									<TableCell colSpan={3} align="center">
										Aún no hay aspectos
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{showCriteriaForm && (
				<CriteriaFormDialog
					onClose={handleCloseCriteriaDialog}
					criteria={selectedCriteria}
					onFinish={handleFinish}
				/>
			)}
		</TitledBox>
	);
};

export default QualificationCriteria;
