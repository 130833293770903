import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';
import { UserRatesService } from 'services';
import { Spinner, TitledBox } from 'components';
import { UserRatesForm } from './UserRatesForm';

const UserRates = () => {
	const [loading, setLoading] = useState(true);
	const [userRates, setUserRates] = useState(null);
	const { enqueueSnackbar } = useSnackbar();

	const { callService, isProcessing } = useService();

	const handleSubmit = (values) => {
		callService(UserRatesService.updateUserRates(values))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Éxito', { variant: 'success' });
			})
			.catch(console.log);
	};

	useEffect(() => {
		if (loading) {
			UserRatesService.getUsersRates()
				.then(({ data }) => {
					setUserRates(data);
				})
				.catch(console.log)
				.finally(() => {
					setLoading(false);
				});
		}
	}, [loading]);

	return (
		<TitledBox title="Tarifas de usuarios" style={{ maxWidth: 566 }}>
			{loading && <Spinner small text="Cargando tarifas" />}
			{!loading && <UserRatesForm userRates={userRates} onSubmit={handleSubmit} />}
			{isProcessing && <Spinner text="Guardando tarifas" isOpen />}
		</TitledBox>
	);
};

export default UserRates;
