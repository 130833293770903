import React, { useState } from 'react';

import AppContext from './AppContext';

const Provider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState();

  return (
    <AppContext.Provider
      value={{
        loggedUser,
        updateLoggedUser: (user) => setLoggedUser(user),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default Provider;
