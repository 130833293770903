import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AddButton, Layout, NoData, PageTitle, Spinner, StatusChip } from 'components';
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';
import { EditRounded } from '@mui/icons-material';

import Tools from 'utils/Tools';

import { PostTypeService } from 'services';
import PostTypeDialog from './PostTypeDialog';
import CanI from 'components/CanI';

const { getPostTypes } = PostTypeService;

const PostTypes = () => {
	const [postTypes, setPostTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedPostType, setSelectedPostType] = useState(null);
	const [showAddForm, setShowAddForm] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (isLoading) {
			getPostTypes()
				.then(({ data }) => {
					setPostTypes(data);
					setIsLoading(false);
				})
				.catch((error) => {
					enqueueSnackbar(Tools.getErrorMessage(error), { variant: 'error' });
					setIsLoading(false);
				});
		}
	}, [enqueueSnackbar, isLoading]);

	const handleClose = () => {
		if (selectedPostType) setSelectedPostType(null);
		if (showAddForm) setShowAddForm(false);
	};

	return (
		<Layout>
			<PageTitle
				title="Tipos de publicación"
				actionButtons={[
					<CanI make="POST_TYPE_ADD">
						<AddButton onClick={() => setShowAddForm(true)} />
					</CanI>,
				]}
			/>
			{isLoading && <Spinner text="Cargando tipos de publicación" isOpen small />}
			{!isLoading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Tipo de publicación</TableCell>
								<TableCell>Estatus</TableCell>
								<TableCell>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{postTypes.map((postType) => (
								<TableRow key={postType.id}>
									<TableCell>{postType.name}</TableCell>
									<TableCell>
										<StatusChip isEnabled={postType.isEnabled} />
									</TableCell>
									<TableCell>
										<CanI make="POST_TYPE_EDIT">
											<IconButton size="small" onClick={() => setSelectedPostType(postType)}>
												<EditRounded />
											</IconButton>
										</CanI>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{!postTypes.length && !isLoading && <NoData message="No hay tipos de publicación" />}

			{(showAddForm || selectedPostType) && (
				<PostTypeDialog
					postType={selectedPostType}
					onCancel={handleClose}
					onSuccess={() => setIsLoading(true)}
				/>
			)}
		</Layout>
	);
};

export default PostTypes;
