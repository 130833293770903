import { Button, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import validationSchemas from 'utils/validationSchemas';

export const TotalWordsForm = ({ assignment, onSubmit }) => {
	const formik = useFormik({
		initialValues: {
			totalWords: assignment?.totalWords ?? 0,
		},
		validationSchema: validationSchemas.totalWords,
		onSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container spacing={2} sx={{ mt: 1 }}>
				<Grid item>
					<TextField
						type="number"
						name="totalWords"
						fullWidth
						id="totalWords"
						label="Cantidad de palabras"
						value={formik.values.totalWords}
						onChange={formik.handleChange}
						error={formik.touched.totalWords && Boolean(formik.errors.totalWords)}
						helperText={formik.touched.totalWords && formik.errors.totalWords}
					/>
				</Grid>
				<Grid item>
					<Button type="submit" color="primary" variant="outlined">
						Enviar
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
