import { useFormik } from 'formik';
import { Button, TextField } from '@mui/material';

import validationSchemas from 'utils/validationSchemas';
import { ActionButtons, CustomSwitch } from 'components';

const PaymentMethodForm = ({ paymentMethod, onSubmit, onCancel }) => {
	const formik = useFormik({
		initialValues: {
			name: paymentMethod?.name || '',
			isEnabled: !!paymentMethod?.isEnabled,
		},
		validationSchema: validationSchemas.paymentMethod,
		onSubmit,
	});

	return (
		<div className="PaymentMethodForm">
			<form onSubmit={formik.handleSubmit} className="PaymentMethodForm-form">
				<TextField
					fullWidth
					id="name"
					name="name"
					label="Método de Pago"
					value={formik.values.name}
					onChange={formik.handleChange}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					margin="normal"
				/>
				<CustomSwitch initialValue={!!paymentMethod?.isEnabled} formik={formik} />

				<ActionButtons style={{ marginTop: 16 }}>
					<Button onClick={onCancel}>Cancelar</Button>
					<Button variant="contained" type="submit">
						{paymentMethod ? 'Guardar' : 'Agregar Método de pago'}
					</Button>
				</ActionButtons>
			</form>
		</div>
	);
};

export default PaymentMethodForm;
