import { Chip } from '@mui/material';
import { StarOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';

const FeaturedChip = ({ label = 'Destacado', size }) => {
	return (
		<Chip
			size={size}
			label={
				<div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
					<StarOutlined htmlColor="orange" /> {label}
				</div>
			}
		/>
	);
};

FeaturedChip.propTypes = {
	size: PropTypes.oneOf(['medium', 'small']),
};

FeaturedChip.defaultProps = {
	size: 'medium',
};

export default FeaturedChip;
