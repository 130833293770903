import { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';

import { AddButton, Spinner, TitledBox } from 'components';
import { TaskTypesDialog } from './TaskTypesDialog';
import { TaskTypeService } from 'services';

const TaskTypes = () => {
	const [loading, setLoading] = useState(true);
	const [openForm, setOpenForm] = useState(false);
	const [selectedTaskType, setSelectedTaskType] = useState(null);
	const [taskTypes, setTaskTypes] = useState(null);

	const handleOpenForm = () => setOpenForm(true);

	const handleCloseForm = (mustReload = false) => {
		setOpenForm(false);
		if (selectedTaskType) setSelectedTaskType(null);
		if (mustReload) setLoading(true);
	};

	const handleClickEdit = (taskType) => {
		setSelectedTaskType(taskType);
		handleOpenForm();
	};

	useEffect(() => {
		if (loading) {
			TaskTypeService.getTaskTypes()
				.then(({ data }) => {
					setTaskTypes(data);
				})
				.catch(console.log)
				.finally(() => {
					setLoading(false);
				});
		}
	}, [loading]);

	return (
		<TitledBox title="Tipos de tareas" style={{ maxWidth: 566 }}>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
				<AddButton onClick={handleOpenForm} />
			</Box>
			{loading && <Spinner small text="Cargando Tipos de Tareas..." />}
			{!loading && (
				<TableContainer component={Box}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Código</TableCell>
								<TableCell>Nombre</TableCell>
								<TableCell sx={{ whiteSpace: 'nowrap' }}>Tiempo (min)</TableCell>
								<TableCell>Descripción</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{taskTypes.map((taskType) => {
								const { id, code, description, name, duration } = taskType;

								return (
									<TableRow key={id}>
										<TableCell>{code}</TableCell>
										<TableCell>{name}</TableCell>
										<TableCell align="right">{duration}</TableCell>
										<TableCell sx={{ fontSize: 13 }}>{description}</TableCell>
										<TableCell align="right">
											<Button size="small" onClick={() => handleClickEdit(taskType)}>
												Editar
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{openForm && (
				<TaskTypesDialog
					taskType={selectedTaskType}
					onClose={handleCloseForm}
					onFinish={handleCloseForm}
				/>
			)}
		</TitledBox>
	);
};

export default TaskTypes;
