import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

import roles, { rolesArray } from 'common/constants/roles';
import {
	EMAIL_FORMAT_ERROR,
	PHONE_FORMAT_ERROR,
	EMAIL_SOME_REQUIRED_ERROR,
	EMAIL_REQUIRED_ERROR,
	PASSWORD_NOT_EQUAL_ERROR,
	PASSWORD_CONFIRM_ERROR,
} from 'common/constants/errorMessages';

function validatePhoneNumber(message) {
	return this.test('isPhone', message, function (value) {
		const { path, createError } = this;

		if (!value) return true;

		const phoneNumber = parsePhoneNumberFromString(value);

		if (!phoneNumber || !phoneNumber.isValid() || !phoneNumber.isPossible()) {
			return createError({
				path,
				message: message || PHONE_FORMAT_ERROR,
			});
		}

		return true;
	});
}

function validateMinWords(min, message) {
	return this.test('minWords', message, function (value) {
		const { path, createError } = this;

		if (!value) return true;

		const words = value.split(' ');

		if (words.length < min) {
			return createError({
				path,
				message: message || `Mínimo ${min} palabras`,
			});
		}

		return true;
	});
}

function validateMaxWords(max, message) {
	return this.test('maxWords', message, function (value) {
		const { path, createError } = this;

		if (!value) return true;

		const words = value.split(' ');

		if (words.length > max) {
			return createError({
				path,
				message: message || `Máximo ${max} palabras`,
			});
		}

		return true;
	});
}

yup.addMethod(yup.string, 'isPhone', validatePhoneNumber);
yup.addMethod(yup.string, 'minWords', validateMinWords);
yup.addMethod(yup.string, 'maxWords', validateMaxWords);

const validationSchemas = {
	signIn: yup.object({
		email: yup.string().email(EMAIL_FORMAT_ERROR).required(EMAIL_REQUIRED_ERROR),
		password: yup.string('Indica tu contraseña').required('Indica tu contraseña'),
	}),
	signUp: yup.object({
		name: yup.string('Indica tu(s) nombre(s)').required('Indica tu(s) nombre(s)'),
		lastName: yup.string('Indica tu(s) apellido(s)').required('Indica tu(s) apellido(s)'),
		email: yup.string().email(EMAIL_FORMAT_ERROR).required(EMAIL_REQUIRED_ERROR),
		phone: yup.string().isPhone().required('Indica un celular de contacto'),
		password: yup
			.string()
			.min(6, 'Debe ser de al menos 6 caracteres')
			.required('Indica tu contraseña'),
		confirmPassword: yup
			.string()
			.required('Confirma tu contraseña')
			.oneOf([yup.ref('password'), null], PASSWORD_NOT_EQUAL_ERROR),
	}),
	profile: yup.object({
		name: yup.string().required('Indica tu(s) nombre(s)'),
		lastName: yup.string('Indica tu(s) apellido(s)').required('Indica tu(s) apellido(s)'),
		email: yup.string().email(EMAIL_FORMAT_ERROR).required(EMAIL_REQUIRED_ERROR),
		phone: yup.string().isPhone().nullable(),
		password: yup.string().min(6, 'Debe ser de al menos 6 caracteres'),
		confirmPassword: yup.string().when('password', {
			is: (password) => password && !!password.length,
			then: yup
				.string()
				.required(PASSWORD_CONFIRM_ERROR)
				.oneOf([yup.ref('password'), null], PASSWORD_NOT_EQUAL_ERROR),
		}),
	}),
	userEdit: yup.object({
		name: yup.string().required('Indica al menos un nombre'),
		lastName: yup.string(),
		idCont: yup.string(),
		email: yup.string().email(EMAIL_FORMAT_ERROR).required(EMAIL_SOME_REQUIRED_ERROR),
		phone: yup.string().isPhone().nullable(),
		isEnabled: yup.boolean(),
		role: yup.string().oneOf(
			rolesArray.map(({ value }) => value),
			`Debe ser al menos uno de: ${rolesArray.map(({ label }) => label).join(', ')}`
		),
		bonus: yup.string().when('role', {
			is: roles.WRITER.value,
			then: yup.string().required('Debe indicar un monto'),
		}),
	}),
	userAdd: yup.object({
		name: yup.string().required('Indica al menos un nombre'),
		lastName: yup.string(),
		idCont: yup.string(),
		email: yup.string().email(EMAIL_FORMAT_ERROR).required(EMAIL_SOME_REQUIRED_ERROR),
		phone: yup.string().isPhone().nullable(),
		isEnabled: yup.boolean(),
		role: yup.string().oneOf(
			rolesArray.map(({ value }) => value),
			`Debe ser al menos uno de: ${rolesArray.map(({ label }) => label).join(', ')}`
		),
		password: yup
			.string()
			.min(6, 'Debe ser de al menos 6 caracteres')
			.required('Indica una contraseña'),
		confirmPassword: yup.string().when('password', {
			is: (password) => password && !!password.length,
			then: yup
				.string()
				.required(PASSWORD_CONFIRM_ERROR)
				.oneOf([yup.ref('password'), null], PASSWORD_NOT_EQUAL_ERROR),
		}),
		bonus: yup.string().when('role', {
			is: roles.WRITER.value,
			then: yup.string().required('Debe indicar un monto'),
		}),
	}),
	category: yup.object({
		name: yup.string().required('Escribe un nombre de etiqueta'),
	}),
	paymentMethod: yup.object({
		name: yup.string().required('Escribe un método de pago'),
	}),
	postType: yup.object({
		name: yup.string().required('Escribe un tipo de publicación'),
	}),
	project: yup.object({
		name: yup.string().required('Escribe un nombre para el proyecto'),
		clientId: yup.string().required('Elige un cliente'),
		categoryId: yup
			.array()
			.min(1, 'Elije al menos una etiqueta')
			.required('Selecciona una etiqueta'),
		amount: yup.string().required('Indica un monto'),
		paidAt: yup.string().nullable(),
		startDate: yup.string().required('Escoge una fecha'),
		endDate: yup.string().required('Escoge una fecha'),
		minWords: yup.number().required('Indica una cantidad'),
		maxWords: yup.number().required('Indica una cantidad'),
		imageRules: yup.string(),
		linksRules: yup.string(),
		clientWebsite: yup.string(),
		referenceWebsite: yup.string().nullable(),
		postTypeId: yup.string().required('Selecciona un tipo de post'),
		focus: yup.string().nullable(),
		tone: yup.string().nullable(),
		pricePer1000: yup.string().required('Indica un monto'),
		notes: yup.string().nullable(),
		checkAll: yup.boolean().required('Indica si hay que revisar'),
		isEnabled: yup.boolean().required('Indica estatus'),
	}),
	assignment: yup.object({
		title: yup.string().required('Indica el título de la asignación'),
		clientId: yup.string().required('Elige un cliente'),
		projectId: yup.string().required('Escoge un proyecto'),
		writerId: yup.string().required('Selecciona un redactor'),
		editorId: yup.string().nullable(),
		guidelines: yup.string().nullable(),
	}),
	rating: yup.object({
		quality: yup.number().required('Indica la puntuación de calidad').min(0.5),
		fluency: yup.number().required('Indica la puntuación de calidad').min(0.5),
		wellDone: yup.string().required('Indica los aspectos positivos').minWords(10).maxWords(200),
		mustDoBetter: yup.string().required('Indica lo que debe mejorar').minWords(10).maxWords(200),
		mustFix: yup.string().required('Indica lo que debe atender de inmediato'),
		totalWords: yup.number().required('Indica la cantidad de palabras').min(1, 'Valor incorrecto'),
	}),
	options: yup.object({
		ADMIN_EMAIL: yup
			.string()
			.required('Indica el email de administración')
			.email(EMAIL_FORMAT_ERROR),
		ADMIN_PHONE: yup.string().required('Indica el celular de administración').isPhone(),
		EDITOR_FEE: yup.string().required('Indica la ganancia del editor/corrector'),
	}),
	extraPayment: yup.object({
		amount: yup.number().required('Indica un monto').min(0.01, 'Indica un monto mayor a 0'),
		description: yup.string().required('Indica una descripción'),
	}),
	userNotification: yup.object({
		message: yup.string().required('Indica el mensaje para el administrador'),
	}),
	clientPayment: yup.object({
		payment: yup.string().required('Indica un monto'),
		paidAt: yup.string(),
	}),
	criteria: yup.object({
		description: yup.string().required('Describa el aspecto de evaluación'),
		percentage: yup
			.number()
			.required('Indique el porcentaje de afectación')
			.min(1, 'Debe ser un número mayor a 0'),
	}),
	qualification: yup.object({
		comment: yup.string(),
	}),
	totalWords: yup.object({
		totalWords: yup.number().required('Indica la cantidad de palabras').min(1, 'Valor incorrecto'),
	}),
	taskType: yup.object({
		code: yup.string().required('Indique Código de tarea'),
		name: yup.string().required('Indique Nombre de la tarea'),
		duration: yup
			.number()
			.required('Indica la duración de la tarea')
			.min(1, 'La duración debe ser mayor a 1'),
		description: yup.string().nullable(),
	}),
	task: yup.object({
		userId: yup.string().required('Selecciona un usuario'),
		code: yup.string().required('Selecciona un código'),
		deliveryDate: yup.string().required('Escoge una fecha'),
		guidelines: yup.string().required('Indique los detalles de la tarea'),
		recurrence: yup
			.number()
			.required('Indique la cantidad de veces a ejecutar')
			.min(1, 'Debe ser mayor a 1'),
	}),
};

const { signIn, signUp, project } = validationSchemas;

export { signIn, signUp, project };

export default validationSchemas;
