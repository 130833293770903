import React from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import NumericInput from 'material-ui-numeric-input';
import { useSnackbar } from 'notistack';

import { useService } from 'hooks';
import { PaymentExtraService } from 'services';
import { ActionButtons, CustomDialogTitle, Spinner } from 'components';
import extraTypes from 'common/constants/extraType';
import validationSchemas from 'utils/validationSchemas';

const PaymentExtraDialog = ({ userId, sentAt, onClose, onSuccess }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { callService, isProcessing } = useService();
	const handleSubmit = (values) => {
		const payload = { ...values, userId, sentAt };

		callService(PaymentExtraService.addExtra(payload))
			.then(({ data }) => {
				enqueueSnackbar(data?.message || 'Listo', { variant: data.messageType });
				onSuccess();
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	const formik = useFormik({
		initialValues: {
			extraType: 'DISCOUNT',
			amount: 0,
			description: '',
		},
		validationSchema: validationSchemas.extraPayment,
		onSubmit: handleSubmit,
	});

	return (
		<Dialog onClose={onClose}>
			<CustomDialogTitle>Añadir bono o descuento</CustomDialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<FormControl fullWidth margin="normal">
						<InputLabel id="extraType-label">Monto extra es un</InputLabel>
						<Select
							labelId="extraType-label"
							id="extraType"
							label="Monto extra es un"
							name="extraType"
							onChange={formik.handleChange}
							value={formik.values.extraType}
							error={formik.touched.extraType && Boolean(formik.errors.extraType)}
							helperText={formik.touched.extraType && formik.errors.extraType}
						>
							{Object.entries(extraTypes).map(([key, { label, value }]) => (
								<MenuItem key={key} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
						<FormHelperText error>
							{formik.touched.extraType && formik.errors.extraType}
						</FormHelperText>
					</FormControl>
					<NumericInput
						name="amount"
						precision={2}
						decimalChar=","
						thousandChar="."
						label="Monto"
						onChange={(e) => (formik.values.amount = e.target.value)}
						variant="outlined"
						value={formik.values.amount}
						defaultValue={Number(formik.values.amount).toLocaleString('es')}
						helperText={formik.touched.amount && formik.errors.amount}
						error={formik.touched.amount && Boolean(formik.errors.amount)}
						margin="normal"
						fullWidth
					/>
					<TextField
						fullWidth
						id="description"
						name="description"
						label="Descripción/Motivo"
						value={formik.values.description}
						onChange={formik.handleChange}
						error={formik.touched.description && Boolean(formik.errors.description)}
						helperText={formik.touched.description && formik.errors.description}
						margin="normal"
					/>
					<ActionButtons style={{ marginTop: 16 }}>
						<Button onClick={onClose}>Cancelar</Button>
						<Button variant="contained" type="submit">
							Guardar
						</Button>
					</ActionButtons>
				</form>
			</DialogContent>
			<Spinner isOpen={isProcessing} text="Procesando..." />
		</Dialog>
	);
};

export default PaymentExtraDialog;
