import { useState } from 'react';

import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useFormik } from 'formik';

import { useLoggedUser } from 'hooks';
import roles from 'common/constants/roles';
import assignmentStatus from 'common/constants/assignmentStatus';
import Tools from 'utils/Tools';
import { ActionButtons } from 'components';

const AssignmentFilterForm = ({ onSubmit, clients, projects, writers = [], editors = [] }) => {
	const [filteredProjects, setFilteredProjects] = useState(null);

	const { loggedUser } = useLoggedUser();

	const isAdminOrAssistant = [roles.ADMIN.value, roles.ASSISTANT.value].includes(loggedUser.role);

	const formik = useFormik({
		initialValues: {
			clientId: '',
			projectId: '',
			editorId: '',
			status: '',
			title: '',
			writerId: '',
		},
		onSubmit,
	});

	const handleChangeClient = (clientIdValue) => {
		if (clientIdValue) {
			setFilteredProjects(projects.filter(({ clientId }) => clientId === clientIdValue));
		} else {
			setFilteredProjects(null);
		}
	};

	const handleClearFilters = () => {
		if (filteredProjects) setFilteredProjects(null);
		formik.resetForm({
			values: {
				title: '',
				clientId: '',
				writerId: '',
				editorId: '',
				projectId: '',
				status: '',
			},
		});
		onSubmit({});
	};

	return (
		<form onSubmit={formik.handleSubmit} className="AssignmentFilterForm">
			<TextField
				fullWidth
				id="title"
				name="title"
				label="Título de la asignación"
				value={formik.values.title}
				onChange={formik.handleChange}
			/>
			{isAdminOrAssistant && (
				<FormControl fullWidth>
					<InputLabel id="writerId-label">Redactor</InputLabel>
					<Select
						labelId="writerId-label"
						id="writerId"
						label="Redactor"
						name="writerId"
						onChange={(e) => {
							formik.values.writerId = e.target.value;
							handleChangeClient(e.target.value);
						}}
						value={formik.values.writerId}
					>
						<MenuItem value="">- Todos -</MenuItem>
						{writers.map(({ id, name, lastName }) => (
							<MenuItem key={id} value={id}>
								{Tools.getFullName({ name, lastName })}
							</MenuItem>
						))}
					</Select>
					<FormHelperText error>{formik.touched.writerId && formik.errors.writerId}</FormHelperText>
				</FormControl>
			)}
			{isAdminOrAssistant && (
				<FormControl fullWidth>
					<InputLabel id="editorId-label">Editor</InputLabel>
					<Select
						labelId="editorId-label"
						id="editorId"
						label="Editor"
						name="editorId"
						onChange={(e) => {
							formik.values.editorId = e.target.value;
							handleChangeClient(e.target.value);
						}}
						value={formik.values.editorId}
					>
						<MenuItem value="">- Todos -</MenuItem>
						{editors.map(({ id, name, lastName }) => (
							<MenuItem key={id} value={id}>
								{Tools.getFullName({ name, lastName })}
							</MenuItem>
						))}
					</Select>
					<FormHelperText error>{formik.touched.editorId && formik.errors.editorId}</FormHelperText>
				</FormControl>
			)}
			<FormControl fullWidth>
				<InputLabel id="clientId-label">Cliente</InputLabel>
				<Select
					labelId="clientId-label"
					id="clientId"
					label="Cliente"
					name="clientId"
					onChange={(e) => {
						formik.values.clientId = e.target.value;
						handleChangeClient(e.target.value);
					}}
					value={formik.values.clientId}
				>
					<MenuItem value="">- Todos -</MenuItem>
					{clients.map(({ id, name, lastName }) => (
						<MenuItem key={id} value={id}>
							{Tools.getFullName({ name, lastName })}
						</MenuItem>
					))}
				</Select>
				<FormHelperText error>{formik.touched.clientId && formik.errors.clientId}</FormHelperText>
			</FormControl>
			<FormControl fullWidth>
				<InputLabel id="projectId-label">Proyecto</InputLabel>
				<Select
					labelId="projectId-label"
					id="projectId"
					label="Proyecto"
					name="projectId"
					onChange={formik.handleChange}
					value={formik.values.projectId}
				>
					<MenuItem value="">- Todos -</MenuItem>
					{(filteredProjects ?? projects).map(({ id, name }) => (
						<MenuItem key={id} value={id}>
							{name}
						</MenuItem>
					))}
				</Select>
				<FormHelperText error>{formik.touched.projectId && formik.errors.projectId}</FormHelperText>
			</FormControl>
			<FormControl fullWidth>
				<InputLabel id="status-label">Estatus</InputLabel>
				<Select
					labelId="status-label"
					id="status"
					label="Estatus"
					name="status"
					onChange={formik.handleChange}
					value={formik.values.status}
				>
					<MenuItem value="">- Todos -</MenuItem>
					{Object.entries(assignmentStatus).map(([key, { label }]) => (
						<MenuItem key={key} value={key}>
							{label}
						</MenuItem>
					))}
				</Select>
				<FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
			</FormControl>
			<ActionButtons>
				<Button type="submit" color="primary" variant="outlined">
					Buscar
				</Button>
				<Button color="error" variant="outlined" onClick={handleClearFilters}>
					Restablecer
				</Button>
			</ActionButtons>
		</form>
	);
};

export default AssignmentFilterForm;
