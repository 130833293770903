import { Route, Switch, Redirect } from 'react-router-dom';

import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';

const GuestMain = () => (
	<Switch>
		<Route exact path="/registro" render={() => <SignUp />} />
		<Route exact path="/" render={() => <SignIn />} />
		<Route render={() => <Redirect to="/" />} />
	</Switch>
);

export default GuestMain;
